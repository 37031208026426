export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";
export const GETTING_FEATURE_FLAGS = "GETTING_FEATURE_FLAGS";

export const SET_MY_VANCO_FEATURE_FLAGS = "SET_MY_VANCO_FEATURE_FLAGS";
export const GETTING_MY_VANCO_FEATURE_FLAGS = "GETTING_MY_VANCO_FEATURE_FLAGS";
export const SET_SHOW_ORG_ADMIN = "SET_SHOW_ORG_ADMIN";

export const UPDATE_SIGNUP_FORM = "UPDATE_SIGNUP_FORM";
export const SIGNING_UP = "SIGNING_UP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_SUCCESS_NO_VERIFICATION = "SIGNUP_SUCCESS_NO_VERIFICATION";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_RESET = "SIGNUP_RESET";
export const EMAIL_VERIFICATION_SENDING = "EMAIL_VERIFICATION_SENDING";
export const EMAIL_VERIFICATION_SENT = "EMAIL_VERIFICATION_SENT";
export const COMPLETING_SIGNUP = "COMPLETING_SIGNUP";
export const COMPLETED_SIGNUP = "COMPLETED_SIGNUP";
export const CHECKING_EMAIL_USAGE = "CHECKING_EMAIL_USAGE";
export const CHECKED_EMAIL_USAGE = "CHECKED_EMAIL_USAGE";
export const CHECKING_PHONE_NUMBER_USAGE = "CHECKING_PHONE_NUMBER_USAGE";
export const CHECKED_PHONE_NUMBER_USAGE = "CHECKED_PHONE_NUMBER_USAGE";
export const SIGN_UP_LOGIN_SUCCESS = "SIGN_UP_LOGIN_SUCCESS";
export const SIGN_UP_LOGIN_FAIL = "SIGN_UP_LOGIN_FAIL";
export const VERIFICATION_CODE_SENT = "VERIFICATION_CODE_SENT";
export const VERIFICATION_CODE_FAIL = "VERIFICATION_CODE_FAIL";
export const VERIFICATION_PASSED = "VERIFICATION_PASSED";
export const SIGNED_IN_USER = "SIGNED_IN_USER";

export const UPDATE_LOGIN_FORM = "UPDATE_LOGIN_FORM";
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_LOGGED_OUT = "SET_LOGGED_OUT";
export const LOGIN_RESET = "LOGIN_RESET";
export const SENDING_PASSWORD_RESET = "SENDING_PASSWORD_RESET";
export const SENDING_PASSWORD_RESET_FAILED = "SENDING_PASSWORD_RESET_FAILED";
export const SENT_PASSWORD_RESET = "SENT_PASSWORD_RESET";
export const SETTING_NEW_PASSWORD = "SETTING_NEW_PASSWORD";
export const SETTING_NEW_PASSWORD_FAILED = "SETTING_NEW_PASSWORD_FAILED";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_REDIRECT_PARAMS = "SET_REDIRECT_PARAMS";
export const RESET_REDIRECT_PARAMS = "RESET_REDIRECT_PARAMS";
export const CLEAR_ORGANIZATION_DATA = "CLEAR_ORGANIZATION_DATA";

export const CREATING_ORGANIZATION = "CREATING_ORGANIZATION";
export const CREATED_ORGANIZATION = "CREATED_ORGANIZATION";
export const LOADING_ORGANIZATION = "LOADING_ORGANIZATION";
export const LOADED_ORGANIZATION = "LOADED_ORGANIZATION";
export const LOADING_ORGANIZATION_FAILED = "LOADING_ORGANIZATION_FAILED";
export const ORGANIZATIONS_LOADED = "ORGANIZATIONS_LOADED";
export const ORGANIZATION_RESET = "ORGANIZATION_RESET";

export const CREATING_ORGANIZATION_ADMIN = "CREATING_ORGANIZATION_ADMIN";
export const CREATED_ORGANIZATION_ADMIN = "CREATED_ORGANIZATION_ADMIN";
export const CREATING_ORGANIZATION_USER = "CREATING_ORGANIZATION_USER";
export const CREATED_ORGANIZATION_USER = "CREATED_ORGANIZATION_USER";
export const CREATING_ORGANIZATION_IN_PROGRESS = "CREATING_ORGANIZATION_IN_PROGRESS";
export const CREATED_ORGANIZATION_IN_PROGRESS = "CREATED_ORGANIZATION_IN_PROGRESS";

export const CREATING_GROUP = "CREATING_GROUP";
export const CREATED_GROUP = "CREATED_GROUP";
export const UPDATED_GROUP = "UPDATED_GROUP";
export const CREATING_GROUP_MEMBERSHIP = "CREATING_GROUP_MEMBERSHIP";
export const CREATED_GROUP_MEMBERSHIP = "CREATED_GROUP_MEMBERSHIP";
export const CREATING_GROUP_INVITATION = "CREATING_GROUP_INVITATION";
export const CREATED_GROUP_INVITATION = "CREATED_GROUP_INVITATION";
export const LOADING_GROUP_INVITATION = "LOADING_GROUP_INVITATION";
export const LOADING_GROUP_INVITATION_FAILED = "LOADING_GROUP_INVITATION_FAILED";
export const LOADED_GROUP_INVITATION = "LOADED_GROUP_INVITATION";
export const UPDATING_GROUP_INVITATION = "UPDATING_GROUP_INVITATION";
export const UPDATED_GROUP_INVITATION = "UPDATED_GROUP_INVITATION";
export const RESET_INVITATIONS = "RESET_INVITATIONS";
export const SET_DEFAULT_GROUP = "SET_DEFAULT_GROUP";
export const JOINING_GROUP = "JOINING_GROUP";
export const JOIN_GROUP_SUCCESS = "JOIN_GROUP_SUCCESS";
export const JOIN_GROUP_FAIL = "JOIN_GROUP_FAIL";
export const LOADING_GROUPS = "LOADING_GROUPS";
export const LOADED_GROUPS = "LOADED_GROUPS";
export const SET_GROUP_SEARCH_TERM = "SET_GROUP_SEARCH_TERM";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_PROFILE_POPUP = "TOGGLE_PROFILE_POPUP";

export const RESET_USERS = 'RESET_USERS';
export const LOADING_USERS = 'LOADING_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const LOADING_USERS_FAILED = 'LOADING_USERS_FAILED';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SAVED_USER = 'SAVED_USER';
export const SAVING_USER = 'SAVING_USER';
export const CHECKING_EMAIL = 'CHECKING_EMAIL';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';
export const CHECKING_PHONE_NUMBER = 'CHECKING_PHONE_NUMBER';
export const CHECK_PHONE_NUMBER_SUCCESS = 'CHECK_PHONE_NUMBER_SUCCESS';
export const CHECK_PHONE_NUMBER_FAIL = 'CHECK_PHONE_NUMBER_FAIL';
export const CHECK_PHONE_NUMBER_RESET = 'CHECK_PHONE_NUMBER_RESET';

export const LOADING_EVENTS = "LOADING_EVENTS";
export const LOADED_EVENTS = "LOADED_EVENTS";
export const LOADING_EVENTS_FAILED = "LOADING_EVENTS_FAILED";
export const LOADING_EVENT_TICKETS = "LOADING_EVENT_TICKETS";
export const LOADED_EVENT_TICKETS = "LOADED_EVENT_TICKETS";
export const SET_EVENT_SEARCH_TERM = "SET_EVENT_SEARCH_TERM";

export const LOADING_DONATIONS = "LOADING_DONATIONS";
export const LOADED_DONATIONS = "LOADED_DONATIONS";
export const LOADING_TICKET_PURCHASES = "LOADING_TICKET_PURCHASES";
export const LOADED_TICKET_PURCHASES = "LOADED_TICKET_PURCHASES";
export const SET_PAYMENT_SEARCH_TERM = "SET_PAYMENT_SEARCH_TERM";
export const LOADING_PAYMENTS = "LOADING_PAYMENTS";
export const LOADED_PAYMENTS = "LOADED_PAYMENTS";
export const LOADING_FUNDS = "LOADING_FUNDS";
export const LOADED_FUNDS = "LOADED_FUNDS";
export const UPDATING_FUND = "UPDATING_FUND";
export const UPDATED_FUND = "UPDATED_FUND";
export const DELETING_FUND = "DELETING_FUND";
export const DELETED_FUND = "DELETED_FUND";
export const ADDING_FUND = "ADDING_FUND";
export const ADDED_FUND = "ADDED_FUND";
export const CLEAR_FUND = "CLEAR_FUND";
export const LOADING_DONATION = "LOADING_DONATION";
export const LOADED_DONATION = "LOADED_DONATION";
export const PLACING_DONATION = "PLACING_DONATION";
export const PLACED_DONATION = "PLACED_DONATION";
export const PLACING_DONATION_FAILED = "PLACING_DONATION_FAILED";
export const PAYMENT_REFUNDING = "PAYMENT_REFUNDING";
export const PAYMENT_REFUNDED = "PAYMENT_REFUNDED";
export const PAYMENT_REFUND_FAILED = "PAYMENT_REFUND_FAILED";

export const INVOICES_LOADING = "INVOICES_LOADING";
export const INVOICES_LOADED = "INVOICES_LOADED";
export const INVOICES_FAILED = "INVOICES_FAILED";
export const UPCOMING_INVOICE_LOADING = "UPCOMING_INVOICE_LOADING";
export const UPCOMING_INVOICE_LOADED = "UPCOMING_INVOICE_LOADED";

export const LOADING_ORGANIZATIONS = "LOADING_ORGANIZATIONS";
export const LOADED_ORGANIZATIONS = "LOADED_ORGANIZATIONS";
export const SET_ORGANIZATION_SEARCH_TERM = "SET_ORGANIZATION_SEARCH_TERM";

export const ADDING_SUPPORT_USER = "ADDING_SUPPORT_USER";
export const ADDED_SUPPORT_USER = "ADDED_SUPPORT_USER";
export const CLEAR_SUPPORT_USER = "CLEAR_SUPPORT_USER";

export const STARTING_METRIC_COUNTS = "STARTING_METRIC_COUNTS";
export const GOT_METRIC_USERS_ORG_COUNTS = "GOT_METRIC_USERS_ORG_COUNTS";
export const GOT_METRIC_INVITE_COUNTS = "GOT_METRIC_INVITE_COUNTS";
export const GOT_METRIC_USERS_COUNTS = "GOT_METRIC_USERS_COUNTS";
export const GOT_METRIC_GROUPS_WITH_COUNT = "GOT_METRIC_GROUPS_WITH_COUNT";
export const GETTING_METRIC_GROUPS_WITH_COUNT = "GETTING_METRIC_GROUPS_WITH_COUNT";
export const GOT_METRIC_EVENTS_WITH_COUNT = "GOT_METRIC_EVENTS_WITH_COUNT";
export const GETTING_METRIC_EVENTS_WITH_COUNT = "GETTING_METRIC_EVENTS_WITH_COUNT";

export const RSVP_LOADING_EVENTS = "RSVP_LOADING_EVENTS";
export const RSVP_LOADED_EVENTS = "RSVP_LOADED_EVENTS";
export const RSVP_LOADING_EVENTS_FAILED = "RSVP_LOADING_EVENTS_FAILED";
export const RSVP_LOADED_EVENT = "RSVP_LOADED_EVENT";
export const RSVP_LOADING_EVENT = "RSVP_LOADING_EVENT";
export const RSVP_LOADING_EVENT_FAILED = "RSVP_LOADING_EVENT_FAILED";
export const RSVP_JOINING = "RSVP_JOINING";
export const RSVP_JOINED = "RSVP_JOINED";
export const RSVP_JOINED_ERROR = "RSVP_JOINED_ERROR";
export const RSVP_LOADED_EVENT_ORG_DETAILS = "RSVP_LOADED_EVENT_ORG_DETAILS";

export const SET_GIVE_IDS_IS_VALID_TOKEN = "SET_GIVE_IDS_IS_VALID_TOKEN";
export const SET_GIVE_IDS_IS_VALID_TOKEN_LOADING = "SET_GIVE_IDS_IS_VALID_TOKEN_LOADING";
export const SET_GIVE_IDS_IS_VALID_TOKEN_LOADED = "SET_GIVE_IDS_IS_VALID_TOKEN_LOADED";

export const ZOOM = "ZOOM";
