import React, { Fragment, useState, useEffect } from "react";
import Modal from "react-modal";

import { useParams } from "react-router-dom";
import moment from "moment";

import NavBar from "../../components/NavBar";
import Footer from "../../../adminstration/components/Footer";
import example from "../../../../img/churchExample.jpg";
import useWindowDimensions from "../../../../utils/windowUtils";
import EventListItem from "../../components/EventListItem/EventListItem";
import ActivitySpinner from "../../../adminstration/components/ActivitySpinner";
import FloatingButton from "../../components/FloatingButton";
import { isMultiDay } from "../../../../actions/RSVPEventsActions";

let dateOfEvents = null;

const RSVPEvents = ({
  RSVPGetEvents,
  RSVPGetEventOrgDetails,
  RSVPevents: RSVPeventsState,
  RSVPloadingEvents,
  RSVPloadingEventsFailed,
  RSVPeventsOrgName,
  RSVPeventsOrgDetails,
  RSVPloadedEvents,
}) => {
  const [showModalSignIn, setShowModalSignIn] = useState(false);
  const [value, onChange] = useState(new Date());
  const [showFloatingButton, setShowFloatingButton] = useState(false)
  let { orgId } = useParams();
  let currentDate;
  const { height, width } = useWindowDimensions();

  const toggleSetShowModalSignIn = () => {
    setShowModalSignIn(!showModalSignIn);
  };

  useEffect(() => {
    RSVPGetEventOrgDetails(orgId);
    RSVPGetEvents(orgId);
  }, []);

  useEffect(()=>{
    window.addEventListener('scroll', (e)=>{
      let {scrollY} = e.currentTarget;
      console.log(scrollY);
      if(scrollY >= 150 && !showFloatingButton){
        setShowFloatingButton(true)
      }
      if(scrollY < 150 ){
        setShowFloatingButton(false)
      }
    })
    return ()=>{
      window.removeEventListener('scroll', (e)=>{
        console.log(e);
      })
    }
  },[])

  return (
    <div className="el-event-list" >
      <Modal
        overlayClassName="signInModal-Overlay "
        isOpen={showModalSignIn}
        shouldCloseOnOverlayClick
        onRequestClose={() => setShowModalSignIn(false)}
        className="signInModal col-md-4 col-12-sm "
      >
        <div className="col">
          <div className="row">
            <h1>Sign in </h1>
          </div>
          <div className="col">
            <h3>Sign in with your Vanco Mobile email and password.</h3>
          </div>
          <div className="col">
            <input
              placeholder="Email"
              className="form-control underlined pv-2 mt-1"
            />
          </div>
          <div className="col mt-1 mb-1">
            <input
              placeholder="Password"
              type="password"
              className="form-control underlined pv-2 mt-1"
            />
          </div>
          <div className="col right-content mt-1">
            <a>Forgot Password?</a>
          </div>
          <div className="col center-content">
            <input className="btn btn-info" type="submit" value="Sign Up" />
          </div>
          <div className="col mt-2 mb-2 center-content">
            <p>
              Not a Vanco Mobile user? <a>Download the app to sign up.</a>
            </p>
          </div>
        </div>
      </Modal>
      <NavBar title={`${RSVPeventsOrgName || ''} Events`} imageUri={RSVPeventsOrgDetails && RSVPeventsOrgDetails.avatar}/>
        {/* <div className="row mt-2">
          <div className="col-md-6 input-group">
            <i class="fa fa-search input-group-addon search-bar-icon"></i>
            <input
              placeholder="Search for an event"
              className="form-control form-contol-icon search-bar-input"
            />
          </div>
        </div> */}
        {/* <div className="row mt-2">
          <div className="col-md-12">
            <div className="row center"> */}
              {/* <div className="RSVP-event-body-column col-md-3 mb-3">
                <div className="row">
                  <Calendar
                    className="RSVP-calendar"
                    onChange={onChange}
                    value={value}
                    calendarType="US"
                    prev2AriaLabel={null}
                    prev2Label={null}
                    tileContent={({ date, view }) => {
                      console.log(view);
                      return (
                        view == "month" && (
                          <div className="RSVP-calendar-event-indicator" />
                        )
                      );
                    }}
                    prevLabel={<i class="fa fa-chevron-left"></i>}
                    nextLabel={<i class="fa fa-chevron-right"></i>}
                    next2AriaLabel={null}
                    next2Label={null}
                    formatShortWeekday={(locale, date) =>
                      moment(date).format("dd").substring(0, 1)
                    }
                  />
                </div>
              </div> */}
              <div className="el-container" >
                <Fragment>
                  {RSVPloadingEvents ? (
                    <ActivitySpinner />
                  ) : (
                    <Fragment>
                      {RSVPeventsState.map((i) => {
                        const startHasPassed = isMultiDay(i) && moment(i.dateTimeStart).isBefore(moment(),'day');
                        const dateTimeStart = startHasPassed ? moment() : moment(i.dateTimeStart);
                        const fullDay = moment(dateTimeStart).format(
                          "dddd, MMMM D, YYYY"
                        );
                        let showLetterRow = true;
                        if (
                          fullDay === currentDate 
                        ) {
                          showLetterRow = false;
                        }
                        else {
                          currentDate = fullDay
                        }

                        return (
                          <Fragment key={`header_${i.id}`}>
                            {showLetterRow && (
                              <div>
                                <h1 className="el-event-title-date">
                                  {fullDay}
                                </h1>
                              </div>
                            )}
                            <EventListItem {...i} key={i.id} orgId={orgId} orgDetails={RSVPeventsOrgDetails}/>
                          </Fragment>
                        );
                      })}
                      {RSVPeventsState.length < 1 && RSVPloadedEvents && (
                        <div style={{ justifyContent:'center'}} className="flex-column">
                          <h1 style={{textAlign:'center', marginTop:100}} className="el-event-title-date">
                            No upcoming events
                          </h1>
                          <p style={{textAlign:'center'}}>There are no vanco events to display. Please contact your organization if you have questions</p>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
                {showFloatingButton && <FloatingButton title="Back to top" onClick={()=>{window.scrollTo(0,0)}} color={RSVPeventsOrgDetails.primaryColor}/>}
              </div>
            {/* </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default RSVPEvents;
