import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ActivitySpinner from '../../../adminstration/components/ActivitySpinner';
import SuggestionAlert from '../../components/SuggestionAlert';
const PaymentError = ({ errorMessage = "An unknown error occurred while processing the payment. Please try again later. (Error code 500)" }) => {
    const [isLoading, setisLoading] = useState(false)
    const location = useLocation()

    /**
    * Determine the mobile operating system.
    * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
    *
    * @returns {String}
    */
    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return "unknown";
    }

    const closeWindow = () => {
        if (getMobileOperatingSystem() == "ios") {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
        else if (getMobileOperatingSystem() == "android") {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/?paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
        else {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
    }

    return (
        <div className="payment-error-container container-fluid paymentConfimation">
            <div className="IDS navbar" style={{ background: location.state.backgroundColor }}>
                <h1>Error</h1>
            </div>
            <SuggestionAlert icon="fa-exclamation-triangle">
                <p className="suggestion-alert-text">{location.state ? location.state.errorMessage : errorMessage}</p>
            </SuggestionAlert>
            <div className="IDS body row">
                {isLoading ? <ActivitySpinner className={"payment-spinner"} /> :
                    <Fragment>
                        <div className="col-xs-12 payment-error">
                            <div className="check-container">
                                <i className="fa fa-exclamation-circle" />
                            </div>
                            <div className="details col-xs-12">
                                <h1 className="payment-error-title">Something went wrong</h1>
                                <p className="payment-error-description">Your payment method has not been charged. Please try again.</p>
                            </div>
                            <div className="col-xs-12 submit-controls">
                                <div className="row">
                                    <input
                                        style={{
                                            borderColor: location.state.backgroundColor,
                                            backgroundColor: location.state.backgroundColor
                                        }}
                                        onClick={closeWindow}
                                        className="btn btn-submit col-xs-8"
                                        type="button"
                                        value="Return to app"
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>}
            </div>
            <div className="footer col-md-12">
                This site is protected by reCAPTCHA and the Google <a style={{color:location.state.backgroundColor}} href="https://policies.google.com/privacy?hl=en-US" target="_blank" >Privacy Policy</a> and <a style={{color:location.state.backgroundColor}} href="https://policies.google.com/terms?hl=en-US">Terms of Service</a> apply
            </div>
        </div>
    )
}

export default PaymentError
