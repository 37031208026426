// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  usersNoOrg: 0,
  usersWithOrg: 0,
  inviteCount: 0,
  userCounts: {},
  gettingGroupsWithCount: false,
  gotGroupsWithCount: false,
  gettingEventsWithCount: false,
  gotEventsWithCount: false,
  groupsWithCount: [],
  eventsWithCount: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.STARTING_METRIC_COUNTS:
      return { ...state, usersNoOrg: 0, usersWithOrg: 0, inviteCount: 0, userCounts: {} };
    case actionConstants.GOT_METRIC_USERS_ORG_COUNTS:
      return { ...state, usersNoOrg: action.usersNoOrg, usersWithOrg: action.usersWithOrg };
    case actionConstants.GOT_METRIC_INVITE_COUNTS:
      return { ...state, inviteCount: action.inviteCount };
    case actionConstants.GOT_METRIC_USERS_COUNTS:
      return {
        ...state, userCounts: action.payload
      };
    case actionConstants.GETTING_METRIC_GROUPS_WITH_COUNT:
      return {
        ...state, gettingGroupsWithCount: true, gotGroupsWithCount: false, groupsWithCount: []
      };
    case actionConstants.GOT_METRIC_GROUPS_WITH_COUNT:
      return {
        ...state, gettingGroupsWithCount: false, gotGroupsWithCount: true, groupsWithCount: action.payload
      };
    case actionConstants.GETTING_METRIC_EVENTS_WITH_COUNT:
      return {
        ...state, gettingEventsWithCount: true, gotEventsWithCount: false, eventsWithCount: []
      };
    case actionConstants.GOT_METRIC_EVENTS_WITH_COUNT:
      return {
        ...state, gettingEventsWithCount: false, gotEventsWithCount: true, eventsWithCount: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getMetric = ({usersNoOrg, usersWithOrg, inviteCount, userCounts, groupsWithCount, eventsWithCount, gettingGroupsWithCount, gotGroupsWithCount, gettingEventsWithCount, gotEventsWithCount, }) => ({
  usersNoOrg,
  usersWithOrg,
  inviteCount,
  userCounts,
  groupsWithCount,
  eventsWithCount,
  gettingGroupsWithCount,
  gotGroupsWithCount,
  gettingEventsWithCount,
  gotEventsWithCount,
});
