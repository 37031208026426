import React, { PureComponent } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import RegistrationTemplate from "./templates/RegistrationTemplate";


/**
 * Admin Containers
 */
import Status from "./apps/adminstration/containers/Status";
import Scripts from "./apps/adminstration/containers/Scripts"

/**
 * Registration Containers
 */
import ErrorOpenInPhone from "./apps/registration/containers/ErrorOpenInPhone";

/**
 * Event Containers
 */
import RSVPEvent from "./apps/events/containers/RSVPEvent";
import RSVPEvents from "./apps/events/containers/RSVPEvents";

/**
 * Payment/IDS Containers
 */
import PaymentConfirmation from "./apps/ids/containers/PaymentConfimation";
import PaymentSuccess from "./apps/ids/containers/PaymentSuccess";
import PaymentError from "./apps/ids/containers/PaymentError";
import Hexea from "./apps/ids/containers/Hexea";
import HexeaGG from "./apps/ids/containers/HexeaGG";
import HexeaAch from "./apps/ids/containers/HexeaAch";
import HexeaAchGG from "./apps/ids/containers/HexeaAchGG";
import HexeaGGv2 from "./apps/ids/containers/v2/HexeaGG";
import HexeaAchv2 from "./apps/ids/containers/v2/HexeaAchGG";
import HexeaGGv3 from "./apps/ids/containers/v3/HexeaGG";

/**
 * IDS Containers
 */
import Loading from "./apps/ids/containers/Loading";

/**
 * Zoom Containers
 */
import ZoomAuth from "./apps/zoom/containers/ZoomAuth";
import AppLink from "./apps/registration/containers/AppLink";


/**
 * Routes file for controlling the auth routes and the main dashboard routes, wrapped in the appropriate template
 */
class Routes extends PureComponent {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/error-phone-link" component={ErrorOpenInPhone} />
            <Route
              path="/app-link"
              render={() => (
                <RegistrationTemplate>
                  <Switch>
                    <Route path="/app-link" component={AppLink} />
                  </Switch>
                </RegistrationTemplate>
              )}
            />
            {/* DO NOT uncomment the line below and merge to master and push, this line must always remain commented when live. To use it, run "yarn start:prod" and uncomment */}
            {/* <Route path="/scripts" component={Scripts} /> */}
            <Route
              path="/events"
              render={() => (
                <Switch>
                  <Route path="/events/:orgId/:eventId" component={RSVPEvent} />
                  <Route path="/events/:orgId" component={RSVPEvents} />
                </Switch>
              )}
            ></Route>
            <Route
              path="/IDS"
              render={() => (
                <Switch>
                  <Route path="/IDS/hexea" component={Hexea} />
                  <Route path="/IDS/hexeaGG" component={HexeaGG} />
                  <Route path="/IDS/hexeaAch" component={HexeaAch} />                
                  <Route path="/IDS/hexeaAchGG" component={HexeaAchGG} />                
                  <Route path="/IDS/paymentConfirmation/:paymentToken/" component={PaymentConfirmation} />
                  <Route path="/IDS/paymentSuccess" component={PaymentSuccess} />
                  <Route path="/IDS/paymentError" component={PaymentError} />
                  <Route path="/IDS/v2/hexeaGG" component={HexeaGGv2} />
                  <Route path="/IDS/v2/hexeaAchGG" component={HexeaAchv2} />
                  <Route path="/IDS/v3/hexeaGG" component={HexeaGGv3} />
                </Switch>
              )}
            ></Route>
            <Route
              path="/status"
              render={() => (
                <RegistrationTemplate>
                  <Switch>
                    <Route path="/status" component={Status} />
                  </Switch>
                </RegistrationTemplate>
              )}
            />
            <Route
              path="/loading"
              render={() => (
                <RegistrationTemplate>
                  <Switch>
                    <Route path="/loading" component={Loading} />
                  </Switch>
                </RegistrationTemplate>
              )}
            />
            <Route
              path="/zoomAuth"
              render={() => (
                <RegistrationTemplate>
                  <Switch>
                    <Route path="/zoomAuth/:userId" component={ZoomAuth} />
                  </Switch>
                </RegistrationTemplate>
              )}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;
