import React, { Fragment, useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import ZoomIcon from '../../../../img/ZoomIcon.svg'
import LanguageIcon from '@material-ui/icons/Language';
import MiniCalendar from "../MiniCalendar";

const EventListItem = ({
  onClickSignIn,
  portrait,
  name,
  description,
  dateTimeStart,
  dateTimeEnd,
  venue,
  hasAttendeeLimit,
  attendeeLimit,
  attending,
  avatar = false,
  limit = 200,
  orgId,
  id,
  allDay,
  locationType,
  locationName,
  address,
  onlineLocationType,
  zoomMeetingDetails,
  webLocationLink,
  orgDetails
}) => {
  const [showAllDescription, setshowAllDescription] = useState(false);
  const [showAllDescriptionLink, setshowAllDescriptionLink] = useState(false);
  const regExpLink = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim;

  const [isSameDay] = useState(moment(dateTimeStart)
    .isSame(moment(dateTimeEnd), "day"))

  const [fixedDescription, setfixedDescription] = useState(description);
  const [isSignupHover, setIsSignupHover] = useState(false)
  const [isCardHover, setIsCardHover] = useState(false)
  let localtz = moment.tz.guess()
  const checkDescriptionLength = () => {
    if (description) {
      let newDescription = description.replace(regExpLink, "</br></br>$1");
      let stringLength = String(newDescription).trim().length;
      if (stringLength > limit && !showAllDescription) {
        setshowAllDescriptionLink(true);
        setfixedDescription(String(newDescription).substring(0, limit) + "...");
      } else {
        setfixedDescription(String(newDescription));
      }
    }
  };
  const calculateAttendeeNumbers = () => {
    let total = 0;
    const attendees = attending;
    if (attendees) {
      Object.keys(attendees).map((key) => {
        return (total += attendees[key] || 1);
      });
    }
    return total;
  };

  const calculateRemainingAttendeeSlots = () => {
    if (!hasAttendeeLimit) {
      return 100;
    }
    return attendeeLimit - calculateAttendeeNumbers() > 0
      ? attendeeLimit - calculateAttendeeNumbers()
      : 0;
  };

  const renderLocation = () => {
    let locType = typeof(locationType) === 'string' ? locationType : locationType && locationType.value
    let onlineLocType = typeof(onlineLocationType) === 'string' ? onlineLocationType : onlineLocationType && onlineLocationType.value
    if(locType == 'person'){
      return (
      <div className="flex-row">
        <RoomOutlinedIcon style={{fontSize: 24, marginRight: 10, color:'#222222'}}/>
        <div className="RSVP-event-subtitle">
          {locationName || "In person"}
        </div>
      </div>
      )
    }
    else if(onlineLocType == 'zoom'){
      return (
        <div className="flex-row">
          <img
            src={ZoomIcon}
            style={{
              width: 24,
              height: 24,
              marginRight: 10,
            }}
          />{" "}
          <div className="RSVP-event-subtitle">Zoom</div>
        </div>
      );
    }
    else if(onlineLocType == 'link'){
      return (<div className="flex-row">
        <LanguageIcon style={{fontSize: 24, marginRight: 10, color:'#222222'}}/>
        <div className="RSVP-event-subtitle">
          {webLocationLink || "Online"}
        </div>
      </div>)
    }
    return null;
  }

  const toggleShowAllDescription = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setshowAllDescription(!showAllDescription);
  };

  useEffect(() => {
    checkDescriptionLength();
  }, [showAllDescription]);
  
  dateTimeEnd = dateTimeEnd || moment(dateTimeStart).endOf('day');
  const isMultiDay = !moment(dateTimeStart).isSame(moment(dateTimeEnd), "day");
  
  return (
    <Link to={`/events/${orgId}/${id}`} className="el-event-item-link">
      <div
        className={`el-event-item ${
          isCardHover && "el-on-hover-background"
        }`}
        onMouseEnter={() => {
          setIsCardHover(true);
        }}
        onMouseLeave={() => {
          setIsCardHover(false);
        }}
      >
        <div className="el-event-item-details">
          <div>
            {avatar && <img src={avatar} className="el-event-list-img" />}
          </div>
          <div className="el-event-item-details-info">
            <div className="el-event-item-title">{name}</div>
            <div style={{ marginBottom: 8, marginTop: 8 }} className="flex-row">
              <AccessTimeOutlinedIcon
                style={{ fontSize: 24, color: "#222222", marginRight: 8 }}
              />
              <div className="flex-column el-event-subtitle">
                {isMultiDay && !allDay ? (
                  <div>
                    {moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, h:mm A")}{" "}
                    - <br/>{moment(dateTimeEnd).tz(localtz).format("dddd, MMMM D, h:mm A zz")}
                  </div>
                ) : (
                  <div>
                    {`${moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, YYYY")} ${allDay ? "(all day)" : ""}`}
                  </div>
                )}
                { 
                  (!isMultiDay && !allDay) && (
                    <div>
                      {moment(dateTimeStart).tz(localtz).format("h:mm A")} -{" "}
                      {moment(dateTimeEnd).tz(localtz).format("h:mm A zz")}
                    </div>
                  )
                }
              </div>
            </div>
            {renderLocation()}
          </div>
        </div>
        <div>
          <div className="el-sign-up-section">
            {hasAttendeeLimit ? (
              <p className="el-remaining-spots">
                {calculateRemainingAttendeeSlots()} spots remaining
              </p>
            ) : (
              <div />
            )}
            <input
              style={{ color: orgDetails.primaryColor }}
              className={[
                "btn",
                "RSVP-event-item-button",
                isSignupHover && "RSVP-on-hover-background",
              ].join(" ")}
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsSignupHover(true);
                setIsCardHover(false);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsSignupHover(false);
                setIsCardHover(true);
              }}
              type="submit"
              value="Sign up"
            />
          </div>
        </div>
      </div>
      {/* <div className="row RSVP-event-item">
        <div className="col ">
          <div className="col-md-4">
            {avatar && (
              <img src={avatar} className="RSVP-event-list-img" />
            ) }
          </div>
          <div className="col-md-8">
            <p className="RSVP-event-item-title">{name}</p>
            <Fragment>
              {hasAttendeeLimit && (
                <p className="RSVP-event-item-seats">
                  {calculateRemainingAttendeeSlots()} remaining
                </p>
              )}
            </Fragment>
            <Fragment>
              <div
                className="RSVP-event-item-description"
                dangerouslySetInnerHTML={{ __html: fixedDescription }}
              ></div>
              {showAllDescriptionLink && (
                <a onClick={toggleShowAllDescription}>
                  {showAllDescription ? "See less" : "See more"}
                </a>
              )}
            </Fragment>
          </div>
        </div>
        <div className="col-md-12 RSVP-event-item-footer mt-1">
          <div className="col-md-5 RSVP-event-item-footer-dates">
            <p>{moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, YYYY")}</p>
            {allDay ? (
              <p>All day</p>
            ) : (
              <p>
                {moment(dateTimeStart).tz(localtz).format("h:mm a") +
                  " - " +
                  moment(dateTimeEnd).tz(localtz).format("h:mm a")}
              </p>
            )}
            {!moment(dateTimeStart).isSame(moment(dateTimeEnd), "day") && (
              <p>{moment(dateTimeEnd).tz(localtz).format("dddd, MMMM D, YYYY")}</p>
            )}
            {/* <p>{venue}</p> 
          </div>
        </div>
        <div className="sign-up-section">
          <div className="RSVP-event-item-sign-btn-container">
            <input
              className="btn RSVP-event-item-button"
              type="submit"
              value="Sign Up"
            />
          </div>
        </div>
      </div> */}
    </Link>
  );
};

export default EventListItem;
