import React, { Fragment, useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { ResizeObserver } from '@juggle/resize-observer';

import { HexeaScript } from './src';

const HexeaGG = () => {
    const containerRef = useRef(null);
    const observer = useRef(new ResizeObserver(entries => {
        const {height} = entries[0].contentRect;
        sendResizeAlert(height)
    }));

    const sendResizeAlert = async (newHeight) => {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'cc_resize',
            payload: newHeight+16
        }));
    }
    const blockScrolling = () => {
        //force the body style on load to prevent web view scrolling
        const hiddenOverFlowStyle = document.createElement('style');
        hiddenOverFlowStyle.appendChild(document.createTextNode( 'body {overflow-x: hidden !important; overflow-y: hidden !important; }'))
        document.head.appendChild(hiddenOverFlowStyle)
    }
    useEffect(()=>{
        if(containerRef.current){
            observer.current.observe(containerRef.current)
        }
        blockScrolling();
    },[]);
    
    return <Fragment>
        <div  ref={containerRef} class="container-fluid hexea-container hexea-gg" style={{marginTop: 10, paddingLeft: 0, paddingRight: 0}}>
            <form id="payment-form" action="#">
                <div class="row" style={{marginBottom: 8}}>
                    <div class="form-group col-xs-12" style={{marginBottom: 8, marginTop:0}}>
                        <div id="card-number" className="form-control" data-placeholder="Card number" style={{height: 50, borderRadius: 5}}>
                        </div>
                        <div id="card-errors" role="alert" class="text-danger" role="alert"></div>
                    </div>
                </div>
                <div class="row" style={{marginBottom: 8}}>
                    <div class="form-group col-xs-12">
                        <div id="card-exp" class="form-control" data-placeholder="Expiration date" style={{height: 50, borderRadius: 5}}>
                        </div>
                        <div id="card-errors-date" role="alert" class="text-danger" role="alert"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12">
                        <input
                            name="bill-postalCode"
                            id="postalCode"
                            class="form-control"
                            autocomplete="billing postal-code"
                            placeholder="ZIP code"
                            type="tel"
                            style={{height: 50, borderRadius: 5, marginTop: 8}}
                            onFocus={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({ focussed: true }))
                            }}
                        />
                        <div id="card-errors-zip" role="alert" class="text-danger" role="alert"></div>
                    </div>
                </div>
            </form>
        </div>
        <Helmet>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/validator/13.1.17/validator.min.js" integrity="sha512-p1b+doSyVwyKqVTZeU1+XJtjpBSkhllBca2L6QTxPUjZZ0ELeZIHkAeQdtfNulbXxLdCwN4uKYGPpp78xeqwfQ==" crossorigin="anonymous"></script>
        </Helmet>
        <HexeaScript />
    </Fragment>

}

export default HexeaGG