import { Helmet } from "react-helmet"
import React from 'react'
export const HexeaScript = () => {
    return <Helmet>
        <script>
            {
                `
            var isSaved = false          
            var zipCodeValid= false    
            var cardValid= false    
            var dateValid= false    

            function internal(pcct, email, nameOnCard, userToken) {
            var hexea = Hexea(pcct);
            var paymentMethod = hexea.paymentMethod("card");
            var zipCode = document.getElementById("postalCode");
            function validateAllInputs(){
                if(!!zipCodeValid && !!cardValid && !!dateValid){
                    window.ReactNativeWebView.postMessage(JSON.stringify({validInputs:true}))
                }
                else{
                    window.ReactNativeWebView.postMessage(JSON.stringify({validInputs:false}))
                }
            }

            zipCode.addEventListener("input",(e)=>validateZipCode(e.target.value))
            function validateZipCode(value,force){
                var displayError = document.getElementById("card-errors-zip");
                let trimmedValue = value.trim();
                if(value.length != trimmedValue.length){
                    value = trimmedValue;
                    zipCode.value = value;
                }
                if (validator.isPostalCode(value, "US")) {
                    displayError.textContent = "";
                    displayError.style.display = "none";
                    zipCodeValid=true
                    validateAllInputs()
                    return true
                } else {
                    zipCodeValid=false
                    validateAllInputs()
                    if(force){
                        displayError.innerHTML = "Invalid postal code";
                        displayError.style.display = "block";                       
                        return false
                    }
                    if(value && value.length>=5){                    
                        displayError.innerHTML = "Invalid postal code";
                        displayError.style.display = "block";                    
                        return false
                    }
                    
                }
            }

            // Custom styling can be passed into each payment method field.
            var style = {
                base: {
                    // Add your base input styles here. For example:
                    fontSize: "14x",
                    color: "#3d3d3d",
                    borderRadius: "3px",
                    borderColor: "grey",
                    borderWidth: 1,
                    borderStyle: "solid",
                    fontFamily:"muli",
                    height:"100%",
                    padding:0
                },
                invalid:{
                    color:"#ee6352"
                }
            };

            // Create an instance of the cardNumber field
            var cardNumber = paymentMethod.field(
                "cardNumber",
                document.getElementById("card-number"),
                { style: style,placeholder:"Card number",hideIcon:true}
            );
            // Create an instance of the cardExpiration field
            var cardExpiration = paymentMethod.field(
                "cardExpiration",
                document.getElementById("card-exp"),
                { style: style,placeholder:"Expiration date" }
            );

            var form = document.getElementById("payment-form");

            form.addEventListener("submit", function (event) {
                // We will be submitting the form with JS in the next step. For now, prevent
                // it from submitting until the token has been collected.
                
                event.preventDefault();
                //ignore if event was sent within the webview
                if(event.isTrusted){
                    cardNumber.blur();
                    cardExpiration.blur();
                    zipCode.blur();
                    return;
                }
                if(validateZipCode(zipCode.value)){
                hexea
                    .tokenize(paymentMethod, {
                        "email":email,
                        "isSaved":isSaved,
                        "authorizationType":"IDS",
                        "nameOnCard":nameOnCard,
                        "address":{                            
                            "postalCode":zipCode.value,
                            "addressLine1": "NOADDRESSLINE1",
                            "city": "NOCITY",
                            "state":"AL",                          
                            },
                        "userToken":userToken                        
                    })
                    .then(function (result) {
                        if (result.error) {
                            // Inform the payer that there was an error
                            var displayError = document.getElementById("card-errors");
                            displayError.textContent = result.error.message;
                            console.log(result)
                            window.ReactNativeWebView.postMessage(JSON.stringify({tokenError:true,error:result.error,pcct, email, nameOnCard, userToken}))
                        } else {
                            // Send the token to your server
                            console.log(result)
                            window.ReactNativeWebView.postMessage(JSON.stringify(result))
                        }
                    });
                    }
                    else{
                        window.ReactNativeWebView.postMessage(JSON.stringify({tokenError:true}))

                    }
                return false;
            });

            // Listen for change events
            [cardNumber].forEach(function (field) {
                field.on("change", function (event) {
                    console.log(event,field.target);
                    var displayError = document.getElementById("card-errors");
                    if (event.error) {
                            displayError.textContent = event.error.message;
                            displayError.style.display = "block";
                            cardValid=false
                            validateAllInputs()

                    } else {
                        displayError.textContent = "";
                        displayError.style.display = "none";                       
                        cardValid=true
                        validateAllInputs()
                    }
                });
            });

            // Listen for change events
            [cardExpiration].forEach(function (field) {
                field.on("change", function (event) {
                    console.log(event,field.target);
                    var displayErrorDate = document.getElementById("card-errors-date");
                    if (event.error) {                        
                            displayErrorDate.textContent = event.error.message;
                            displayErrorDate.style.display = "block";             
                            dateValid=false
                            validateAllInputs()            
                    } else {                
                        displayErrorDate.textContent = "";
                        displayErrorDate.style.display = "none";
                        dateValid=true
                        validateAllInputs()
                    }
                });
            });

            window.ReactNativeWebView.postMessage(JSON.stringify({hexeaReady:true}))
        }

        function makeid(length) {
            var result = "";
            var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            return result;
        }   
        //internal("0D4786C5C3","aaaaa12124124@hotmail.com","John Doe","GQ34rEGDebysZNkXuAPHqP4usdwRZ86SMESqMqud6ZM")
        `
            }
        </script>
    </Helmet>
}
