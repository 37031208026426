import React from "react";
import CallMadeIcon from "@material-ui/icons/CallMade";
const FloatingButton = ({ color, onClick, title }) => {
  return (
    <span
      onClick={onClick}
      className="RSVP-floating-button-container"
      style={{ backgroundColor: color }}
    >
      <CallMadeIcon
        style={{ fontSize: 20 }}
        className="RSVP-floating-button-icon"
      />
      <input
        type="button"
        className="RSVP-floating-button "
        value={title}
        onClick={onClick}
      />
    </span>
  );
};

export default FloatingButton;
