export const falseUsers = [
    {
      "Client PK": 639,
      "Client ID": "504744396",
      "Client Name": "St Martins Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Phyllis Zerhusen",
      "Contact Email": "mlcyoungstown@yahoo.com"
    },
    {
      "Client PK": 693,
      "Client ID": "502593147",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Richard Stieve",
      "Contact Email": "flcaccountant@yahoo.com"
    },
    {
      "Client PK": 794,
      "Client ID": "504742246",
      "Client Name": "Fortune Lake Lutheran Camp",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nathan Ewart",
      "Contact Email": "dvogt@splcs.net"
    },
    {
      "Client PK": 830,
      "Client ID": "504744109",
      "Client Name": "Pilgrim Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marietta Tartamella",
      "Contact Email": "info@emmanuelypsi.org"
    },
    {
      "Client PK": 936,
      "Client ID": "504750020",
      "Client Name": "St James Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rebecca Pereverzoff",
      "Contact Email": "vhansonzion@gmail.com"
    },
    {
      "Client PK": 949,
      "Client ID": "504732469",
      "Client Name": "King Of Kings Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Sledgianowski",
      "Contact Email": "centluth@gci.net"
    },
    {
      "Client PK": 1017,
      "Client ID": "504739660",
      "Client Name": "Martin Luther Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Ryan",
      "Contact Email": "tammykoch@trinitysp.com"
    },
    {
      "Client PK": 1056,
      "Client ID": "504747002",
      "Client Name": "Luther Memorial Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Catherine Lagaris",
      "Contact Email": "concordiasuperior@gmail.com"
    },
    {
      "Client PK": 1067,
      "Client ID": "504749161",
      "Client Name": "Cormorant Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Eric Dull",
      "Contact Email": "office@stmarksneenah.org"
    },
    {
      "Client PK": 1077,
      "Client ID": "504751475",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kevin Cerda",
      "Contact Email": "loiswei@aol.com"
    },
    {
      "Client PK": 1090,
      "Client ID": "504758017",
      "Client Name": "Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Kosbab",
      "Contact Email": "adorsett@tlcwaupaca.org"
    },
    {
      "Client PK": 1106,
      "Client ID": "502736125",
      "Client Name": "Advent Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie Kohout",
      "Contact Email": "cheryl@clcmarine.org"
    },
    {
      "Client PK": 1119,
      "Client ID": "504744413",
      "Client Name": "Bristol Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Elizabeth Dietz",
      "Contact Email": "accounting@luther-memorial.com"
    },
    {
      "Client PK": 1164,
      "Client ID": "504743377",
      "Client Name": "St James Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sandra Ayers",
      "Contact Email": "finance@lakenokomis.org"
    },
    {
      "Client PK": 1217,
      "Client ID": "504748902",
      "Client Name": "Nordland Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Brown",
      "Contact Email": "kathy.trinity@gmail.com"
    },
    {
      "Client PK": 1224,
      "Client ID": "504754340",
      "Client Name": "Immanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Becky Reidle",
      "Contact Email": "clchurch@loretel.net"
    },
    {
      "Client PK": 1229,
      "Client ID": "504742311",
      "Client Name": "Memorial Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Connie Gullixson",
      "Contact Email": "stevematthews@bresnan.net"
    },
    {
      "Client PK": 1250,
      "Client ID": "507935167",
      "Client Name": "Capitol Hill Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Elizabeth Ruiz",
      "Contact Email": "finance@holytrinityelgin.com"
    },
    {
      "Client PK": 1268,
      "Client ID": "504747724",
      "Client Name": "House Of Hope",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Becky Nyhus",
      "Contact Email": "lauralatzke@sbcglobal.net"
    },
    {
      "Client PK": 1296,
      "Client ID": "504742564",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marty Niedbala",
      "Contact Email": "hope_lutheran@att.net"
    },
    {
      "Client PK": 1301,
      "Client ID": "504740623",
      "Client Name": "Zion Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Lischewski",
      "Contact Email": "nakanob@flchurch.org"
    },
    {
      "Client PK": 1337,
      "Client ID": "504749043",
      "Client Name": "Our Saviors Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tim Wegner",
      "Contact Email": "brant@salemglendale.org"
    },
    {
      "Client PK": 1356,
      "Client ID": "502624011",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pamela Wetterlund",
      "Contact Email": "vsingleton.nlt@gmail.com"
    },
    {
      "Client PK": 1369,
      "Client ID": "504743811",
      "Client Name": "St Stephen The Martyr Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ronald Jorgenson",
      "Contact Email": "phylliszerhusen@gmail.com"
    },
    {
      "Client PK": 1531,
      "Client ID": "504748375",
      "Client Name": "Gustavus Adolphus College",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "David Hedlin",
      "Contact Email": "flckak@outlook.com"
    },
    {
      "Client PK": 1559,
      "Client ID": "502189913",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Kiddy",
      "Contact Email": "secretary2@bristollutheran.org"
    },
    {
      "Client PK": 1616,
      "Client ID": "504745096",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Val Santjer",
      "Contact Email": "pastorsonntag@stpaulsglenside.org"
    },
    {
      "Client PK": 1622,
      "Client ID": "502747144",
      "Client Name": "Faith Lutheran Preschool",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ann Turbiville",
      "Contact Email": "mhkorri@gmail.com"
    },
    {
      "Client PK": 1697,
      "Client ID": "504754812",
      "Client Name": "St Pauls Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Patricia Wein",
      "Contact Email": "james.cramer8029@gmail.com"
    },
    {
      "Client PK": 1805,
      "Client ID": "502256025",
      "Client Name": "St Marks Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Bruce Bullerman",
      "Contact Email": "office@firstlutheran-gladstone.org"
    },
    {
      "Client PK": 1833,
      "Client ID": "502726257",
      "Client Name": "Ascension Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Deacon Kyle Warfield",
      "Contact Email": "assistant@wplchurch.org"
    },
    {
      "Client PK": 1898,
      "Client ID": "504755164",
      "Client Name": "Holy Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brenda Manmiller",
      "Contact Email": "pastorstieve@hotmail.com"
    },
    {
      "Client PK": 1904,
      "Client ID": "504760464",
      "Client Name": "Kennewick First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lisa Willey",
      "Contact Email": "office@trinitytaneytown.org"
    },
    {
      "Client PK": 1948,
      "Client ID": "ELCA7124",
      "Client Name": "Bethlehem Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brenda Skramstad",
      "Contact Email": "cynthia@firstluthbd.org"
    },
    {
      "Client PK": 1953,
      "Client ID": "504735125",
      "Client Name": "St Johns Windish Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Abra Gillman",
      "Contact Email": "oslc@iowatelecom.net"
    },
    {
      "Client PK": 1956,
      "Client ID": "504734912",
      "Client Name": "Ascension Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "William Skelton",
      "Contact Email": "viktoria1914@comcast.net"
    },
    {
      "Client PK": 1964,
      "Client ID": "504747764",
      "Client Name": "King Of Glory Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "BARBARA CARINCI",
      "Contact Email": "tlcoffice@trinitywebstercity.org"
    },
    {
      "Client PK": 1969,
      "Client ID": "504742004",
      "Client Name": "Lebanon Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ruth Ann Diehl",
      "Contact Email": "Bitnerca@gmail.com"
    },
    {
      "Client PK": 1984,
      "Client ID": "504745706",
      "Client Name": "St Johns Luth Church & School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Boring",
      "Contact Email": "shepcluthfinance@gmail.com"
    },
    {
      "Client PK": 1993,
      "Client ID": "504753486",
      "Client Name": "Martin Luther Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Luann Vesy",
      "Contact Email": "rdreedcb@yahoo.com"
    },
    {
      "Client PK": 2003,
      "Client ID": "504739760",
      "Client Name": "Good Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Geri Wilson",
      "Contact Email": "stjamesbett@gmail.com"
    },
    {
      "Client PK": 2049,
      "Client ID": "504759240",
      "Client Name": "Shepherd Of The Hills Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Adriana Vogt",
      "Contact Email": "faithlilacway@q.com"
    },
    {
      "Client PK": 2050,
      "Client ID": "504747028",
      "Client Name": "Cross Of Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jennifer Mays",
      "Contact Email": "richardlburton@msn.com"
    },
    {
      "Client PK": 2085,
      "Client ID": "504754345",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Valerie Hanson",
      "Contact Email": "thomsengrace@gmail.com"
    },
    {
      "Client PK": 2132,
      "Client ID": "501825038",
      "Client Name": "St Pauls Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Voris",
      "Contact Email": "office@holycrosslutheran.net"
    },
    {
      "Client PK": 2159,
      "Client ID": "505648733",
      "Client Name": "St Paul’s Lutheran Church in Lionville",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tammy Koch",
      "Contact Email": "mmeveland@gmail.com"
    },
    {
      "Client PK": 2191,
      "Client ID": "502736500",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nancy O'Neill",
      "Contact Email": "office@hopelutheranfl.org"
    },
    {
      "Client PK": 2232,
      "Client ID": "502289287",
      "Client Name": "Emmaus Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "MaryJane",
      "Contact Email": "secretary@gdlch.org"
    },
    {
      "Client PK": 2237,
      "Client ID": "504738062",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Alicia Dorsett",
      "Contact Email": "officetrinityomaha@gmail.com"
    },
    {
      "Client PK": 2267,
      "Client ID": "504740935",
      "Client Name": "Good Shepherd Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cheryl Reinitz",
      "Contact Email": "the.office.clc@gmail.com"
    },
    {
      "Client PK": 2296,
      "Client ID": "504745018",
      "Client Name": "First American Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Clint Heaser",
      "Contact Email": "nathanewart@gmail.com"
    },
    {
      "Client PK": 2307,
      "Client ID": "502470220",
      "Client Name": "Greenfield Lutheran Church, Elca",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joanne Billmeier",
      "Contact Email": "davidfalksen@gmail.com"
    },
    {
      "Client PK": 2312,
      "Client ID": "504756294",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Gilbertson",
      "Contact Email": "teri@calvarywillmar.org"
    },
    {
      "Client PK": 2358,
      "Client ID": "504744278",
      "Client Name": "Jordan Evan Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Stacey Hovelson",
      "Contact Email": "kc@lifeatctk.org"
    },
    {
      "Client PK": 2409,
      "Client ID": "504748356",
      "Client Name": "Trinity Evan Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Steve Matthews",
      "Contact Email": "marietta.tartamella@gmail.com"
    },
    {
      "Client PK": 2419,
      "Client ID": "C9295116",
      "Client Name": "Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Beth Quathamer",
      "Contact Email": "jwithers@oursaviorplantation.org"
    },
    {
      "Client PK": 2441,
      "Client ID": "504746837",
      "Client Name": "Living Waters Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laura Latzke",
      "Contact Email": "wcsjr76@comcast.net"
    },
    {
      "Client PK": 2455,
      "Client ID": "502470840",
      "Client Name": "Glenville First Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kate Schwartz",
      "Contact Email": "ranaem0426@gmail.com"
    },
    {
      "Client PK": 2480,
      "Client ID": "504747481",
      "Client Name": "Cross of Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brandy Nakano",
      "Contact Email": "barbar@centurylink.net"
    },
    {
      "Client PK": 2570,
      "Client ID": "504759012",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brant Reed",
      "Contact Email": "jmiller@stjohnsgc.org"
    },
    {
      "Client PK": 2659,
      "Client ID": "504737875",
      "Client Name": "Ascension Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Vickie Singleton",
      "Contact Email": "omahaimmanuel@gmail.com"
    },
    {
      "Client PK": 2713,
      "Client ID": "504742763",
      "Client Name": "American Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lallette Kistler",
      "Contact Email": "mlcfinance1940@gmail.com"
    },
    {
      "Client PK": 2728,
      "Client ID": "504742571",
      "Client Name": "Rock Creek Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Kane",
      "Contact Email": "zionhuron@gmail.com"
    },
    {
      "Client PK": 2761,
      "Client ID": "504743839",
      "Client Name": "Messiah Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Henrik Sonntag",
      "Contact Email": "amc@akronmench.org"
    },
    {
      "Client PK": 2763,
      "Client ID": "504740891",
      "Client Name": "Ascension Of Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marsha Korri",
      "Contact Email": "cereinert@sbcglobal.net"
    },
    {
      "Client PK": 2856,
      "Client ID": "C9000115",
      "Client Name": "Victory Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Cramer",
      "Contact Email": "cindyjohngreg4@aol.com"
    },
    {
      "Client PK": 2866,
      "Client ID": "504745314",
      "Client Name": "Rib Mountain Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rhonda Husbye",
      "Contact Email": "info@chlcdesmoines.org"
    },
    {
      "Client PK": 2889,
      "Client ID": "504749482",
      "Client Name": "Blessed Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Girard",
      "Contact Email": "jay@trinityrockford.org"
    },
    {
      "Client PK": 2890,
      "Client ID": "C9087588",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lexi Schafer",
      "Contact Email": "donnadrew@pilgrimindy.org"
    },
    {
      "Client PK": 2902,
      "Client ID": "504753804",
      "Client Name": "Beautiful Savior Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cynthia Larson",
      "Contact Email": "financialsec@bethlehem-lutheran.net"
    },
    {
      "Client PK": 2921,
      "Client ID": "504741819",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pam Steckler",
      "Contact Email": "ryan@trinitymhd.org"
    },
    {
      "Client PK": 2940,
      "Client ID": "504747359",
      "Client Name": "Emanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Monika Drake",
      "Contact Email": "sledgesix@msn.com"
    },
    {
      "Client PK": 2966,
      "Client ID": "504747693",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kelly Law",
      "Contact Email": "jasonlovas@gmail.com"
    },
    {
      "Client PK": 3010,
      "Client ID": "504759102",
      "Client Name": "Prince Of Peace Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cheryl Bitner",
      "Contact Email": "christina.ascension@gmail.com"
    },
    {
      "Client PK": 3043,
      "Client ID": "504732126",
      "Client Name": "St Matthew Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Lou May",
      "Contact Email": "christina.coleman@comcast.net"
    },
    {
      "Client PK": 3045,
      "Client ID": "502736250",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darlene Reed",
      "Contact Email": "dsquibb@ziondallas.org"
    },
    {
      "Client PK": 3048,
      "Client ID": "502279745",
      "Client Name": "Our Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Connie Jacob",
      "Contact Email": "info@trinityboulder.org"
    },
    {
      "Client PK": 3116,
      "Client ID": "504754753",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ann Nowak",
      "Contact Email": "office@goodshepherdsaginaw.com"
    },
    {
      "Client PK": 3143,
      "Client ID": "504738375",
      "Client Name": "Hope Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Richard Burton",
      "Contact Email": "kbrownmath@yahoo.com"
    },
    {
      "Client PK": 3167,
      "Client ID": "504732463",
      "Client Name": "Prince Of Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Grace Thomsen",
      "Contact Email": "treasurer@trinityworc.org"
    },
    {
      "Client PK": 3174,
      "Client ID": "504733260",
      "Client Name": "St Pauls Evangelical Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Adrian Peppers",
      "Contact Email": "jan@flcnorthwood.org"
    },
    {
      "Client PK": 3199,
      "Client ID": "504732861",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marisha M Eveland",
      "Contact Email": "pbingenheimer@ebenezerlutheran.org"
    },
    {
      "Client PK": 3204,
      "Client ID": "504751448",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cindy Stewart",
      "Contact Email": "office@zionlutheranchurch.net"
    },
    {
      "Client PK": 3290,
      "Client ID": "504751518",
      "Client Name": "St Andrew Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Harris",
      "Contact Email": "haneyjc@comcast.net"
    },
    {
      "Client PK": 3335,
      "Client ID": "504738574",
      "Client Name": "Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carri Prusia",
      "Contact Email": "officemgr@plcsuperior.org"
    },
    {
      "Client PK": 3357,
      "Client ID": "504743401",
      "Client Name": "Gloria Dei",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jane Amble",
      "Contact Email": "zioninfo@zionluthlakecrystal.org"
    },
    {
      "Client PK": 3362,
      "Client ID": "504742609",
      "Client Name": "Grace Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "David Falksen",
      "Contact Email": "treasurer@celebrationaz.com"
    },
    {
      "Client PK": 3368,
      "Client ID": "504753788",
      "Client Name": "Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Teri Sokulski",
      "Contact Email": "jeej30@aol.com"
    },
    {
      "Client PK": 3431,
      "Client ID": "504736590",
      "Client Name": "Trinity Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "KC Gubrud",
      "Contact Email": "stjohnlutheranmineola@hotmail.com"
    },
    {
      "Client PK": 3447,
      "Client ID": "502248032",
      "Client Name": "Evergreen Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Janice Withers",
      "Contact Email": "gmhagen@yahoo.com"
    },
    {
      "Client PK": 3492,
      "Client ID": "504743404",
      "Client Name": "Park View Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Bill Stoltenberg",
      "Contact Email": "oslcbarnesville@gmail.com"
    },
    {
      "Client PK": 3499,
      "Client ID": "504735739",
      "Client Name": "St Matthews Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "RaNae Martinson",
      "Contact Email": "jean4nier49@gmail.com"
    },
    {
      "Client PK": 3511,
      "Client ID": "504734554",
      "Client Name": "St Pauls Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barbara Rusnacko",
      "Contact Email": "officemanager@fortunelake.org"
    },
    {
      "Client PK": 3517,
      "Client ID": "504755062",
      "Client Name": "Christ The King Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jan Miller",
      "Contact Email": "lchsfinance@gmail.com"
    },
    {
      "Client PK": 3540,
      "Client ID": "504735765",
      "Client Name": "St Luke Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Judy McNew",
      "Contact Email": "finance@oakharborchurch.com"
    },
    {
      "Client PK": 3761,
      "Client ID": "504736467",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Judy Newhouse",
      "Contact Email": "clagaris@hephatha.net"
    },
    {
      "Client PK": 3773,
      "Client ID": "504750383",
      "Client Name": "First Lutheran Church of Harvey",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Suzanne McFee",
      "Contact Email": "administrator@dhlc.org"
    },
    {
      "Client PK": 3788,
      "Client ID": "504750781",
      "Client Name": "Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christine Reinert",
      "Contact Email": "lutepk@hotmail.com"
    },
    {
      "Client PK": 3789,
      "Client ID": "504736434",
      "Client Name": "Good Shepherd Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cynthia Gregory",
      "Contact Email": "ejdull69@gmail.com"
    },
    {
      "Client PK": 3806,
      "Client ID": "504760411",
      "Client Name": "St Marks Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa Daniel-Way",
      "Contact Email": "jstudier@lcrelca.org"
    },
    {
      "Client PK": 3854,
      "Client ID": "504739809",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jay P. Quinn",
      "Contact Email": "mdhart@bellsouth.net"
    },
    {
      "Client PK": 3881,
      "Client ID": "504746022",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Drew",
      "Contact Email": "jusjmiller@aol.com"
    },
    {
      "Client PK": 3884,
      "Client ID": "504749510",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jenny Kelley",
      "Contact Email": "darrin.labensky@stmarklacey.org"
    },
    {
      "Client PK": 3936,
      "Client ID": "504756848",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ryan George",
      "Contact Email": "redeemerchicooffice@gmail.com"
    },
    {
      "Client PK": 3959,
      "Client ID": "504760002",
      "Client Name": "Burlington Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jason Lovas",
      "Contact Email": "office@stjohnbelleplaine.org"
    },
    {
      "Client PK": 3996,
      "Client ID": "504760438",
      "Client Name": "Zion Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christina Brooks",
      "Contact Email": "pastor@princeofpeaceshoreline.com"
    },
    {
      "Client PK": 4009,
      "Client ID": "502734341",
      "Client Name": "St Olaf Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christina Montgomery",
      "Contact Email": "christ.marshfield@gmail.com"
    },
    {
      "Client PK": 4011,
      "Client ID": "504742302",
      "Client Name": "Grace Lutheran Church of Adel Iowa",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Squibb",
      "Contact Email": "nheimann@epiphanydayton.org"
    },
    {
      "Client PK": 4102,
      "Client ID": "504743558",
      "Client Name": "St Johns Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melanie Nehlsburow",
      "Contact Email": "mitchw@atonluth.org"
    },
    {
      "Client PK": 4157,
      "Client ID": "504744528",
      "Client Name": "West Middleton Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melinda Kisser",
      "Contact Email": "ccook@immanuelstcharles.org"
    },
    {
      "Client PK": 4171,
      "Client ID": "504743871",
      "Client Name": "Atonement Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Brown",
      "Contact Email": "dlhn1128@comcast.net"
    },
    {
      "Client PK": 4191,
      "Client ID": "504744685",
      "Client Name": "Lutheran Church Of Peace",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Scott Baeder",
      "Contact Email": "ccarpenter@trinityutica.com"
    },
    {
      "Client PK": 4200,
      "Client ID": "504746745",
      "Client Name": "First Lutheran Church ELCA",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jan Thompson",
      "Contact Email": "office@stthomasgi.com"
    },
    {
      "Client PK": 4248,
      "Client ID": "502273621",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Phil Bingenheimer",
      "Contact Email": "Julie@FLCCH.org"
    },
    {
      "Client PK": 4367,
      "Client ID": "504741722",
      "Client Name": "St Stephens Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Chloe Steele",
      "Contact Email": "bookkeeper@stlcfamily.org"
    },
    {
      "Client PK": 4388,
      "Client ID": "504756561",
      "Client Name": "Lord Of Life Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Maureen Haney",
      "Contact Email": "paul.hensel@trinitycamphill.org"
    },
    {
      "Client PK": 4439,
      "Client ID": "504748571",
      "Client Name": "Immanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Curt Hanson",
      "Contact Email": "busmgr@ilcsw.net"
    },
    {
      "Client PK": 4470,
      "Client ID": "504747123",
      "Client Name": "Cross Of Glory Mounds View",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Gilman",
      "Contact Email": "church@gatheredbygrace.org"
    },
    {
      "Client PK": 4524,
      "Client ID": "504757852",
      "Client Name": "All Saints Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nancy Fisher",
      "Contact Email": "ssjjpeterson@yahoo.com"
    },
    {
      "Client PK": 4577,
      "Client ID": "504739565",
      "Client Name": "Our Saviour Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Janet Rhymer",
      "Contact Email": "tvasold@verizon.net"
    },
    {
      "Client PK": 4628,
      "Client ID": "504735415",
      "Client Name": "Family Of God Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michelle Woodrum",
      "Contact Email": "sjelinski@ezekiellutheran.org"
    },
    {
      "Client PK": 4690,
      "Client ID": "502283656",
      "Client Name": "St Paul Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marcia Hagen",
      "Contact Email": "finance@stpaulsh.org"
    },
    {
      "Client PK": 4733,
      "Client ID": "504750156",
      "Client Name": "Our Saviors Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Megan Bauer",
      "Contact Email": "clcbizmgr@calvarylutheranchurch.org.com"
    },
    {
      "Client PK": 4734,
      "Client ID": "504746891",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jean Fournier",
      "Contact Email": "church@atonementmke.org"
    },
    {
      "Client PK": 4738,
      "Client ID": "504750447",
      "Client Name": "Atonement Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Daaron Dohler",
      "Contact Email": "jheier@bethlehemaberdeen.org"
    },
    {
      "Client PK": 4784,
      "Client ID": "504743486",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cindy Hemminger",
      "Contact Email": "finco@faithlc.com"
    },
    {
      "Client PK": 4792,
      "Client ID": "504755285",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kenny Rupp",
      "Contact Email": "kfread@oursaviourslc.org"
    },
    {
      "Client PK": 4822,
      "Client ID": "504749658",
      "Client Name": "Springdale Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "James Stolte",
      "Contact Email": "kvince@bex.net"
    },
    {
      "Client PK": 4824,
      "Client ID": "504742982",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Janine Studier",
      "Contact Email": "ademarco11@verizon.net"
    },
    {
      "Client PK": 4852,
      "Client ID": "504745650",
      "Client Name": "North Beaver Creek Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michael Hartzog",
      "Contact Email": "kanderson@tlcmoline.org"
    },
    {
      "Client PK": 4861,
      "Client ID": "504744823",
      "Client Name": "Holy Cross Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jane Miller",
      "Contact Email": "gailjdlittle@hotmail.com"
    },
    {
      "Client PK": 4878,
      "Client ID": "504760347",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darrin G Labensky",
      "Contact Email": "info@pilgrimtosa.org"
    },
    {
      "Client PK": 4879,
      "Client ID": "504758068",
      "Client Name": "Lutheran Church of the Resurrection",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jacqueline Vieira",
      "Contact Email": "emanuel@bright.net"
    },
    {
      "Client PK": 4885,
      "Client ID": "504744775",
      "Client Name": "Bonnet Prairie Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rae Fimon",
      "Contact Email": "lizdietzpaw@yahoo.com"
    },
    {
      "Client PK": 4931,
      "Client ID": "504745276",
      "Client Name": "Christ Community Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pamela Russell",
      "Contact Email": "ddawson@easter.org"
    },
    {
      "Client PK": 4938,
      "Client ID": "504740092",
      "Client Name": "St Johns Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dana Speth",
      "Contact Email": "kim.burdett@clcop.org"
    },
    {
      "Client PK": 4943,
      "Client ID": "504751240",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Natalie Heimann",
      "Contact Email": "rgappleton@gmail.com"
    },
    {
      "Client PK": 4959,
      "Client ID": "504740201",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mitch Wyant",
      "Contact Email": "rick@gslacrosse.org"
    },
    {
      "Client PK": 4998,
      "Client ID": "504743001",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carolyn Cook",
      "Contact Email": "bookkeeper@standrewpsl.org"
    },
    {
      "Client PK": 5074,
      "Client ID": "504749184",
      "Client Name": "Our Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Null",
      "Contact Email": "kclowney@stjamesgettysburg.org"
    },
    {
      "Client PK": 5082,
      "Client ID": "504747154",
      "Client Name": "Emmaus Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cyndi Carpenter",
      "Contact Email": "guy@templinfamily.org"
    },
    {
      "Client PK": 5101,
      "Client ID": "504570605",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tim Perry",
      "Contact Email": "tlblatt@comcast.net"
    },
    {
      "Client PK": 5109,
      "Client ID": "504747864",
      "Client Name": "Our Saviors Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie Wilson",
      "Contact Email": "business@ctkbham.org"
    },
    {
      "Client PK": 5123,
      "Client ID": "504743918",
      "Client Name": "Norway Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Debra Springborn",
      "Contact Email": "lori.haag@naz.org"
    },
    {
      "Client PK": 5129,
      "Client ID": "504745736",
      "Client Name": "Our Saviors Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Paul Hensel",
      "Contact Email": "baxtman@trinityfreistadt.com"
    },
    {
      "Client PK": 5182,
      "Client ID": "504734396",
      "Client Name": "Mt Calvary Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Anna Brandt",
      "Contact Email": "beiselt@trinitylc.org"
    },
    {
      "Client PK": 5206,
      "Client ID": "504735145",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Anne Larsen",
      "Contact Email": "office@poproseville.org"
    },
    {
      "Client PK": 5208,
      "Client ID": "505290869",
      "Client Name": "Shepherd Of The Hills Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Stephen Peterson",
      "Contact Email": "rjska@yahoo.com"
    },
    {
      "Client PK": 5455,
      "Client ID": "C9807307",
      "Client Name": "Our Savior Lutheran Church and School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tom Vasold",
      "Contact Email": "rindee@trinitylonglake.org"
    },
    {
      "Client PK": 5511,
      "Client ID": "504758312",
      "Client Name": "Mt Calvary Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Steve Jelinski",
      "Contact Email": "mikec@waconiafaithlutheran.com"
    },
    {
      "Client PK": 5521,
      "Client ID": "504748302",
      "Client Name": "Hayward Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ken Hibbert",
      "Contact Email": "ulch@ulch.org"
    },
    {
      "Client PK": 5664,
      "Client ID": "504744967",
      "Client Name": "Trinity Ev Lutheran Church & School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gabriel Romero",
      "Contact Email": "gsc-mec@usfamily.net"
    },
    {
      "Client PK": 5726,
      "Client ID": "C9136208",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Janis Heier",
      "Contact Email": "gwendonev@gustavus.edu"
    },
    {
      "Client PK": 5739,
      "Client ID": "504752316",
      "Client Name": "Salem Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sara Wegner",
      "Contact Email": "lynne.inman@mlcauburn.org"
    },
    {
      "Client PK": 5819,
      "Client ID": "504747926",
      "Client Name": "Messiah Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kimberly Fread",
      "Contact Email": "kkohn@hope-lutheran.org"
    },
    {
      "Client PK": 5826,
      "Client ID": "504735373",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michael Vince",
      "Contact Email": "church@foxpointchurch.org"
    },
    {
      "Client PK": 5847,
      "Client ID": "504760266",
      "Client Name": "Bethany Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Austin DeMarco",
      "Contact Email": "bookkeeper@tllc.org"
    },
    {
      "Client PK": 5881,
      "Client ID": "504735768",
      "Client Name": "St Johns Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Anderson",
      "Contact Email": "donnab@faithlutheran-becker.org"
    },
    {
      "Client PK": 5910,
      "Client ID": "502567677",
      "Client Name": "Faith Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gail Little",
      "Contact Email": "pella@pellachurch.net"
    },
    {
      "Client PK": 5982,
      "Client ID": "504735157",
      "Client Name": "Trinity Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darci Dawson",
      "Contact Email": "flcfindlay@gmail.com"
    },
    {
      "Client PK": 6165,
      "Client ID": "504742562",
      "Client Name": "St Luke Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kim Burdett",
      "Contact Email": "d2032arnold@gmail.com"
    },
    {
      "Client PK": 6175,
      "Client ID": "504760531",
      "Client Name": "Epiphany Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ronald Appleton",
      "Contact Email": "rdulas@wasecagracelutheran.com"
    },
    {
      "Client PK": 6234,
      "Client ID": "504743296",
      "Client Name": "Bethany Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rick Laufenberg",
      "Contact Email": "bookkeeper@mtolivelutheran.org"
    },
    {
      "Client PK": 6301,
      "Client ID": "504748520",
      "Client Name": "Shetek Lutheran Ministries",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carolyn Tiedemann",
      "Contact Email": "finance@holyspiritllc.net"
    },
    {
      "Client PK": 6426,
      "Client ID": "502290197",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Katheryn M L Clowney",
      "Contact Email": "jperr@christbrea.com"
    },
    {
      "Client PK": 6484,
      "Client ID": "504737953",
      "Client Name": "Peace Lutheran Church of Palm Bay",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Guy Templin",
      "Contact Email": "t04irish@gmail.com"
    },
    {
      "Client PK": 6598,
      "Client ID": "504741954",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Thomas Blatt",
      "Contact Email": "officeadmin@adventlutheran.com"
    },
    {
      "Client PK": 6693,
      "Client ID": "504750581",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tom Nelson",
      "Contact Email": "parishadmin@flrb.org"
    },
    {
      "Client PK": 6725,
      "Client ID": "504749289",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lori Haag",
      "Contact Email": "messiahbookkeep@gmail.com"
    },
    {
      "Client PK": 6732,
      "Client ID": "504737303",
      "Client Name": "St James Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brian Axtman",
      "Contact Email": "melanie@goodshepherdbismarck.com"
    },
    {
      "Client PK": 6756,
      "Client ID": "504748083",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Robert Eiselt",
      "Contact Email": "joanne.scherer@gmail.com"
    },
    {
      "Client PK": 6797,
      "Client ID": "504738936",
      "Client Name": "Hosanna Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Peter Christ",
      "Contact Email": "chuck@pontonline.org"
    },
    {
      "Client PK": 6807,
      "Client ID": "504735680",
      "Client Name": "Community Of Love Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rindee Erdmann",
      "Contact Email": "stjohnbkpg@aol.com"
    },
    {
      "Client PK": 6811,
      "Client ID": "504749534",
      "Client Name": "Shalom Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michael Christopherson",
      "Contact Email": "bookkeeper@epiphany-lutheran.com"
    },
    {
      "Client PK": 6820,
      "Client ID": "504749989",
      "Client Name": "Calvary Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nathan Obrestad",
      "Contact Email": "rlc@acegroup.cc"
    },
    {
      "Client PK": 6846,
      "Client ID": "504745407",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Grant Christiansen",
      "Contact Email": "ctkadmin@hutchtel.net"
    },
    {
      "Client PK": 6929,
      "Client ID": "504741040",
      "Client Name": "King of Kings Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gwen Donev",
      "Contact Email": "amber@trinity-ec.org"
    },
    {
      "Client PK": 7052,
      "Client ID": "504739000",
      "Client Name": "Clinton Heights Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lynne Inman",
      "Contact Email": "mmua@rlc-wbl.org"
    },
    {
      "Client PK": 7099,
      "Client ID": "C9185114",
      "Client Name": "Messiah Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Katherine Kohn",
      "Contact Email": "laura.kohlmeier@orlctosa.org"
    },
    {
      "Client PK": 7134,
      "Client ID": "C9999002",
      "Client Name": "Lutheran Mission Society",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jo Ann Berdelman",
      "Contact Email": "office@atonementbillings.org"
    },
    {
      "Client PK": 7312,
      "Client ID": "ES6800",
      "Client Name": "Church Of The Savior",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gary Collier",
      "Contact Email": "zionlc@baldwin-telecom.net"
    },
    {
      "Client PK": 7333,
      "Client ID": "UCC361020",
      "Client Name": "Lynnhurst Congregational Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nina Dukes",
      "Contact Email": "tjhornseth@charter.net"
    },
    {
      "Client PK": 7539,
      "Client ID": "ES6903",
      "Client Name": "New Hope Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Douglas Arnold",
      "Contact Email": "bellis@faithfairoaks.com"
    },
    {
      "Client PK": 7689,
      "Client ID": "504752245",
      "Client Name": "Evangelical Lutheran Church of Our Redeemer",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Robin Dulas",
      "Contact Email": "lhofeldt@rosevillelutheran.org"
    },
    {
      "Client PK": 7690,
      "Client ID": "504737097",
      "Client Name": "Holy Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Debra Zematis",
      "Contact Email": "kmcgin4449@gmail.com"
    },
    {
      "Client PK": 7719,
      "Client ID": "16230596324",
      "Client Name": "Mason First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joy Perr",
      "Contact Email": "gdenninger@moply.org"
    },
    {
      "Client PK": 7739,
      "Client ID": "17112640946",
      "Client Name": "Minnehaha United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tanner Eubank",
      "Contact Email": "accounting@stjohnbeatrice.org"
    },
    {
      "Client PK": 7762,
      "Client ID": "14635609280",
      "Client Name": "New Bethel United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Allison Nahr",
      "Contact Email": "joyce.nelson@osel.org"
    },
    {
      "Client PK": 7776,
      "Client ID": "59123750156",
      "Client Name": "Aldersgate United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Ewing",
      "Contact Email": "swenas12@gmail.com"
    },
    {
      "Client PK": 7782,
      "Client ID": "31540002101",
      "Client Name": "Gray Memorial United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dennis Wohlers",
      "Contact Email": "elizruiz@blcmankato.org"
    },
    {
      "Client PK": 7783,
      "Client ID": "32108182680",
      "Client Name": "Faith United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melanie Nicklos",
      "Contact Email": "bookkeeper@saint-paul.org"
    },
    {
      "Client PK": 7789,
      "Client ID": "17124641883",
      "Client Name": "Wesley United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joanne Scherer",
      "Contact Email": "deppengelc@gmail.com"
    },
    {
      "Client PK": 7795,
      "Client ID": "17134645265",
      "Client Name": "Stewartville United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Charles Ritterman",
      "Contact Email": "financialsecretary@immanuelplainview.org"
    },
    {
      "Client PK": 7958,
      "Client ID": "17129642513",
      "Client Name": "Alexandria United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carolyn Holtz",
      "Contact Email": "Beckers56@hotmail.com"
    },
    {
      "Client PK": 7977,
      "Client ID": "MC18564",
      "Client Name": "Harrisonburg Mennonite Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carl Rayburn",
      "Contact Email": "edenrollins@yahoo.com"
    },
    {
      "Client PK": 8110,
      "Client ID": "502736353",
      "Client Name": "Holy Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jean Krambeer",
      "Contact Email": "finance@mt-olive.org"
    },
    {
      "Client PK": 8180,
      "Client ID": "UCC690050",
      "Client Name": "Parkview Congregational Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laura Lansink",
      "Contact Email": "hopelutheranwarren@sbcglobal.net"
    },
    {
      "Client PK": 8594,
      "Client ID": "504746859",
      "Client Name": "Highview Christiania Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Amber Moltzau",
      "Contact Email": "lstein@immanuelbrookfield.org"
    },
    {
      "Client PK": 8597,
      "Client ID": "504750810",
      "Client Name": "Shepherd's Table",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Magnolia Mua",
      "Contact Email": "bethanychurchlady@gmail.com"
    },
    {
      "Client PK": 8709,
      "Client ID": "ES3609",
      "Client Name": "Grace-Trinity Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laura Kohlmeier",
      "Contact Email": "ltsmily@gmail.com"
    },
    {
      "Client PK": 8711,
      "Client ID": "ES3736",
      "Client Name": "St Johns United Church Of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nancy Rupe",
      "Contact Email": "busmgr@ilcsw.net"
    },
    {
      "Client PK": 8949,
      "Client ID": "504738294",
      "Client Name": "Lamb of God Lutheran Episcopal Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Richard Rademaker",
      "Contact Email": "treasurer@trinitycincy.org"
    },
    {
      "Client PK": 8990,
      "Client ID": "ES5030",
      "Client Name": "Herndon Friends Meeting",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tim Hornseth",
      "Contact Email": "bookkeeper@stpaulsgi.org"
    },
    {
      "Client PK": 9150,
      "Client ID": "ES5549",
      "Client Name": "High Plains Church - Unitarian Universalist",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barbara Ellis",
      "Contact Email": "office@stpaulref.org"
    },
    {
      "Client PK": 9545,
      "Client ID": "ES2367",
      "Client Name": "The Salvation Army",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laurel Hofeldt",
      "Contact Email": "chrisk@timothylutheran.com"
    },
    {
      "Client PK": 9860,
      "Client ID": "19465678766",
      "Client Name": "Hope United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kristin McGinness",
      "Contact Email": "shawnd@wi.rr.com"
    },
    {
      "Client PK": 10392,
      "Client ID": "504741696",
      "Client Name": "Faith Lutheran Ch of Meridian Township",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gwen Denninger",
      "Contact Email": "htoffice@holytrinityelca.info"
    },
    {
      "Client PK": 10463,
      "Client ID": "504737810",
      "Client Name": "Epiphany Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jeannie Thomas",
      "Contact Email": "sothrichfield@gmail.com"
    },
    {
      "Client PK": 10498,
      "Client ID": "15010553280",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joyce Nelson",
      "Contact Email": "proyer@ststephen.net"
    },
    {
      "Client PK": 10741,
      "Client ID": "MC13474",
      "Client Name": "West Union Mennonite Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Arnold Swenson",
      "Contact Email": "grace@gctel.com"
    },
    {
      "Client PK": 10902,
      "Client ID": "504745571",
      "Client Name": "Calvary Evangelical Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dianna Leo",
      "Contact Email": "churchoffice@stjohnsbaraboo.org"
    },
    {
      "Client PK": 11020,
      "Client ID": "17129639207",
      "Client Name": "Annandale United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Deppen",
      "Contact Email": "cindy@sclc-rc.org"
    },
    {
      "Client PK": 11082,
      "Client ID": "59230763353",
      "Client Name": "Helotes Hills United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Richard Zabel",
      "Contact Email": "oslcsp@oslcsp.org"
    },
    {
      "Client PK": 11226,
      "Client ID": "UCC600320",
      "Client Name": "New Goshenhoppen UCC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Evelyn Rollins",
      "Contact Email": "finsec@flcgainesville.org"
    },
    {
      "Client PK": 11653,
      "Client ID": "504743302",
      "Client Name": "Good Shepherd Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lachele Auvigne",
      "Contact Email": "lisa.ziegert@stpaulgb.com"
    },
    {
      "Client PK": 11663,
      "Client ID": "UCC061530",
      "Client Name": "First Congregational Church of Westfield NJ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laurel Stein",
      "Contact Email": "dkjar@flcfargo.org"
    },
    {
      "Client PK": 11709,
      "Client ID": "17106639548",
      "Client Name": "United Methodist Church of Elk River",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Daleen Haifley",
      "Contact Email": "bgreen@friedenslutheran.org"
    },
    {
      "Client PK": 11877,
      "Client ID": "UCC280600",
      "Client Name": "Congregational Church in Cumberland",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Smith",
      "Contact Email": "admin@stmarksconshy.org"
    },
    {
      "Client PK": 11971,
      "Client ID": "C9989646",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Anna Brandt",
      "Contact Email": "karenbaldwin40@gmail.com"
    },
    {
      "Client PK": 12002,
      "Client ID": "UCC041220",
      "Client Name": "Kensington Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joyce Greber",
      "Contact Email": "noel.becraft@peacewels.org"
    },
    {
      "Client PK": 12007,
      "Client ID": "UCC021130",
      "Client Name": "Peace United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Feldotto",
      "Contact Email": "darrell.granahan@ourimmanuel.org"
    },
    {
      "Client PK": 12030,
      "Client ID": "514103864",
      "Client Name": "New Covenant Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jennifer Walding",
      "Contact Email": "johnl@bethelhudson.org"
    },
    {
      "Client PK": 12131,
      "Client ID": "UCC863020",
      "Client Name": "First Congregational Church, UCC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Chris Krenz",
      "Contact Email": "juliesiegmund@outlook.com"
    },
    {
      "Client PK": 12208,
      "Client ID": "504747858",
      "Client Name": "Bethesda Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Shawn Douglas",
      "Contact Email": "financial.secretary@ascensionlouisville.org"
    },
    {
      "Client PK": 12280,
      "Client ID": "514152934",
      "Client Name": "Lifesong Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Phylaine Nicholas",
      "Contact Email": "businessmanager@clnorfolk.org"
    },
    {
      "Client PK": 12380,
      "Client ID": "504759517",
      "Client Name": "Bethel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Susan Faber",
      "Contact Email": "kathyhamilton60124@gmail.com"
    },
    {
      "Client PK": 12399,
      "Client ID": "C9622837",
      "Client Name": "Bethany Lutheran Church & School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Paul Royer",
      "Contact Email": "twegner@stjamesshawano.org"
    },
    {
      "Client PK": 12579,
      "Client ID": "504738345",
      "Client Name": "Atonement Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kelli Wendel",
      "Contact Email": "cswensrud@firstlutheranwilliston.com"
    },
    {
      "Client PK": 12631,
      "Client ID": "504741862",
      "Client Name": "Immanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Giese",
      "Contact Email": "dawn@stlukechurch.com"
    },
    {
      "Client PK": 12730,
      "Client ID": "ELCA6741",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cindy Dressler",
      "Contact Email": "erik.borgmeyer@messiah.us"
    },
    {
      "Client PK": 13134,
      "Client ID": "504737849",
      "Client Name": "St. Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Holly Lohse",
      "Contact Email": "wllcfinance@wllc.org"
    },
    {
      "Client PK": 13273,
      "Client ID": "C7000772",
      "Client Name": "Christ Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gail Mahnken",
      "Contact Email": "jlaugesen59@gmail.com"
    },
    {
      "Client PK": 13345,
      "Client ID": "ES8520",
      "Client Name": "Unity of Kansas City North",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lisa Ziegert",
      "Contact Email": "businessadmin@calvaryftw.org"
    },
    {
      "Client PK": 13356,
      "Client ID": "504736807",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Danelle Kjar",
      "Contact Email": "sandyh@trinitywels.com"
    },
    {
      "Client PK": 13364,
      "Client ID": "C9752395",
      "Client Name": "Ramona Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brook Green",
      "Contact Email": "finsechtlc@gmail.com"
    },
    {
      "Client PK": 13528,
      "Client ID": "ES8604",
      "Client Name": "Liberty Baptist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Pokalo",
      "Contact Email": "stplc.office@sbcglobal.net"
    },
    {
      "Client PK": 13558,
      "Client ID": "UCC560440",
      "Client Name": "Dover United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Baldwin",
      "Contact Email": "kennewickflc@gmail.com"
    },
    {
      "Client PK": 13618,
      "Client ID": "ELCA4209",
      "Client Name": "A Mighty Fortress Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Noel Becraft",
      "Contact Email": "office@sothchurch.com"
    },
    {
      "Client PK": 13753,
      "Client ID": "504754021",
      "Client Name": "Resurrection Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darrell Granahan",
      "Contact Email": "pwetterlund@htlcmpls.org"
    },
    {
      "Client PK": 13855,
      "Client ID": "UCC861630",
      "Client Name": "Community United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie A. Siegmund",
      "Contact Email": "stjohn.carroll@hotmail.com"
    },
    {
      "Client PK": 13868,
      "Client ID": "504736309",
      "Client Name": "Bethany Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda Herb",
      "Contact Email": "office@christtheservant.org"
    },
    {
      "Client PK": 14015,
      "Client ID": "ES8838",
      "Client Name": "Waterford Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carol Uhing",
      "Contact Email": "elc@mhtc.net"
    },
    {
      "Client PK": 14019,
      "Client ID": "504745673",
      "Client Name": "Halfway Creek Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathleen M Hamilton",
      "Contact Email": "stacy.gibbens@ielcth.org"
    },
    {
      "Client PK": 14055,
      "Client ID": "504751207",
      "Client Name": "Lord and Savior Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Charlotte Swensrud",
      "Contact Email": "treasurer@divinegrace.net"
    },
    {
      "Client PK": 14115,
      "Client ID": "504736056",
      "Client Name": "Gloria Dei Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dawn Juliano",
      "Contact Email": "finance@proctorlutheran.org"
    },
    {
      "Client PK": 14155,
      "Client ID": "UCC620990",
      "Client Name": "First United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Erik Borgmeyer",
      "Contact Email": "office@christlutherandeforest.org"
    },
    {
      "Client PK": 14176,
      "Client ID": "504748468",
      "Client Name": "Bethany-Elim Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pastor Tim Herzfeldt-Kamp",
      "Contact Email": "fuller58@windstream.net"
    },
    {
      "Client PK": 14183,
      "Client ID": "MI0507",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Laugesen",
      "Contact Email": "c.brazil@trinitybrillion.org"
    },
    {
      "Client PK": 14200,
      "Client ID": "11030999886",
      "Client Name": "United Protestant Church of Grayslake",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tammy Nadaskay",
      "Contact Email": "office@stjohnswindish.org"
    },
    {
      "Client PK": 14285,
      "Client ID": "C9139589",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sandra Huebner",
      "Contact Email": "dwaugh@messiahfp.org"
    },
    {
      "Client PK": 14313,
      "Client ID": "504754046",
      "Client Name": "Gloria Dei Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Judith Lauritsen",
      "Contact Email": "office@ascensionws.org"
    },
    {
      "Client PK": 14322,
      "Client ID": "504760478",
      "Client Name": "Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Olga Kowalski",
      "Contact Email": "joletzke@comcast.net"
    },
    {
      "Client PK": 14414,
      "Client ID": "504745974",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa Zerb",
      "Contact Email": "tpma1982@aol.com"
    },
    {
      "Client PK": 14505,
      "Client ID": "504756985",
      "Client Name": "Lord of the Valley Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Shawn Wacholz",
      "Contact Email": "dhurst@trinityaa.org"
    },
    {
      "Client PK": 14567,
      "Client ID": "504739531",
      "Client Name": "Our Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pastor or Treasurer",
      "Contact Email": "pastor@sjsparta.com"
    },
    {
      "Client PK": 14569,
      "Client ID": "504745651",
      "Client Name": "Living Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ellen Imp",
      "Contact Email": "stlukelc@ptd.net"
    },
    {
      "Client PK": 14596,
      "Client ID": "504760529",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy O'Donnell",
      "Contact Email": "peacechurch@peacenewlenox.org"
    },
    {
      "Client PK": 14636,
      "Client ID": "UCC170690",
      "Client Name": "St John United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Stacy Gibbens",
      "Contact Email": "office@mllchurch.com"
    },
    {
      "Client PK": 14656,
      "Client ID": "504735071",
      "Client Name": "Evang Luth Church of the Good Shepherd",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christine Pasek",
      "Contact Email": "Finsec@holyspirit-emmaus.org"
    },
    {
      "Client PK": 14748,
      "Client ID": "504739215",
      "Client Name": "St. Stephen Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Alice Marohl",
      "Contact Email": "bookkeeper@goodhopelutheran.com"
    },
    {
      "Client PK": 14904,
      "Client ID": "504743635",
      "Client Name": "Holy Trinity Ev Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jane Fuller",
      "Contact Email": "dmlc@gdinet.com"
    },
    {
      "Client PK": 15134,
      "Client ID": "504757912",
      "Client Name": "Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cheryl Brazil",
      "Contact Email": "rscott@stpetersfw.org"
    },
    {
      "Client PK": 15379,
      "Client ID": "504755710",
      "Client Name": "Grace Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Novak",
      "Contact Email": "slc@silverdalelutheran.org"
    },
    {
      "Client PK": 15457,
      "Client ID": "10545557802",
      "Client Name": "Knoxville First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darlene Waugh",
      "Contact Email": "shawnabookkeeper@gmail.com"
    },
    {
      "Client PK": 15591,
      "Client ID": "504751195",
      "Client Name": "Trinity Lutheran Church of Rolling Meadows",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Doutrich",
      "Contact Email": "office@resgc.org"
    },
    {
      "Client PK": 15598,
      "Client ID": "16115584086",
      "Client Name": "Court Street United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Oletzke",
      "Contact Email": "accountspayable@lol.org"
    },
    {
      "Client PK": 15663,
      "Client ID": "504755636",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tony DeNicolo",
      "Contact Email": "billandmarthap@gmail.com"
    },
    {
      "Client PK": 15810,
      "Client ID": "UCC163800",
      "Client Name": "St. Peter's United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Daryl Hurst",
      "Contact Email": "crossofchristtreasurer@gmail.com"
    },
    {
      "Client PK": 16049,
      "Client ID": "38130167890",
      "Client Name": "Olivet United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Meyer",
      "Contact Email": "firstlutheran@mainstreetcom.com"
    },
    {
      "Client PK": 16082,
      "Client ID": "504740094",
      "Client Name": "St. Luke's Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Holly Seward",
      "Contact Email": "don.c@bushmillsethanol.com"
    },
    {
      "Client PK": 16171,
      "Client ID": "35320080342",
      "Client Name": "Tunkhannock United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda McKaig",
      "Contact Email": "angie@gswausau.org"
    },
    {
      "Client PK": 16208,
      "Client ID": "54215793907",
      "Client Name": "Bristol Hill United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Terri Hinderliter",
      "Contact Email": "grace@graceocontofalls.org"
    },
    {
      "Client PK": 16222,
      "Client ID": "UCC690740",
      "Client Name": "United Church of Christ Parker Hilltop",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Caroline Metcalf",
      "Contact Email": "finance@memoriallutheran.org"
    },
    {
      "Client PK": 16294,
      "Client ID": "ES9871",
      "Client Name": "Bellingham Unitarian Fellowship Inc.",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Engel",
      "Contact Email": "dgkiddy@gmail.com"
    },
    {
      "Client PK": 16308,
      "Client ID": "502736085",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rhonda Scott",
      "Contact Email": "schaffner@trinitybismarck.com"
    },
    {
      "Client PK": 16452,
      "Client ID": "504735434",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Bruce Kramer",
      "Contact Email": "finance@zionep.org"
    },
    {
      "Client PK": 16568,
      "Client ID": "17106644000",
      "Client Name": "Saint Paul's United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Shawna Schorre",
      "Contact Email": "glenda@flcomaha.org"
    },
    {
      "Client PK": 16672,
      "Client ID": "C9000551",
      "Client Name": "Grace Celebration Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joan Altman",
      "Contact Email": "julianodawn@aol.com"
    },
    {
      "Client PK": 17128,
      "Client ID": "504757097",
      "Client Name": "St. Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tammy Grissman",
      "Contact Email": "gcarlson@atonementfargo.org"
    },
    {
      "Client PK": 17131,
      "Client ID": "504743337",
      "Client Name": "First Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Martha Pierce",
      "Contact Email": "linda@poplargo.org"
    },
    {
      "Client PK": 17234,
      "Client ID": "504738881",
      "Client Name": "Immanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sue B Hill",
      "Contact Email": "membership@littlezion.org"
    },
    {
      "Client PK": 17321,
      "Client ID": "75122353364",
      "Client Name": "Beymer Memorial United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mike Carlson",
      "Contact Email": "jearl@stjohnowatonna.org"
    },
    {
      "Client PK": 17684,
      "Client ID": "504740298",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donald Carlson",
      "Contact Email": "ttrinklein@stpaullakeland.org"
    },
    {
      "Client PK": 17729,
      "Client ID": "514650501",
      "Client Name": "The Emmaus Road Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Angie Huckbody",
      "Contact Email": "office@granitefallslutheran.com"
    },
    {
      "Client PK": 18001,
      "Client ID": "ES10828",
      "Client Name": "First Friends Meeting",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Holli Kussow",
      "Contact Email": "aglc@gci.net"
    },
    {
      "Client PK": 18017,
      "Client ID": "504748931",
      "Client Name": "Gethsemane Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Scott Prunty",
      "Contact Email": "office@salemdakotacity.org"
    },
    {
      "Client PK": 18021,
      "Client ID": "C9134278",
      "Client Name": "Holy Cross Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie Schaffner",
      "Contact Email": "annstjames@rushmore.com"
    },
    {
      "Client PK": 18293,
      "Client ID": "MC11311",
      "Client Name": "Albuquerque Mennonite Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Patricia Crawford",
      "Contact Email": "financesecretary@gslutheranchurch.org"
    },
    {
      "Client PK": 19017,
      "Client ID": "S9762761",
      "Client Name": "Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Glenda Wood",
      "Contact Email": "talexander@holytrinitychapelhill.org"
    },
    {
      "Client PK": 19399,
      "Client ID": "ES11211",
      "Client Name": "Abington Monthly Meeting",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dawn Juliano",
      "Contact Email": "cll.nofx@gmail.com"
    },
    {
      "Client PK": 19815,
      "Client ID": "504740162",
      "Client Name": "Mt Olive Lutheran Congregation",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gayle Carlson",
      "Contact Email": "dkt@tregoninglaw.com"
    },
    {
      "Client PK": 19917,
      "Client ID": "504745722",
      "Client Name": "Mount Calvary Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda Greilick",
      "Contact Email": "tammykroening@gmail.com"
    },
    {
      "Client PK": 20335,
      "Client ID": "504758299",
      "Client Name": "Rock of the Foothills (ELCA)",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Haskins",
      "Contact Email": "zionmayergiving@gmail.com"
    },
    {
      "Client PK": 20389,
      "Client ID": "504731985",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joyce Earl",
      "Contact Email": "bookkeeper@stmarkswashington.org"
    },
    {
      "Client PK": 20423,
      "Client ID": "504735995",
      "Client Name": "Hope Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tracy Trinklein",
      "Contact Email": "stlukechurch.bkp@gmail.com"
    },
    {
      "Client PK": 20571,
      "Client ID": "ES11563",
      "Client Name": "Unitarian Universalist Church of Silver Sprin",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kari Bieshelt",
      "Contact Email": "diane@lcrchurch.org"
    },
    {
      "Client PK": 20577,
      "Client ID": "504752219",
      "Client Name": "Zion Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Regina Rawlings",
      "Contact Email": "lhughes@stjohncharlescity.com"
    },
    {
      "Client PK": 20883,
      "Client ID": "73133301224",
      "Client Name": "Hopewell United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Eric Westberg",
      "Contact Email": "officemanager.bethanycr@gmail.com"
    },
    {
      "Client PK": 22483,
      "Client ID": "56095815162",
      "Client Name": "Aurora United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Thomas Alexander",
      "Contact Email": "wein.patricia@gmail.com"
    },
    {
      "Client PK": 23705,
      "Client ID": "504749695",
      "Client Name": "St. Paul Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laurel Lindstrom",
      "Contact Email": "parishadministrator@verizon.net"
    },
    {
      "Client PK": 24075,
      "Client ID": "ES12788",
      "Client Name": "Chapel Lane Presbyterian Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Daniel Tregoning",
      "Contact Email": "jamifecher@gmail.com"
    },
    {
      "Client PK": 24645,
      "Client ID": "504749271",
      "Client Name": "New Salem Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tammy Kroening",
      "Contact Email": "rlj4108@comcast.net"
    },
    {
      "Client PK": 25955,
      "Client ID": "S9792149",
      "Client Name": "Immanuel Lutheran School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joan Schwartz",
      "Contact Email": "ctkadm@sbcglobal.net"
    },
    {
      "Client PK": 26041,
      "Client ID": "C9373731",
      "Client Name": "Zion Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Yvonne Shields",
      "Contact Email": "zion@zionchurch.net"
    },
    {
      "Client PK": 26059,
      "Client ID": "MI3702",
      "Client Name": "Mount Calvary Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michelle Barba",
      "Contact Email": "office@holytrinityonline.org"
    },
    {
      "Client PK": 26129,
      "Client ID": "504750159",
      "Client Name": "Richland Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane McCauley",
      "Contact Email": "churchadmin@sjrw.org"
    },
    {
      "Client PK": 26667,
      "Client ID": "C9679465",
      "Client Name": "Zion New Life Church and School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda Hughes",
      "Contact Email": "treasurer@unilu.org"
    },
    {
      "Client PK": 27103,
      "Client ID": "504747026",
      "Client Name": "Vasa Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Missy Gingrich",
      "Contact Email": "knein@graceshillington.org"
    },
    {
      "Client PK": 27143,
      "Client ID": "C7001314",
      "Client Name": "The Rock Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cari Kramer",
      "Contact Email": "connect@stmatthewsrenton.org"
    },
    {
      "Client PK": 27693,
      "Client ID": "52230742726",
      "Client Name": "First United Methodist Church of Farmersville",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Benjamin Fecher",
      "Contact Email": "marcystpaul@yahoo.com"
    },
    {
      "Client PK": 27741,
      "Client ID": "515001862",
      "Client Name": "Peace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Roger Jones",
      "Contact Email": "holycrosssaginaw@gmail.com"
    },
    {
      "Client PK": 28149,
      "Client ID": "514987719",
      "Client Name": "Christ the King Lutheran Church of Waseca",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Margaret Vogt",
      "Contact Email": "kpoerschke@carolina.rr.com"
    },
    {
      "Client PK": 29099,
      "Client ID": "52105734965",
      "Client Name": "Oakdale United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nancy Monson",
      "Contact Email": "jo@stjohns-ec.org"
    },
    {
      "Client PK": 29205,
      "Client ID": "504755848",
      "Client Name": "St. Matthew Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jamie Bisek",
      "Contact Email": "mary@reformationlasvegas.org"
    },
    {
      "Client PK": 29371,
      "Client ID": "ES14470",
      "Client Name": "Aldersgate United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Annette Jonas",
      "Contact Email": "dklabunde@gracewoodstock.org"
    },
    {
      "Client PK": 30093,
      "Client ID": "504735287",
      "Client Name": "Dinkey Memorial Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Edward Bucher",
      "Contact Email": "office@christlutheranlv.org"
    },
    {
      "Client PK": 30281,
      "Client ID": "UCC082170",
      "Client Name": "Congregational Church in South Glastonbury",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Nein",
      "Contact Email": "dschulz5@cox.net"
    },
    {
      "Client PK": 30867,
      "Client ID": "14315611751",
      "Client Name": "Alquina United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Libby Lucier",
      "Contact Email": "office@oursaviorswestsalem.org"
    },
    {
      "Client PK": 30919,
      "Client ID": "ES14991",
      "Client Name": "Open Sky Shambhala Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marcy Diaz",
      "Contact Email": "treasurer@elctempe.org"
    },
    {
      "Client PK": 31417,
      "Client ID": "504748263",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Robert Revard",
      "Contact Email": "TamaraThomasCRC@bellsouth.net"
    },
    {
      "Client PK": 31813,
      "Client ID": "514748541",
      "Client Name": "Christ the King Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Keith Poerschke",
      "Contact Email": "bruceandregina@aol.com"
    },
    {
      "Client PK": 31897,
      "Client ID": "S9728433",
      "Client Name": "Grace Lutheran School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jo Rauckman",
      "Contact Email": "treasurer@zioncrete.org"
    },
    {
      "Client PK": 32119,
      "Client ID": "504749209",
      "Client Name": "Faith Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary La Barbera",
      "Contact Email": "todd.witt@outlook.com"
    },
    {
      "Client PK": 32303,
      "Client ID": "504732105",
      "Client Name": "St Andrew Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Denise Klabunde",
      "Contact Email": "financialsecretary@messiahmauldin.org"
    },
    {
      "Client PK": 32479,
      "Client ID": "MI1908",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jean Cooksey",
      "Contact Email": "trinitychurch@trinitybaycity.org"
    },
    {
      "Client PK": 32627,
      "Client ID": "16225602585",
      "Client Name": "Plainwell First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "David Schulz",
      "Contact Email": "rdubois61@gmail.com"
    },
    {
      "Client PK": 33051,
      "Client ID": "504734447",
      "Client Name": "St James Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kris Seeger",
      "Contact Email": "gracechurch45@gmail.com"
    },
    {
      "Client PK": 33367,
      "Client ID": "C9385242",
      "Client Name": "Bethel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Darcy Meyer",
      "Contact Email": "melissa@crossofchrist.us"
    },
    {
      "Client PK": 33391,
      "Client ID": "504750009",
      "Client Name": "St Pauls Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tamara Thomas",
      "Contact Email": "office@stjohnslew.org"
    },
    {
      "Client PK": 33441,
      "Client ID": "UCC300360",
      "Client Name": "First Church Of Christ, Congregational",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nathan Pasbrig",
      "Contact Email": "busyd@comcast.net"
    },
    {
      "Client PK": 33649,
      "Client ID": "504745414",
      "Client Name": "Immanuel Lutheran Church and School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Todd Witt",
      "Contact Email": "tysondl@comcast.net"
    },
    {
      "Client PK": 33669,
      "Client ID": "MI2408",
      "Client Name": "St Johns Lutheran Church & School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Scott Ruby",
      "Contact Email": "abenter@stmatthewswinona.org"
    },
    {
      "Client PK": 33735,
      "Client ID": "504732757",
      "Client Name": "Christ Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Gina Burke",
      "Contact Email": "anj11159@yahoo.com"
    },
    {
      "Client PK": 33937,
      "Client ID": "MI1106",
      "Client Name": "Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Robert DuBois",
      "Contact Email": "samsteed@comcast.net"
    },
    {
      "Client PK": 33961,
      "Client ID": "504746502",
      "Client Name": "St Paul's Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Beth Smith",
      "Contact Email": "office@firstamericanlutheran.org"
    },
    {
      "Client PK": 34581,
      "Client ID": "504735329",
      "Client Name": "Zion United Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa DeRosier",
      "Contact Email": "stmartins@stmartinschurch.com"
    },
    {
      "Client PK": 35073,
      "Client ID": "504754692",
      "Client Name": "Grace Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rebeckah Ellinghuysen",
      "Contact Email": "russell.wiggs.b4mc@statefarm.com"
    },
    {
      "Client PK": 35195,
      "Client ID": "ES16332",
      "Client Name": "Holy Spirit Synodal Catholic Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diana Satrum",
      "Contact Email": "stjohn@hickorytech.net"
    },
    {
      "Client PK": 35511,
      "Client ID": "ES16410",
      "Client Name": "Boulder Valley UU Fellowship",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dale Tyson",
      "Contact Email": "finance.office@mountoliveappleton.com"
    },
    {
      "Client PK": 35789,
      "Client ID": "14385626650",
      "Client Name": "Brazil Union United Methodist Church, Inc.",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Amanda Benter",
      "Contact Email": "office@greenfieldlutheran.com"
    },
    {
      "Client PK": 35884,
      "Client ID": "504750092",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Anjalee Wagers",
      "Contact Email": "bookkeeper@christthelordbrookfield.org"
    },
    {
      "Client PK": 36179,
      "Client ID": "ES16633",
      "Client Name": "Hosanna Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sam Steed",
      "Contact Email": "valerie@peacenb.org"
    },
    {
      "Client PK": 36191,
      "Client ID": "20055694460",
      "Client Name": "Centenary United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Bobby Jo Lipp",
      "Contact Email": "ashleywill@stpetersfdl.net"
    },
    {
      "Client PK": 36595,
      "Client ID": "16120586028",
      "Client Name": "Marquette Hope",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rachel Wendler",
      "Contact Email": "financialsecretary@joy-church.org"
    },
    {
      "Client PK": 36603,
      "Client ID": "14350634364",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Russell Wiggs",
      "Contact Email": "leeann@calvaryrapidcity.org"
    },
    {
      "Client PK": 36701,
      "Client ID": "504748845",
      "Client Name": "St. Luke's Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie Keane",
      "Contact Email": "petersonaccounting@gmail.com"
    },
    {
      "Client PK": 36893,
      "Client ID": "504735303",
      "Client Name": "St Matthews Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Shannon Weyenberg",
      "Contact Email": "mountcalvaryoffice@new.rr.com"
    },
    {
      "Client PK": 36921,
      "Client ID": "16225602027",
      "Client Name": "Hartford United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sandy Strozyk",
      "Contact Email": "marylou@lord-of-love.org"
    },
    {
      "Client PK": 36927,
      "Client ID": "56070800640",
      "Client Name": "Palmyra United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Arianne Kannenberg",
      "Contact Email": "sliermann.fg@gmail.com"
    },
    {
      "Client PK": 36971,
      "Client ID": "15005559641",
      "Client Name": "First UMC Marengo",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Valerie Wood",
      "Contact Email": "maggie@livinglordfl.org"
    },
    {
      "Client PK": 37029,
      "Client ID": "C9745095",
      "Client Name": "Holy Cross Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ashley Will",
      "Contact Email": "church@redemptionwels.com"
    },
    {
      "Client PK": 37131,
      "Client ID": "ES16961",
      "Client Name": "Jamesville Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Flatz",
      "Contact Email": "trinity@trinityminocqua.org"
    },
    {
      "Client PK": 38053,
      "Client ID": "C7000782",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lee Ann Henry",
      "Contact Email": "deanna2@goodsheperdreno.org"
    },
    {
      "Client PK": 38177,
      "Client ID": "504759184",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kelly Peterson",
      "Contact Email": "church@zionrhinelander.org"
    },
    {
      "Client PK": 38535,
      "Client ID": "504754299",
      "Client Name": "St Pauls Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sarah De Valk",
      "Contact Email": "office@faithsussex.org"
    },
    {
      "Client PK": 38994,
      "Client ID": "16230585228",
      "Client Name": "Williamston United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Lou Gustafson",
      "Contact Email": "kwarfield@saintluke.us"
    },
    {
      "Client PK": 39047,
      "Client ID": "504746502-LS",
      "Client Name": "St. Paul's Lutheran School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sheila Liermann",
      "Contact Email": "clbtrinity@ptd.net"
    },
    {
      "Client PK": 39051,
      "Client ID": "UCC602030",
      "Client Name": "Zion Blue Mountain UCC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Martha Mork",
      "Contact Email": "jordanluth@att.net"
    },
    {
      "Client PK": 39257,
      "Client ID": "94005935327",
      "Client Name": "Elmhurst United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jamie Baade",
      "Contact Email": "blcelca@gmail.com"
    },
    {
      "Client PK": 40037,
      "Client ID": "ES17965",
      "Client Name": "Turning Point Christian Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pamela Richardson",
      "Contact Email": "stlukewttn@tslwels.org"
    },
    {
      "Client PK": 40539,
      "Client ID": "UCC601560",
      "Client Name": "First United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Deanna Gaunt",
      "Contact Email": "wpeterson@stbarnabaslutheran.org"
    },
    {
      "Client PK": 40645,
      "Client ID": "504739602",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Puestow",
      "Contact Email": "dmaxwell@orovalley.org"
    },
    {
      "Client PK": 40735,
      "Client ID": "ES18216",
      "Client Name": "Connexion Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laurie Wolters",
      "Contact Email": "estampfl@stlukechicago.org"
    },
    {
      "Client PK": 43943,
      "Client ID": "502742454",
      "Client Name": "St Mark Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cynthia Bakos",
      "Contact Email": "finance.tlc@iw.net"
    },
    {
      "Client PK": 44277,
      "Client ID": "12336545314",
      "Client Name": "Tabor United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jill M Parbs",
      "Contact Email": "bookkeeperalc900@gmail.com"
    },
    {
      "Client PK": 44945,
      "Client ID": "19472666985",
      "Client Name": "Clifton United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Schadt",
      "Contact Email": "lori.enter@trinitynicollet.org"
    },
    {
      "Client PK": 45047,
      "Client ID": "16230596107",
      "Client Name": "Asbury United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Wayne Peterson",
      "Contact Email": "j3jerryness@gmail.com"
    },
    {
      "Client PK": 45231,
      "Client ID": "C9400972-13",
      "Client Name": "Zion Lutheran Church & School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Maxwell",
      "Contact Email": "businessmanager@tlcgi.org"
    },
    {
      "Client PK": 45483,
      "Client ID": "C7000965",
      "Client Name": "Alive Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Elisabeth Stampfl",
      "Contact Email": "billing@bethlehem-raymore.org"
    },
    {
      "Client PK": 46079,
      "Client ID": "504743881-SC",
      "Client Name": "Star of Bethlehem School",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lilah Gillis",
      "Contact Email": "bookkeeper@zionfm.org"
    },
    {
      "Client PK": 46261,
      "Client ID": "ES19963",
      "Client Name": "St. Luke's Episcopal Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Elizabeth Peroni",
      "Contact Email": "amystrop@yahoo.com"
    },
    {
      "Client PK": 46299,
      "Client ID": "ES19898",
      "Client Name": "Sharon Moravian Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lori Enter",
      "Contact Email": "jessie.duehring@alcfdl.org"
    },
    {
      "Client PK": 46577,
      "Client ID": "UCC20052",
      "Client Name": "Faith Family United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jerry Ness",
      "Contact Email": "michelle.warner@trinitydavenport.org"
    },
    {
      "Client PK": 46843,
      "Client ID": "19472668084",
      "Client Name": "Oak Hills United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ruth Koch",
      "Contact Email": "office@ulclv.org"
    },
    {
      "Client PK": 47999,
      "Client ID": "C9154512",
      "Client Name": "Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Erika Atcheson",
      "Contact Email": "bookkeeper@livingwaterslutheran.org"
    },
    {
      "Client PK": 48553,
      "Client ID": "C9043544",
      "Client Name": "Our Redeemer Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Chase",
      "Contact Email": "RLCContributions@yahoo.com"
    },
    {
      "Client PK": 49185,
      "Client ID": "ES20718",
      "Client Name": "Augusta Assembly of God Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Amy Birchard",
      "Contact Email": "kjohnson.flc@gmail.com"
    },
    {
      "Client PK": 49581,
      "Client ID": "C9716931",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jessica Duehring",
      "Contact Email": "dmiller@lakeshorefellowship.com"
    },
    {
      "Client PK": 49675,
      "Client ID": "ES18367-DC",
      "Client Name": "Destiny Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michelle Warner",
      "Contact Email": "msearcy@hclc.info"
    },
    {
      "Client PK": 49816,
      "Client ID": "91222853864",
      "Client Name": "First United Methodist Church of Limon",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cathy Shaffer",
      "Contact Email": "esperanza@myesperanza.org"
    },
    {
      "Client PK": 50001,
      "Client ID": "UCC581035",
      "Client Name": "Dille Parish United Church of Christ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "REBECCA JUNI",
      "Contact Email": "rlc@redeemeralden.com"
    },
    {
      "Client PK": 50103,
      "Client ID": "504759112-CH",
      "Client Name": "Shepherd of the Valley Lutheran Church & Pres",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa Arp",
      "Contact Email": "a.lorig@dedert.com"
    },
    {
      "Client PK": 51441,
      "Client ID": "20080699781",
      "Client Name": "Waukau United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Krista Johnson",
      "Contact Email": "kjswanson92@yahoo.com"
    },
    {
      "Client PK": 54013,
      "Client ID": "UCC81350",
      "Client Name": "South Congregational Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Debra Miller",
      "Contact Email": "church@goodshepherds.net"
    },
    {
      "Client PK": 55263,
      "Client ID": "C9490098",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michelle Searcy",
      "Contact Email": "masloskifamily@gmail.com"
    },
    {
      "Client PK": 56953,
      "Client ID": "56090802080",
      "Client Name": "Fairfax United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joni Thorpe",
      "Contact Email": "huskerjoyce@gmail.com"
    },
    {
      "Client PK": 62025,
      "Client ID": "54120787028",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Beth Wilder",
      "Contact Email": "frank.brown915@gmail.com"
    },
    {
      "Client PK": 62327,
      "Client ID": "80355479093",
      "Client Name": "Riverview United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Alan Lorig",
      "Contact Email": "professional.qualified@gmail.com"
    },
    {
      "Client PK": 63193,
      "Client ID": "C9663735",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kirk Swanson",
      "Contact Email": "lwilley@stmarkcares.org"
    },
    {
      "Client PK": 63257,
      "Client ID": "502743120",
      "Client Name": "Oak Grove Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda Veldman",
      "Contact Email": "att41623@sbcglobal.net"
    },
    {
      "Client PK": 63659,
      "Client ID": "504749575",
      "Client Name": "Zion American Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Matt Masloski",
      "Contact Email": "ascen@smig.net"
    },
    {
      "Client PK": 64371,
      "Client ID": "ES24539",
      "Client Name": "Waterville Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joyce Benedict",
      "Contact Email": "oslcvalleycity@msn.com"
    },
    {
      "Client PK": 64959,
      "Client ID": "ES24661",
      "Client Name": "Church of the Good Shepherd",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Frank Brown",
      "Contact Email": "lcotmtreasurer@gmail.com"
    },
    {
      "Client PK": 66289,
      "Client ID": "91010864344",
      "Client Name": "United Methodist Church Lewistown MT Inc",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jody Rinehart",
      "Contact Email": "al@stmattslutheran.org"
    },
    {
      "Client PK": 66521,
      "Client ID": "ES25024",
      "Client Name": "Adventure-Life Reformed Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cindi  Rachowicz",
      "Contact Email": "jluedke@columbus.rr.com"
    },
    {
      "Client PK": 67837,
      "Client ID": "504748119",
      "Client Name": "Our Savior Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Georgia Wentzel",
      "Contact Email": "dsommer@goodshep.com"
    },
    {
      "Client PK": 68775,
      "Client ID": "C9942660",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kim Calabrese",
      "Contact Email": "abra@ststephenswsp.org"
    },
    {
      "Client PK": 71981,
      "Client ID": "ES26021",
      "Client Name": "Mount Olive African Methodist Episcopal Churc",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Al Van Lith",
      "Contact Email": "sandy.hauschildt@zionmarengo.net"
    },
    {
      "Client PK": 72361,
      "Client ID": "ES26074",
      "Client Name": "Highest Praise Fellowship",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jeffrey L Luedke",
      "Contact Email": "paula@ascensiongb.com"
    },
    {
      "Client PK": 73664,
      "Client ID": "95115921844-TXT",
      "Client Name": "Fairwood Community United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dana Sommer",
      "Contact Email": "tina@rlc-scottsdale.org"
    },
    {
      "Client PK": 74298,
      "Client ID": "C9477406",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sandy Hauschildt",
      "Contact Email": "office@beautifulsavior.net"
    },
    {
      "Client PK": 74780,
      "Client ID": "504750275",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Paula Wozniak",
      "Contact Email": "martibrockmeier@firstlutherankearney.org"
    },
    {
      "Client PK": 75459,
      "Client ID": "19415668701-TXT",
      "Client Name": "Maple Grove United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tina Souza",
      "Contact Email": "info@stpaul-lutheran.com"
    },
    {
      "Client PK": 77175,
      "Client ID": "ES26823",
      "Client Name": "World Mission Maranatha",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jenni Pavesic",
      "Contact Email": "treasurer@christlc.org"
    },
    {
      "Client PK": 77391,
      "Client ID": "92720881543-TXT",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marti Brockmeier",
      "Contact Email": "treasurerclcrsm@gmail.com"
    },
    {
      "Client PK": 77493,
      "Client ID": "504749593",
      "Client Name": "Dalesburg Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sheldon Erickson",
      "Contact Email": "zionfairwater@hotmail.com"
    },
    {
      "Client PK": 77870,
      "Client ID": "80388477015-TXT",
      "Client Name": "St Lukes United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brian Sharkey",
      "Contact Email": "beth@nativitychurch.org"
    },
    {
      "Client PK": 78651,
      "Client ID": "76044318292-TXT",
      "Client Name": "Parkway Hills United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tracy Cantin",
      "Contact Email": "klenox@earthlink.net"
    },
    {
      "Client PK": 79609,
      "Client ID": "71245227447-TXT",
      "Client Name": "Kingsland First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Schwandt",
      "Contact Email": "BarbH@tlcowatonna.org"
    },
    {
      "Client PK": 80323,
      "Client ID": "15054567708-TXT",
      "Client Name": "Asbury United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Beth Schuman",
      "Contact Email": "cosrock_staffminister@hotmail.com"
    },
    {
      "Client PK": 80337,
      "Client ID": "C9364990",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kate Lenox",
      "Contact Email": "barb@rclcqc.org"
    },
    {
      "Client PK": 80981,
      "Client ID": "ES204549",
      "Client Name": "Emmanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barb Howell",
      "Contact Email": "dmjknight@yahoo.com"
    },
    {
      "Client PK": 82797,
      "Client ID": "ES234220",
      "Client Name": "Willing Heart Cathedral",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Thomas Marten",
      "Contact Email": "jim@gsolympia.org"
    },
    {
      "Client PK": 82865,
      "Client ID": "72252255467",
      "Client Name": "Elkmont United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barbara Grosskopf",
      "Contact Email": "finance.oslc.denver@gmail.com"
    },
    {
      "Client PK": 84540,
      "Client ID": "38130970935-TXT",
      "Client Name": "Grace United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Denise Knight-Drury",
      "Contact Email": "cherylhlippert@gmail.com"
    },
    {
      "Client PK": 85168,
      "Client ID": "76032333140",
      "Client Name": "Hernando United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Howeth",
      "Contact Email": "Billing@stpaulfd.org"
    },
    {
      "Client PK": 85236,
      "Client ID": "36045096187",
      "Client Name": "Ingomar Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Margaret Catchpole",
      "Contact Email": "bhottois@crownoflife.org"
    },
    {
      "Client PK": 85892,
      "Client ID": "75143356094-FL2",
      "Client Name": "Aldersgate Christian Learning Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cheryl Lippert",
      "Contact Email": "dana.vandeveer@gmail.com"
    },
    {
      "Client PK": 86626,
      "Client ID": "ES310387",
      "Client Name": "St Francis Episcopal Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michael LuAnn Bacon",
      "Contact Email": "laura@crossofglory.com"
    },
    {
      "Client PK": 87276,
      "Client ID": "15019550594-TXT",
      "Client Name": "Salem United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Beverly Hottois",
      "Contact Email": "ccampbell@ascensionlcms.org"
    },
    {
      "Client PK": 89012,
      "Client ID": "38118169683-TXT",
      "Client Name": "Epworth United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Carla Campbell",
      "Contact Email": "swyatt@zionsa.org"
    },
    {
      "Client PK": 89146,
      "Client ID": "ES348846",
      "Client Name": "Puente de Vida Bridge of Life",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Alan Stache",
      "Contact Email": "vaflemming@hotmail.com"
    },
    {
      "Client PK": 89252,
      "Client ID": "ES350739",
      "Client Name": "Restoration Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joy Belew",
      "Contact Email": "fatman75@jtt.net"
    },
    {
      "Client PK": 90146,
      "Client ID": "77011384593-TXT",
      "Client Name": "Saint John United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Suzie Wyatt",
      "Contact Email": "luthwash@hotmail.com"
    },
    {
      "Client PK": 90278,
      "Client ID": "ES376603",
      "Client Name": "Faith Journey Church of the Nazarene",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Virginia Flemming",
      "Contact Email": "markr@gardinercpa.com"
    },
    {
      "Client PK": 90588,
      "Client ID": "ES382929",
      "Client Name": "Bethel Missionary Baptist Church of Markham",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michael Waring",
      "Contact Email": "connect@trinityjamestown.com"
    },
    {
      "Client PK": 90722,
      "Client ID": "13320501652-TXT",
      "Client Name": "Emmanuel United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Linda Finke",
      "Contact Email": "dean.sponheim@plantpioneer.com"
    },
    {
      "Client PK": 91738,
      "Client ID": "92215888305-TXT",
      "Client Name": "Lancaster United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mark Rodruck",
      "Contact Email": "office@uscfaith.org"
    },
    {
      "Client PK": 91966,
      "Client ID": "ES418130",
      "Client Name": "Rivers of Living Water Ministry Inc",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kristina Weber",
      "Contact Email": "simplygiving@oslclaurel.org"
    },
    {
      "Client PK": 92224,
      "Client ID": "ES368521-TXT",
      "Client Name": "Ashland United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dean Sponheim",
      "Contact Email": "bwinter@fsgleasing.com"
    },
    {
      "Client PK": 92258,
      "Client ID": "52132732580",
      "Client Name": "St Luke United Methodist Church - Corsicana",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Michelle Ritchie",
      "Contact Email": "terri.reuter@locdelano.com"
    },
    {
      "Client PK": 92446,
      "Client ID": "ES428531",
      "Client Name": "Calvary United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kirk Rittenhouse",
      "Contact Email": "weskelton@yahoo.com"
    },
    {
      "Client PK": 92716,
      "Client ID": "ES433826",
      "Client Name": "Greater Grace Temple Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Bruce Winter",
      "Contact Email": "secretary@messiahkenosha.org"
    },
    {
      "Client PK": 93312,
      "Client ID": "95125923865-TXT",
      "Client Name": "First United Methodist Church of Auburn",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Terri Reuter",
      "Contact Email": "treasurer@ascensionofchrist.org"
    },
    {
      "Client PK": 93374,
      "Client ID": "ES448746",
      "Client Name": "New Beginnings Church of God of Prophecy",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lesa Roemke",
      "Contact Email": "hslclisa@sbcglobal.net"
    },
    {
      "Client PK": 93432,
      "Client ID": "35075065483-TXT",
      "Client Name": "Bethlehem United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Charles Jackson",
      "Contact Email": "tfrusti@trinitydavison.org"
    },
    {
      "Client PK": 93964,
      "Client ID": "ES458728",
      "Client Name": "Mount Sinai United Holy Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lisa Gabe",
      "Contact Email": "businessmanager@guardianlutheran.org"
    },
    {
      "Client PK": 93980,
      "Client ID": "55002711350-TXT",
      "Client Name": "Church of the Covenant UMC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Todd Frusti",
      "Contact Email": "info@immanuelmarshfield.org"
    },
    {
      "Client PK": 94150,
      "Client ID": "ES462808",
      "Client Name": "Saint John Church - Baptist",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathleen Sillanpaa",
      "Contact Email": "pschneider@stjohnmerrill.org"
    },
    {
      "Client PK": 94986,
      "Client ID": "38126970616-TXT",
      "Client Name": "Randall Memorial United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christine GARBISCH",
      "Contact Email": "kim.blount@immanuelgr.org"
    },
    {
      "Client PK": 95072,
      "Client ID": "ES481216",
      "Client Name": "Greater Works Christian Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Polly Schneider",
      "Contact Email": "sheryllpeterson@yahoo.com"
    },
    {
      "Client PK": 95078,
      "Client ID": "ES486383",
      "Client Name": "Perfecting Worship Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kimberly Blount",
      "Contact Email": "dana@commhope.org"
    },
    {
      "Client PK": 95758,
      "Client ID": "ES505634",
      "Client Name": "The Community's Christian Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sheryl Peterson",
      "Contact Email": "chris@splco.org"
    },
    {
      "Client PK": 95856,
      "Client ID": "52260738267-TXT",
      "Client Name": "St Paul UMC-Dallas",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dana Eaton",
      "Contact Email": "kpetzold@holycrossjenison.org"
    },
    {
      "Client PK": 95900,
      "Client ID": "ES519803",
      "Client Name": "First Pentecostal Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christine Hesse",
      "Contact Email": "dmoody@smls.net"
    },
    {
      "Client PK": 96072,
      "Client ID": "33636054480-TXT",
      "Client Name": "St Pauls United Methodist Church of Brick NJ",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Petzold",
      "Contact Email": "Mikew4519@gmail.com"
    },
    {
      "Client PK": 96256,
      "Client ID": "ES536395-TXT",
      "Client Name": "Radcliff United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Moody",
      "Contact Email": "cindynellessen@saintpetersls.com"
    },
    {
      "Client PK": 96798,
      "Client ID": "ES550931",
      "Client Name": "Collins Grove United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Cynthia Nellessen",
      "Contact Email": "PamBandy@edinarealty.com"
    },
    {
      "Client PK": 97000,
      "Client ID": "C9786715",
      "Client Name": "St. John's Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Donna Pfiester",
      "Contact Email": "secretary@stpeterswaterford.com"
    },
    {
      "Client PK": 97354,
      "Client ID": "ES567773",
      "Client Name": "Southern Hills United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pam Bandy",
      "Contact Email": "geigerp@ctkl.org"
    },
    {
      "Client PK": 97516,
      "Client ID": "ES563891",
      "Client Name": "Amazing Grace Worship Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathy Anderson",
      "Contact Email": "rmlcwausau@gmail.com"
    },
    {
      "Client PK": 97990,
      "Client ID": "ES581881",
      "Client Name": "Gods House of Brotherly Love",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Pamela Geiger",
      "Contact Email": "zeke3613@aol.com"
    },
    {
      "Client PK": 98184,
      "Client ID": "ES586559",
      "Client Name": "Connections at Metropolitan UMC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sue Bahlow",
      "Contact Email": "david@dlgoodrich.com"
    },
    {
      "Client PK": 99063,
      "Client ID": "ES612643",
      "Client Name": "Ebenezer AME Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Peters",
      "Contact Email": "admin@zionfairbanks.org"
    },
    {
      "Client PK": 99224,
      "Client ID": "ES614626",
      "Client Name": "Living Way Church Maui",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "David Goodrich",
      "Contact Email": "msguimond@yahoo.com"
    },
    {
      "Client PK": 99480,
      "Client ID": "ES621422",
      "Client Name": "Tapp United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Susan Matchael",
      "Contact Email": "lpodoll@msn.com"
    },
    {
      "Client PK": 100049,
      "Client ID": "ES636670",
      "Client Name": "Celebrate New Life Tabernacle",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mary Guimond",
      "Contact Email": "stpauloffice@bevcomm.net"
    },
    {
      "Client PK": 100934,
      "Client ID": "ES660585",
      "Client Name": "Word of Hope World Outreach Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Loren Podoll",
      "Contact Email": "zonkfan@msn.com"
    },
    {
      "Client PK": 101137,
      "Client ID": "ES662786",
      "Client Name": "Kingdom in the Valley Christian Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kimberly Mastin",
      "Contact Email": "craig.hashbarger@gmail.com"
    },
    {
      "Client PK": 101273,
      "Client ID": "ES670357",
      "Client Name": "Oak Grove Harvest Assembly of God",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "David Pergande",
      "Contact Email": "julie.allen@stmatthew-ch.org"
    },
    {
      "Client PK": 101783,
      "Client ID": "ES682338",
      "Client Name": "Voice of Praise Worldwide Ministries",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Craig Hashbarger",
      "Contact Email": "john@bslcks.org"
    },
    {
      "Client PK": 102274,
      "Client ID": "ES696403",
      "Client Name": "St John's Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Julie Allen",
      "Contact Email": "llmckown@cox.net"
    },
    {
      "Client PK": 102636,
      "Client ID": "ES709855",
      "Client Name": "From The Heart Church Ministries of Philly",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Blakley",
      "Contact Email": "nleverone@cross-of-christ.org"
    },
    {
      "Client PK": 103182,
      "Client ID": "ES729525",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Nikki Leverone",
      "Contact Email": "peacelutheranstjoe@sbcglobal.net"
    },
    {
      "Client PK": 103942,
      "Client ID": "ES760050",
      "Client Name": "Citadel of Truth Faith Ministries",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kristi Westergaard",
      "Contact Email": "juliemaesweet@yahoo.com"
    },
    {
      "Client PK": 104892,
      "Client ID": "PDS305613",
      "Client Name": "St Gabriel Church Congregation",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lois Weiland",
      "Contact Email": "mary@faithloves.org"
    },
    {
      "Client PK": 104971,
      "Client ID": "ES801209",
      "Client Name": "Mayflower Congregational Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brandi Johnson",
      "Contact Email": "dramirez@newulmtel.net"
    },
    {
      "Client PK": 105732,
      "Client ID": "504738432-CDC",
      "Client Name": "Christ The King Child Development Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Deborah Ramirez",
      "Contact Email": "bpjpayson@gmail.com"
    },
    {
      "Client PK": 106689,
      "Client ID": "ES799000",
      "Client Name": "Christ Abundant Life International Ministries",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brian Jones",
      "Contact Email": "ftkull@comcast.net"
    },
    {
      "Client PK": 106994,
      "Client ID": "ES799217",
      "Client Name": "McMillan United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathleen Oelfke",
      "Contact Email": "IECOORDJORDAN@PTD.NET"
    },
    {
      "Client PK": 107616,
      "Client ID": "ES799583",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tom Kull",
      "Contact Email": "sharic@allsaintsphoenix.org"
    },
    {
      "Client PK": 107776,
      "Client ID": "ES799682",
      "Client Name": "West Heights UMC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Shari Clark",
      "Contact Email": "sue@oslcmankato.org"
    },
    {
      "Client PK": 108813,
      "Client ID": "ES800193",
      "Client Name": "San Bernardino Community Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Holmlund",
      "Contact Email": "office@atpeace.org"
    },
    {
      "Client PK": 109554,
      "Client ID": "ES800518",
      "Client Name": "New Life Evangelistic Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jeff Niederloh",
      "Contact Email": "financialsecretary@saintjohnsag.com"
    },
    {
      "Client PK": 109672,
      "Client ID": "ES800553",
      "Client Name": "Harvest Tabernacle Ministries",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kerry Wood",
      "Contact Email": "office@redeemer-rochester.com"
    },
    {
      "Client PK": 109818,
      "Client ID": "ES800611",
      "Client Name": "Kennedy Jordon Manor Inc",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christina Tjosaas",
      "Contact Email": "bicatty@aol.com"
    },
    {
      "Client PK": 110668,
      "Client ID": "ES800960",
      "Client Name": "Pentecostal Christian Missionary Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barbara Pentico",
      "Contact Email": "donmcallister5103@comcast.net"
    },
    {
      "Client PK": 110962,
      "Client ID": "ES801066",
      "Client Name": "I Rise Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Don McAllister",
      "Contact Email": "pastorbhartwig@gmail.com"
    },
    {
      "Client PK": 111364,
      "Client ID": "C9476977",
      "Client Name": "First Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Brett Hartwig",
      "Contact Email": "stofficeasst@sbcglobal.net"
    },
    {
      "Client PK": 111984,
      "Client ID": "ES801524",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Doretta Rapp",
      "Contact Email": "laura.muelling@gls-hsv.org"
    },
    {
      "Client PK": 111988,
      "Client ID": "ES801527",
      "Client Name": "Shepherd of the Valley Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Laura Muelling",
      "Contact Email": "church@stmartinswinona.org"
    },
    {
      "Client PK": 112016,
      "Client ID": "ES801535",
      "Client Name": "True Unity Baptist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa Denzer",
      "Contact Email": "administrator@firstlutheransd.org"
    },
    {
      "Client PK": 112115,
      "Client ID": "ES801576",
      "Client Name": "Faith Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Hannah DeMers",
      "Contact Email": "anne@propeace.org"
    },
    {
      "Client PK": 113023,
      "Client ID": "ES801961",
      "Client Name": "Community United Methodist Church of Julian",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Anne Hess",
      "Contact Email": "kate@allsaintsnet.org"
    },
    {
      "Client PK": 113203,
      "Client ID": "ES802059",
      "Client Name": "Peoples Community Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kate Heukles",
      "Contact Email": "ronaldbarrett3820@gmail.com"
    },
    {
      "Client PK": 113467,
      "Client ID": "ES802167",
      "Client Name": "Graceland Church Inc",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Ron Barrett",
      "Contact Email": "summertime_99@hotmail.com"
    },
    {
      "Client PK": 113973,
      "Client ID": "ES802406",
      "Client Name": "Winner United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jason Sommerer",
      "Contact Email": "karengalas@hotmail.com"
    },
    {
      "Client PK": 114057,
      "Client ID": "ES802416",
      "Client Name": "New Life Mission Church of Northern Cal",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Karen Galas",
      "Contact Email": "jimehlers8000@gmail.com"
    },
    {
      "Client PK": 114395,
      "Client ID": "ES802552",
      "Client Name": "Dell Grove Emmanuel Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jim Ehlers",
      "Contact Email": "office@stmatthewavon.org"
    },
    {
      "Client PK": 114667,
      "Client ID": "ES802655",
      "Client Name": "Apple Valley Worship Center",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Diane Oates",
      "Contact Email": "jacque@mcelroyfamily.com"
    },
    {
      "Client PK": 115445,
      "Client ID": "ES802949",
      "Client Name": "First United Methodist Church of Saranac Lake",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jacque McElroy",
      "Contact Email": "bellis@faithfairoaks.com"
    },
    {
      "Client PK": 115745,
      "Client ID": "ES803083",
      "Client Name": "St Sophia/Ss Faith Hope Agape Greek Orthodox",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Barbara Ellis",
      "Contact Email": "Thewisdoms@optonline.net"
    },
    {
      "Client PK": 115837,
      "Client ID": "ES802733",
      "Client Name": "Cruz de Vida AG",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Doni Wisdom",
      "Contact Email": "office@orlutheran.org"
    },
    {
      "Client PK": 116036,
      "Client ID": "ES803632",
      "Client Name": "Mulberry United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Phyllis Thornton",
      "Contact Email": "jmarek@immanuelolivette.org"
    },
    {
      "Client PK": 116176,
      "Client ID": "ES803233",
      "Client Name": "Fowler United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Joan Marek",
      "Contact Email": "emh200@swbell.net"
    },
    {
      "Client PK": 116268,
      "Client ID": "ES803257",
      "Client Name": "Saint Paul United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Elaine Hilgeman",
      "Contact Email": "maggiek@gracelutherantampa.org"
    },
    {
      "Client PK": 116756,
      "Client ID": "ES803452",
      "Client Name": "Grace United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Maggie Gould",
      "Contact Email": "jdehart@soth.org"
    },
    {
      "Client PK": 117460,
      "Client ID": "ES803559",
      "Client Name": "New Visions WLC Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Amy Glause",
      "Contact Email": "vsufka@corlutheran.org"
    },
    {
      "Client PK": 117810,
      "Client ID": "ES803918",
      "Client Name": "New Virginia United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Victoria Sufka",
      "Contact Email": "pwcoffice@pwclc.org"
    },
    {
      "Client PK": 117908,
      "Client ID": "ES803516",
      "Client Name": "Cornerstone Wesleyan Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Toni McPeek",
      "Contact Email": "jean.kurtz@cune.org"
    },
    {
      "Client PK": 118302,
      "Client ID": "ES804146",
      "Client Name": "Trinity United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Max Williams",
      "Contact Email": "hopelutheranhudson@gmail.com"
    },
    {
      "Client PK": 118974,
      "Client ID": "ES804404",
      "Client Name": "First United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Melissa Miller Furgeson",
      "Contact Email": "adalexander01@gmail.com"
    },
    {
      "Client PK": 119578,
      "Client ID": "ES804753",
      "Client Name": "Tree of Life Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Alan Alexander",
      "Contact Email": "church@johnhv.me"
    },
    {
      "Client PK": 119716,
      "Client ID": "C9690047",
      "Client Name": "St Paul Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "John Vercelletto",
      "Contact Email": "FinSec@firstdekalb.org"
    },
    {
      "Client PK": 119730,
      "Client ID": "C9406192",
      "Client Name": "St Matthew Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Teresa Lange",
      "Contact Email": "lmobley@immanuelcl.org"
    },
    {
      "Client PK": 119940,
      "Client ID": "ES804873",
      "Client Name": "Iglesia Despertar",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Lynette Mobley",
      "Contact Email": "bookkeeper@rlcfp.org"
    },
    {
      "Client PK": 119962,
      "Client ID": "ES804895",
      "Client Name": "DeWitt United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Judi Ferrari",
      "Contact Email": "secretary@sjlcdepew.com"
    },
    {
      "Client PK": 120276,
      "Client ID": "ES805060",
      "Client Name": "St Johns Evangelical Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mike Brown",
      "Contact Email": "dorothy.oppelt@stpaulmonroe.org"
    },
    {
      "Client PK": 120418,
      "Client ID": "ES805136",
      "Client Name": "StJohns Episcopal Church Maple Shade",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Della Field",
      "Contact Email": "chadfca@aol.com"
    },
    {
      "Client PK": 120622,
      "Client ID": "ES805239",
      "Client Name": "CJC East Orange",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Chad Cornell",
      "Contact Email": "secretary@kirklandflc.org"
    },
    {
      "Client PK": 120702,
      "Client ID": "ES805262",
      "Client Name": "Nehalem Bay United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Dianna Wittwer",
      "Contact Email": "FinSec@holycrosschurch.org"
    },
    {
      "Client PK": 120748,
      "Client ID": "ES805167",
      "Client Name": "Mercy Temple",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tom Miller",
      "Contact Email": "treasurer@trinityhuntley.org"
    },
    {
      "Client PK": 121450,
      "Client ID": "C9311598",
      "Client Name": "Shepherd Of The Hills Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Marietta Smith",
      "Contact Email": "office@standrewlutheran.net"
    },
    {
      "Client PK": 121510,
      "Client ID": "ES805638",
      "Client Name": "Gloria Dei Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Mayra Vazquez",
      "Contact Email": "steve.snow@messiah-wauconda.org"
    },
    {
      "Client PK": 122288,
      "Client ID": "C9084871",
      "Client Name": "Luther Memorial Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Holly Foster",
      "Contact Email": "faith@faithluth.com"
    },
    {
      "Client PK": 122328,
      "Client ID": "C9900257",
      "Client Name": "St John Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Helen Kiepke",
      "Contact Email": "christie.hoaglin@messiahelca.com"
    },
    {
      "Client PK": 122402,
      "Client ID": "ES806040",
      "Client Name": "Lebanon Lutheran",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christie Hoaglin",
      "Contact Email": "jofft@kingofkingscr.org"
    },
    {
      "Client PK": 122548,
      "Client ID": "ES806110",
      "Client Name": "Scottsdale Center for Spiritual Living",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jonathan Offt",
      "Contact Email": "stephanie.ryman@zionrc.org"
    },
    {
      "Client PK": 122694,
      "Client ID": "ES806161",
      "Client Name": "Apostolic Mission Church (Cooljc)",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Stephanie Ryman",
      "Contact Email": "kgood@gracedewitt.org"
    },
    {
      "Client PK": 122916,
      "Client ID": "ES806221",
      "Client Name": "Appointed Church Las Vegas",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Kathleen  Holdorf",
      "Contact Email": "tim.mccartan@rocketmail.com"
    },
    {
      "Client PK": 123129,
      "Client ID": "ES806354",
      "Client Name": "CINCINNATI BIBLE WAY CHURCH, INC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Rob Gregory",
      "Contact Email": "evelynma25@aol.com"
    },
    {
      "Client PK": 123936,
      "Client ID": "ES806565",
      "Client Name": "Glory Realm International",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Evelyn Buchhorn",
      "Contact Email": "treasurer@gracewelcomesyou.org"
    },
    {
      "Client PK": 123968,
      "Client ID": "ES806558",
      "Client Name": "Gods Holy Fire International Ministry",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Claudia Wood",
      "Contact Email": "giving@stpaulaustin.org"
    },
    {
      "Client PK": 124913,
      "Client ID": "C9282497",
      "Client Name": "Grace Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Jan Mitchell",
      "Contact Email": "gracefd@frontiernet.net"
    },
    {
      "Client PK": 124929,
      "Client ID": "ES807312",
      "Client Name": "Dominguez United Methodist Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Tammy Mickelson",
      "Contact Email": "ruthann@stjohns-salisbury.org"
    },
    {
      "Client PK": 125701,
      "Client ID": "C9385600",
      "Client Name": "Zion Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Colleen Holle",
      "Contact Email": "kimco785@yahoo.com"
    },
    {
      "Client PK": 125843,
      "Client ID": "C9123767",
      "Client Name": "St Johns Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Larry Conrad",
      "Contact Email": "larry@tlcms.org"
    },
    {
      "Client PK": 126862,
      "Client ID": "ES808258",
      "Client Name": "Greater Bethel AMEC",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Sharon Bush",
      "Contact Email": "slbush65@gmail.com"
    },
    {
      "Client PK": 128985,
      "Client ID": "C9983496",
      "Client Name": "Trinity Lutheran Church",
      "Cohort": "1 GPM Only",
      "Wave": "Wave 1",
      "Contact Name": "Christine Wirth",
      "Contact Email": "christine@stmarkwdm.org"
    }
   ]