import { Helmet } from "react-helmet";
import React from "react";
export const HexeaScript = () => {
  return (
    <Helmet>
      <script>
        {`
            const messagingHelper = new window.MessagingHelper()
            var isSaved = false          
            var zipCodeValid= false    
            var cardValid= false    
            var dateValid= false
            var cvvValid= false

            var email = null
            var nameOnCard = null
            var userToken = null

            var isGuest = false;
            
            var address = '';
            var addressInternal = '';
            var city = '';
            var state = '';
            var postalCode = '';
            var phoneNumber = '';

            function internal(pcct) {
            var hexea = Hexea(pcct);
            var paymentMethod = hexea.paymentMethod("card");
            var zipCode = document.getElementById("postalCode");
            function validateAllInputs(){
                if(!!zipCodeValid && !!cardValid && !!dateValid && cvvValid){
                    messagingHelper.postFormValidation(true)
                }
                else{
                    messagingHelper.postFormValidation(false)
                }
            }
            function focussedInput() {
                messagingHelper.sendEvent('focused', true);
            }
            zipCode.addEventListener("input",(e)=>validateZipCode(e.target.value))
            zipCode.addEventListener("blur",(e)=>validateZipCode(e.target.value, true))
            function validateZipCode(value,force){
                var displayError = document.getElementById("card-errors-zip");
                let trimmedValue = value.trim();
                if(value.length != trimmedValue.length){
                    value = trimmedValue;
                    zipCode.value = value;
                }
                if (validator.isPostalCode(value, "US")) {
                    displayError.textContent = "";
                    displayError.style.display = "none";
                    zipCode.classList.remove('form-control-error');
                    zipCodeValid=true
                    validateAllInputs()
                    return true
                } else {
                    zipCodeValid=false
                    validateAllInputs()
                    if(force){
                        displayError.innerHTML = "Invalid postal code";
                        zipCode.classList.add('form-control-error');
                        displayError.style.display = "block";                       
                        return false
                    }
                    if(value && value.length>=5){                    
                        displayError.innerHTML = "Invalid postal code";
                        zipCode.classList.add('form-control-error');
                        displayError.style.display = "block";                    
                        return false
                    }
                    
                }
            }

            // Custom styling can be passed into each payment method field.
            var style = {
                base: {
                    // Add your base input styles here. For example:
                    fontSize: "14x",
                    color: "#3d3d3d",
                    borderRadius: "3px",
                    borderColor: "grey",
                    borderWidth: 1,
                    borderStyle: "solid",
                    fontFamily:"muli",
                    height:"100%",
                    padding:0
                },
                invalid:{
                    color: "#E31212",
                    borderColor: "#E31212",
                    borderWidth: 1,
                    borderStyle: "solid",
                    fontFamily:"muli",
                }
            };

            // Create an instance of the cardNumber field
            var cardNumber = paymentMethod.field(
                "cardNumber",
                document.getElementById("card-number"),
                { style: style,placeholder:"Card number",hideIcon:true}
            );
            // Create an instance of the cardExpiration field
            var cardExpiration = paymentMethod.field(
                "cardExpiration",
                document.getElementById("card-exp"),
                { style: style,placeholder:"Expiration date" }
            );
            var cvvField = paymentMethod.field(
                "cardVerificationValue",
                document.getElementById("cvv"),
                { style: style,placeholder:"CVV (Card Verification Value)",hideIcon:true}
            );
            var form = document.getElementById("payment-form");

            form.addEventListener("submit", function (event) {
                // We will be submitting the form with JS in the next step. For now, prevent
                // it from submitting until the token has been collected.
                
                event.preventDefault();

                if(!userToken){
                    cardNumber.blur();
                    cardExpiration.blur();
                    zipCode.blur();
                    return;
                }
                if(validateZipCode(zipCode.value)){
                let auth = {};
                if(isGuest){
                    auth.partnerPayerRef = userToken
                } else {
                    auth.userToken = userToken
                }
                let reqBody = {
                    "email":email,
                    "isSaved":isSaved,
                    "authorizationType":"IDS",
                    "nameOnCard":nameOnCard,
                    "address":{                            
                        "postalCode": zipCode.value,
                        "addressLine1": address || '',
                        "addressLine2": addressInternal,
                        "city": city || '',
                        "state": state || null,               
                        },
                    ...auth
                }
                hexea
                    .tokenize(paymentMethod, reqBody)
                    .then(function (result) {
                        if (result.error) {
                            // Inform the payer that there was an error
                            var displayError = document.getElementById("card-errors");
                            displayError.textContent = result.error.message;
                            console.log(result)
                            messagingHelper.postError(result.error)
                            
                        } else {
                            // Send the token to your server
                            console.log(result)
                            messagingHelper.postPaymentToken(result)
                        }
                    });
                    }
                    else{
                        messagingHelper.postError({error:{message: 'Zip code is not valid'}});
                    }
                return false;
            });

            // Listen for change events
            [cardNumber].forEach(function (field) {
                field.on("focus", function (event) {
                    focussedInput()
                })
                field.on("change", function (event) {
                    console.log(event,field.target);
                    var displayError = document.getElementById("card-errors");
                    if (event.error) {
                            document.getElementById('card-number').classList.add('form-control-error');
                            displayError.textContent = event.error.message;
                            displayError.style.display = "block";
                            cardValid=false
                            validateAllInputs()

                    } 
                    else if (!event.complete || event.empty){
                        cardValid=false
                        validateAllInputs()
                    }
                    else {
                        document.getElementById('card-number').classList.remove('form-control-error');
                        displayError.textContent = "";
                        displayError.style.display = "none";                       
                        cardValid=true
                        validateAllInputs()
                    }
                });
            });

            // Listen for change events
            [cvvField].forEach(function (field) {
                debugger;
                field.on("focus", function (event) {
                    focussedInput()
                })
                debugger;
                field.on("change", function (event) {
                    console.log(event,field.target);
                    var displayErrorCvv = document.getElementById("card-errors-cvv");
                    if (event.error) {             
                            document.getElementById('cvv').classList.add('form-control-error');
                            displayErrorCvv.textContent = event.error.message;
                            displayErrorCvv.style.display = "block";
                            cvvValid = false;         
                            validateAllInputs()            
                    } 
                    else if (!event.complete || event.empty){
                        cvvValid = false;
                        validateAllInputs()
                    }
                    else {
                        document.getElementById('cvv').classList.remove('form-control-error');
                        displayErrorCvv.textContent = "";
                        displayErrorCvv.style.display = "none";
                        cvvValid = true;
                        validateAllInputs()
                    }
                });
            });

            // Listen for change events
            [cardExpiration].forEach(function (field) {
                field.on("focus", function (event) {
                    focussedInput()
                })
                field.on("change", function (event) {
                    console.log(event,field.target);
                    var displayErrorDate = document.getElementById("card-errors-date");
                    if (event.error) {             
                            document.getElementById('card-exp').classList.add('form-control-error');
                            displayErrorDate.textContent = event.error.message;
                            displayErrorDate.style.display = "block";             
                            dateValid=false
                            validateAllInputs()            
                    } 
                    else if (!event.complete || event.empty){
                        dateValid=false
                        validateAllInputs()
                    }
                    else {
                        document.getElementById('card-exp').classList.remove('form-control-error');
                        displayErrorDate.textContent = "";
                        displayErrorDate.style.display = "none";
                        dateValid=true
                        validateAllInputs()
                    }
                });
            });
            console.log('ready')
            messagingHelper.sendHexeaReady()
        }

        function makeid(length) {
            var result = "";
            var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            return result;
        }   
        //internal("0D4786C5C3","aaaaa12124124@hotmail.com","John Doe","GQ34rEGDebysZNkXuAPHqP4usdwRZ86SMESqMqud6ZM")
        `}
      </script>
    </Helmet>
  );
};
