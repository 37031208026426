import { Formik } from 'formik';
import React, { Fragment, useState, useRef, useEffect } from 'react'
import * as Yup from 'yup';

const Hexea = ({ createPaymentMethodAch }) => {
    let formRef = useRef(null);
    const [userDetails, setUserDetails] = useState(null)
    const routingNumber = useRef(null);
    const accNumberRef = useRef(null);
    const [ACDone, setACDone] = useState(false);
    const [RNDone, setRNDone] = useState(false);


    const validationSchema = Yup.object().shape({
        accountNumber: Yup.string().required().min(3).max(17),
        routingNumber: Yup.string().required().min(9).max(9),
    });


    useEffect(() => {

        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ hexeaReady: true }))
        window.addEventListener("submit", (e) => {
            setUserDetails({ ...e.detail })
            routingNumber.current.blur();
            formRef.current.submitForm()
        })
    }, [])

    return <Fragment>
        <div class="container-fluid hexea-container">
            <Formik
              innerRef={formRef}
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={{ routingNumber: "", accountNumber: '' }}
              onSubmit={(values, actions) => {
                if(!userDetails){
                    routingNumber.current.blur();
                    accNumberRef.current.blur();
                    return;
                }
                createPaymentMethodAch({ ...userDetails, ...values }, (result) => {
                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ paymentMethod: { ...result.data } }))
                })
              }}
              validateOnChange
            >
                {({ handleBlur, values, handleChange, handleSubmit, errors, touched, isValid }) => {

                    if (isValid && !!values.accountNumber && !!values.routingNumber) {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ validInputsAch: true }))
                    }
                    if (!isValid || !values.accountNumber && !values.routingNumber) {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ validInputsAch: false }))
                    }

                    return <form id="payment-form" className={'form-inline'} onSubmit={handleSubmit}>
                        <div className="form-group col-xs-12" style={{marginTop: '10px'}}>
                            <div className={'col-xs-9'}>
                                <input
                                  ref={accNumberRef}
                                  name="accountNumber"
                                  tabIndex={1}
                                  id="accountNumber"
                                  className="form-control"
                                  autocomplete="billing account-number"
                                  placeholder="Account number"
                                  type="tel"
                                  onBlur={handleBlur}
                                  value={values.accountNumber}
                                  onChange={handleChange}
                                  onFocus={() => setACDone(false)}
                                />
                            </div>
                            <div className={'col-xs-3'} style={{paddingTop: '4px'}}>
                                <button
                                  type="button"
                                  className={'btn btn-sm ' + (ACDone ? 'btn-default' : 'btn-success')}
                                  onClick={() => {
                                      setACDone(true);
                                      routingNumber.current.focus();
                                  }}>Next
                                </button>
                            </div>

                            {errors.accountNumber &&
                            touched.accountNumber &&
                            <div id="card-errors" role="alert" className="text-danger col-xs-12" role="alert">Account number should be between 3 and 17 numbers</div>}
                        </div>

                        <div className="form-group col-xs-12" style={{marginTop: '5px'}}>
                            <div className={'col-xs-9'}>
                                <input
                                  ref={routingNumber}
                                  tabIndex={2}
                                  name="routingNumber"
                                  id="routingNumber"
                                  className="form-control"
                                  autocomplete="billing routing-number"
                                  placeholder="Routing number"
                                  type="tel"
                                  onBlur={handleBlur}
                                  value={values.routingNumber}
                                  onChange={handleChange}
                                  onFocus={() => setRNDone(false)}
                                />
                            </div>
                            <div className={'col-xs-3'} style={{paddingTop: '4px'}}>
                                <button type="button" className={'btn btn-sm ' + (RNDone ? 'btn-default' : 'btn-success')} onClick={() => setRNDone(true)}>Done</button>
                            </div>
                            {errors.routingNumber &&
                            touched.routingNumber &&
                            <div id="card-errors" role="alert" className="text-danger col-xs-12" role="alert">Routing number should be 9 numbers</div>}
                        <input type='submit' tabIndex={3} style={{opacity:0, height:0, width:0}}/>
                        </div>
                    </form>
                }}
            </Formik>
        </div>
    </Fragment >

}

export default Hexea
