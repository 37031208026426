import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircleOutlinedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ArrowBack from "@material-ui/icons/ArrowBack";
import Modal from "@material-ui/core/Modal";
import Cookies from "js-cookie";
import moment from "moment";
import queryString from "query-string";
import Switch from "react-switch";
import { createIntl, createIntlCache } from "react-intl";
import ActivitySpinner from "../../../adminstration/components/ActivitySpinner";
import LockIcon from '../../../../img/lock.svg';
import VisaIcon from '../../../../img/cards/visa.svg';
import DiscoverIcon from '../../../../img/cards/discover.svg';
import AmexIcon from '../../../../img/cards/amex.svg';
import mastercard from '../../../../img/cards/master-card.svg';
import bankIcon from '../../../../img/bank-outline.png';

const cards = {
  visa: VisaIcon,
  Visa: VisaIcon,
  discover: DiscoverIcon,
  Discover: DiscoverIcon,
  amex: AmexIcon,
  Amex: AmexIcon,
  mastercard: mastercard,
  Mastercard: mastercard,
  Checking: bankIcon,
}

const PaymentConfirmation = ({
  checkIfIsValidPaymentToken,
  isValidTokenLoading,
  makeBasketPayment,
}) => {
  let recaptchaRef = useRef(null);
  let { paymentToken } = useParams();
  let history = useHistory();
  const parsed = queryString.parse(window.location.search);

  const [amount, setAmount] = useState(false);
  const [backgroundColor, setbackgroundColor] = useState("#965AA5");

  const [brand, setBrand] = useState(false)
  const [last4, setLast4] = useState(false)
  const [startDate, setStartDate] = useState(false)
  const [endDate, setEndDate] = useState(false)
  const [frequency, setFrequency] = useState(false)
  const [campaingName, setCampaingName] = useState(false)
  const [paymentInfoRes, setPaymentInfo] = useState(null)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentFeesPercentage, setPaymentFeesPercentage] = useState('')
  const [notificationEmail, setnotificationEmail] = useState(null)
  const [name, setName] = useState(null)
  const [isBasket, setIsBasket] = useState(false);
  const [paymentItems, setPaymentItems] = useState([])
  const [total, setTotal] = useState(0)
  const [paymentId, setPaymentId] = useState('')
  const [errorLoading, setErrorLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const cache = createIntlCache();
  let formater = createIntl(
    {
      locale: "en",
      defaultLocale: "en",
    },
    cache
  );


  const renderTotalLabel = (name) => {
    let newName = name;
    switch (name) {
      case "OneTimeNow":
        newName = "One-time total";
        break;
      case "OneTimeFuture":
        newName = "Once-time total";
        break;
      case "Weekly":
        newName = "Weekly total";
        break;
      case "BiWeekly":
        newName = "Bi-weekly total";
        break;
      case "BiMonthly":
        newName = "Twice a month (1st and 15th)";
        break;
      case "Monthly":
        newName = "Monthly total";
        break;
      case "Quarterly":
        newName = "Quarterly total";
        break;
      case "Annually":
        newName = "Annual total";
        break;
      default:
        newName = 'Total'
        break;
    }
    return newName;
  };

  useEffect(() => {
    setIsBasket(parsed.basket === 'true');
    if (parsed.paymentInfo) {
      checkIfIsValidPaymentToken(
        paymentToken,
        parsed.paymentInfo,
        parsed.gg === "true",
        (res, error) => {
          if (error) {
            console.log(error);
            history.push("/IDS/paymentError", {
              errorMessage: error,
              backgroundColor: res.backgroundColor,
            });
            return;
          }
          res.includeProcessingFee ? setPaymentFeesPercentage(res.paymentFeesPercentage) : setPaymentFeesPercentage('');
          setPaymentItems(res.paymentItems)
          setTotal(res.paymentItems.map((paymentItem) => parseFloat(paymentItem.showAmount)).reduce((a, b) => a + b, 0))
          setBrand(res.brand);
          setLast4(res.last4);
          setName(res.name)
          setbackgroundColor(res.backgroundColor);
          setnotificationEmail(res.notificationEmail);
          setPaymentId(res.paymentId);
          setPaymentInfo({ ...res });
        }
      );
    }
  }, []);

  const _makePayment = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      setPaymentLoading(true);
      makeBasketPayment(
        { ...paymentInfoRes, reCaptchaToken: token, gg: parsed.gg === "true" },
        (_, error) => {
          setPaymentLoading(false);
          if (error) {
            setErrorMessage(error);
            setErrorLoading(true);
            return;
          }
          history.push("/IDS/paymentSuccess", {
            orgName: campaingName,
            qty: amount,
            startDate: startDate && startDate,
            endDate: endDate && endDate,
            frequency: frequency && frequency,
            backgroundColor,
            notificationEmail,
            name
          });
        }
      );
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return "unknown";
  };

  const closeWindow = () => {
    if (getMobileOperatingSystem() == "ios") {
      let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
      var win = window.open(url, "_blank");
      win.focus();
    } else if (getMobileOperatingSystem() == "android") {
      let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/?paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
      var win = window.open(url, "_blank");
      win.focus();
    } else {
      let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=false&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
      var win = window.open(url, "_blank");
      win.focus();
    }
  };

  const renderPaymentItems = () => paymentItems.map((paymentItem) => {
    const { campaignType, campaign: { content }, campaign, frequency, startDate, endDate, showAmount, textFieldTitle, memoLine, campaignQty } = paymentItem;
    return (
      <div className="payment-review-card-item">
        {campaignType === "donation" && (
          <div className="row ids-row payment-details">
            <div className="ids-col col-xs-12 description">
              <p className="ids-col col-xs-12 title">{content.title}</p>
              <p className="ids-col col-xs-12">
                {formater.formatNumber(showAmount, {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            {frequency !== "OneTimeNow" && (
              <div className="ids-col col-xs-12 description extra">
                <p className="ids-col col-xs-6">
                  Start date
                </p>
                <p className="ids-col col-xs-6 description extra">
                  {moment(startDate, "YYYY-MM-DD").format("MM/DD/YYYY")}
                </p>
              </div>
            )}
            {frequency !== "OneTimeNow" && endDate && (
              <div className="ids-col col-xs-12 description extra">
                <p className="ids-col col-xs-6 description extra">
                  End date
                </p>
                <p className="ids-col col-xs-6 description extra">
                  {moment(endDate, "YYYY-MM-DD").format("MM/DD/YYYY")}
                </p>
              </div>
            )}
            <div className="ids-col col-xs-12 description extra">
              <p className="ids-col col-xs-8 description extra">{renderTotalLabel(frequency)}</p>
            </div>
            {textFieldTitle && memoLine && (
              <div className="ids-col col-xs-12 description extra">
                <p className="ids-col col-xs-6 description extra">{textFieldTitle}</p>
                <p className="ids-col col-xs-6 description extra">{memoLine}</p>
              </div>
            )}
          </div>
        )}
        {campaignType === "payment" && (
          <div className="row ids-row payment-details">
            <div className="ids-col col-xs-12 description">
              <p className="ids-col col-xs-12 title">{content.title}</p>
              <p className="ids-col col-xs-12">
                {formater.formatNumber(showAmount, {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
              <p className="ids-col col-xs-12 description extra">Quantity: {campaignQty}{" "}
                {!!campaign.paymentOptions &&
                  formater.formatNumber(campaign.paymentOptions.price.toString(), {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}ea
              </p>
            </div>
            <div className="ids-col col-xs-12 description">
              <p className="ids-col col-xs-8 description extra">One-time total</p>
            </div>
            {textFieldTitle && memoLine && (
              <div className="ids-col col-xs-12 description extra">
                <p className="ids-col col-xs-6 description extra">{textFieldTitle}</p>
                <p className="ids-col col-xs-6 description extra">{memoLine}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="container-fluid paymentConfimation gray-background">
      <Modal
        open={paymentLoading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ width: '80%', height: '200px', position: 'absolute', top: '40%', left: '10%', backgroundColor: 'white', borderRadius: '20px' }}>
          <p style={{ width: '100%', fontSize: '30px', textAlign: 'center', color: 'black', marginTop: '20px' }}>Processing ...</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ActivitySpinner className={"payment-spinner"} />
          </div>
        </div>
      </Modal>
      <Modal
        open={errorLoading}
        onClose={() => setErrorLoading(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ width: '80%', height: '300px', position: 'absolute', top: '20%', left: '10%', backgroundColor: 'white', borderRadius: '20px' }}>
          <p style={{ width: '100%', fontSize: '25px', fontWeight: 600, textAlign: 'left', marginLeft: '30px', color: 'red', marginTop: '25px' }}>
            Error
          </p>
          <div style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#66666640', borderBottomStyle: 'solid' }} />
          <p style={{ width: '80%', fontSize: '18px', textAlign: 'left', marginLeft: '30px', color: 'black', marginTop: '20px' }}>
            {'There was an issue processing the Payment Method'}
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <div
              className="btn ids-col"
              style={{
                borderColor: '#66666640',
                display: 'flex',
                justifyContent: 'center',
                minWidth: '25%',
                borderRadius: '5px',
              }}
              onClick={() => setErrorLoading(false)}
              id="close-modal-error"
            >
              <span style={{ color: 'black', padding: '15px', fontWeight: 600 }}>Ok</span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="IDS navbar payment-review-navbar">
        <h1>{paymentId ? 'Editing' : 'Summary'}</h1>
      </div>
      <div className="stepper-container" />
      <div className="IDS body payment-review-card">
        {isValidTokenLoading ? (
          <ActivitySpinner className={"payment-spinner"} />
        ) : (
          <Fragment>
            <div className="payment-review-card-item">
              <div className="row ids-row payment-details">
                <div className="ids-col col-xs-12 description">
                  <p className="ids-col col-xs-8">Total</p>
                  <p className="ids-col col-xs-4 total">${total.toFixed(2)}</p>
                </div>
              </div>
            </div>
            <div>
              {renderPaymentItems()}
            </div>
            <div className="payment-review-card-item">
              <div className="row ids-row payment-details">
                <div className="ids-col col-xs-12 description">
                  <p className="row ids-row payment-title">Payment method</p>
                  <p className="card row ids-row">
                    <img
                      src={cards[brand]}
                      style={{
                        width: cards[brand] === "Checking" ? 48 : 35,
                        height: cards[brand] === "Checking" ? 31 : 32,
                        marginRight: 16
                      }} />
                    **** **** {last4}
                  </p>
                  <p className="card row ids-row">
                    {
                      paymentFeesPercentage && (
                        <>
                          <div className="ids-col col-xs-9 description fees">
                            Cover processing fees <span style={{ color: backgroundColor }}>({parseFloat(paymentFeesPercentage).toFixed(2)}%)</span>
                          </div>
                        </>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="row ids-row">
              <div className="ids-col col-xs-12 notification-email">
                Your receipt will be sent to your account email address:{" "}
                {notificationEmail}{" "}
              </div>
            </div>
            <div className="row ids-row" style={{ position: 'sticky', bottom: 0, left: 16, right: 16, backgroundColor: '#F4F5F7'}}>
              <div className="ids-col col-xs-4 submit-controls">
                <div className="row ids-row">
                  <div
                    className="btn btn-cancel ids-col col-xs-8"
                    style={{
                      color: 'black',
                      borderColor: 'black',
                      width: '100%',
                      marginRight: 8
                    }}
                    onClick={closeWindow}
                    id="payment-cancel"
                  >
                    <ArrowBack
                      style={{
                        fontSize: 20,
                        marginRight: 15,
                        color: 'black',
                      }}
                    /> Back
                  </div>
                </div>
              </div>
              <div className="ids-col col-xs-8 submit-controls">
                <div className="row ids-row">
                  <input
                    style={{
                      borderColor: backgroundColor,
                      backgroundColor,
                      width: '100%',
                      marginLeft: 8
                    }}
                    className="btn btn-submit ids-col col-xs-8"
                    type="button"
                    disabled={paymentLoading || isValidTokenLoading}
                    onClick={_makePayment}
                    value="Submit"
                    id="payment-submit"
                  />
                </div>
              </div>
              <div className="row ids-row col-xs-12">
                <p className="paymentText">
                  <img
                    src={LockIcon}
                    style={{
                      width: 12,
                      height: 16,
                      marginRight: 16,
                      marginBottom: 4
                    }} />
                  Payments are processed securely</p>
              </div>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={window.RECAPTCHA_SITEKEY}
            />
          </Fragment>
        )}
      </div>
      <div className="footer col-md-12">
        This site is protected by reCAPTCHA and the Google{" "}
        <a style={{ color: backgroundColor }} href="https://policies.google.com/privacy?hl=en-US" target="_blank">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a style={{ color: backgroundColor }} href="https://policies.google.com/terms?hl=en-US">
          Terms of Service
        </a>{" "}
        apply
      </div>
    </div>
  );
};

export default PaymentConfirmation;
