import React, { PureComponent } from 'react';
import moment from 'moment-timezone'

class Footer extends PureComponent {
  render() {
    return (
      <div className="vanco-footer">
        <a href={`${window.VANCOPAYMENTS_BASEURL}/terms-and-conditions`} target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        &nbsp;|&nbsp;
        <a href={`${window.VANCOPAYMENTS_BASEURL}/privacy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        &nbsp;|&nbsp;
        <a href="mailto:cs@vancopayments.com">Email Support</a>
        <br />
        &copy;{moment().format("Y")} Vanco. All rights reserved.
      </div>
    );
  }
}

export default Footer;
