import firebase from 'firebase/compat/app';
import axios from "axios";
import { PhoneNumber } from "google-libphonenumber";

// For Google libnumber
const PNF = require("google-libphonenumber").PhoneNumberFormat;
// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const internalColors = [
  "#7BD5FC",
  "#FEC756",
  "#FE6E50",
  "#FEAE5E",
  "#3BA17F",
  "#DC4758",
  "#B1E47F",
  "#3F7597",
  "#A3A3A3",
  "#767573",
  "#3BA04D",
  "#A04B39",
  "#748AB3",
  "#B6759D",
  "#81B676",
  "#217683",
];

/**
 * Amend the role labels based on the admin settings
 *
 * @param role
 * @param organization
 * @returns {*}
 */
export const roleLabel = (role, organization) => {
  role = String(role).toLowerCase()
  switch (role) {
    case "admin":
      return organization.adminTerm;
    case "user":
      return organization.userTerm;
    case "moderator":
      return "Moderator";
    default:
      return null;
  }
};

/**
 * Return user status label
 *
 * @param userId
 * @param blocked
 * @returns {string}
 */
export const statusLabel = (userId, blocked) =>
  blocked && Object.keys(blocked).find((key) => key === userId)
    ? blockedLabel()
    : activeLabel();

/**
 * Return the blocked label
 *
 * @returns {string}
 */
export const blockedLabel = () => "Suspended";

/**
 * Return the active label
 *
 * @returns {string}
 */
export const activeLabel = () => "Active";

/**
 * Filter the users by role
 *
 * @param allUsers
 * @returns {{admins: Array, users: Array}}
 */
export const filterUsersByRole = (allUsers) => {
  const admins = [];
  const users = [];
  const moderators = [];
  allUsers.map((user) => {

    if (user.role === "Admin") {
      admins.push(user);
    }
    else if (user.role === "Moderator") {
      moderators.push(user);
    }
    else {
      users.push(user);
    }
    return true;
  });
  return { admins, users };
};

/**
 *
 * @param image
 * @param targetPath
 * @param callback
 */
export const uploadImageToStorage = (image, targetPath, callback) => {
  const storageRef = firebase
    .storage()
    .ref(`/${targetPath}/${firebase.auth().currentUser.uid}_${Date.now()}.jpg`);
  storageRef
    .put(image)
    .then((snapshot) => {
      if (snapshot.state === "success") {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          callback(downloadURL);
        });
      }
    })
    .catch((error) => console.log(error));
};

/**
 *
 * @param str
 * @returns {*}
 */
export const stringToColor = (str) => {
  if (str) {
    switch (str.substring(0, 2).length) {
      case 0:
        return "#000000";
      case 1:
        return internalColors[str.charCodeAt(0) % internalColors.length];
      case 2:
        return internalColors[
          (str.charCodeAt(0) + str.charCodeAt(1)) % internalColors.length
        ];
      default:
        return "#000000";
    }
  }
  return false;
};

/**
 *
 * @param phoneNumber
 * @param country
 * @returns {*}
 */
export const parsePhonenumber = (phoneNumber, country) =>
  phoneUtil.parseAndKeepRawInput(phoneNumber, country);

export const isValidNumber = (phoneNumber, country) => {
  try {
    if (phoneNumber) {
      const parsedPhone = parsePhonenumber(phoneNumber, country);
      return phoneUtil.isValidNumber(parsedPhone);
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 *
 * @param phoneNumber
 * @param country
 * @returns {*}
 */
export const getE164 = (phoneNumber, country) =>
  phoneNumber &&
  isValidNumber(phoneNumber, country) &&
  phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phoneNumber, country),
    PNF.E164
  );

/**
 *
 * @param phoneNumber
 * @returns {*}
 */
export const getRegionCode = (phoneNumber) =>
  phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phoneNumber));

/**
 *
 * @param phoneNumber
 * @param country
 * @returns {*}
 */
export const formatPhoneNumber = (phoneNumber, country) =>
  phoneNumber && isValidNumber(phoneNumber, country)
    ? phoneUtil.formatInOriginalFormat(
      phoneUtil.parse(phoneNumber, country),
      country
    )
    : null;

/**
 * Org has specified feature flag
 *
 * @param featureFlags
 * @param org
 * @param feature
 * @returns {DEFAULT_STATE.featureFlags|{}|boolean}
 */
export const orgHasFeatureFlag = (featureFlags, org, feature) =>
  featureFlags &&
  featureFlags[feature] &&
  org.featureFlags &&
  org.featureFlags.indexOf(feature) !== -1;

/**
 * Is organization using CXP or a link for donations
 *
 * @param org
 * @returns {*|boolean}
 */
export const orgUsingFullGiveFeature = (org) =>
  org.giveMechanism && org.giveMechanism === "cxp";

/**
 * Check statuspage.io for incidents
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkStatusPage = () => {
  const config = {
    baseURL: window.STATUS_PAGE_BASEURL,
    url: "/v1/pages/bjftj3pc5sz8/incidents.json",
    method: "GET",
    headers: { Authorization: "Bearer 8959a313-2f0d-41ee-a205-3f3843b78d50" },
    responseType: "json",
    timeout: 3000,
  };
  return axios(config)
    .then((resp) => {
      // Successful request, returning data
      console.log(resp);
      if (resp.status === 200) {
        return resp.data;
      }
      return null;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("No response from API");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error in API call", error.message);
      }
      return null;
    });
};

/**
 * Parse statuspage.io responses
 *
 * @param statusPageResponse
 * @param incidentCallback
 * @param noIncidentCallback
 */
export const checkStatusPageResponse = (
  statusPageResponse,
  incidentCallback,
  noIncidentCallback
) => {
  if (statusPageResponse) {
    const filteredStatusPageResponse = statusPageResponse.filter(
      (incident) => !incident.resolved_at && !incident.scheduled_for
    );
    if (filteredStatusPageResponse && filteredStatusPageResponse.length > 0) {
      const relevantIncidents = filteredStatusPageResponse.filter((incident) =>
        incident.incident_updates.find((update) =>
          update.affected_components.find(
            (component) => component.code === "kzs1nn4vtp0d"
          )
        )
      );
      if (relevantIncidents.length > 0) {
        return incidentCallback({ data: relevantIncidents });
      }
    }
  }

  noIncidentCallback();
};

/**
 * Create organization invite code
 *
 * @returns {string}
 */
export const createOrganizationInviteCode = () => {
  let code = "";
  const letters = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  for (let i = 0; i < 6; i += 1) {
    code += letters[Math.floor(Math.random() * 31)];
  }
  return code;
};
export const formatPhoneAsYouType = (phone) => {
	const input = phone.replace(/\D/g,'').substring(0,10); 
	const zip = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);
  
	if(input.length > 6){return `(${zip}) ${middle}-${last}`;}
	else if(input.length > 3){return `(${zip}) ${middle}`;}
	else if(input.length > 0){return `(${zip}`;}
  return "";
}