import moment from "moment";

import * as actionConstants from "../actionConstants";
import { APIV1 } from "..";

const setRSVPEvents = ({ events, orgName, orgDetails }) => ({
  type: actionConstants.RSVP_LOADED_EVENTS,
  events,
  orgName,
  orgDetails,
});

export const setRSVPEventDetails = (payload) => ({
  type: actionConstants.RSVP_LOADED_EVENT,
  payload,
});

export const setRSVPEventOrgDetails = (payload) => ({
  type: actionConstants.RSVP_LOADED_EVENT_ORG_DETAILS,
  payload,
});

const setRSVPEventsLoading = () => ({
  type: actionConstants.RSVP_LOADING_EVENTS,
});

// ? Event details
const setRSVPEventLoading = () => ({
  type: actionConstants.RSVP_LOADING_EVENT,
});

// ? Event details
const setRSVPEventLoadingFailed = () => ({
  type: actionConstants.RSVP_LOADING_EVENT_FAILED,
});

const setRSVPEventsLoadingFailed = () => ({
  type: actionConstants.RSVP_LOADING_EVENTS_FAILED,
});

const setRSVPJoining = () => ({
  type: actionConstants.RSVP_JOINING,
});

const setRSVPJoined = () => ({
  type: actionConstants.RSVP_JOINED,
});

const setRSVPJoinedError = () => ({
  type: actionConstants.RSVP_JOINED_ERROR,
});
export const isMultiDay = (event) => {
  const {dateTimeStart, dateTimeEnd} = event;
  if(!dateTimeEnd) return false;
  const dayDiff = moment(dateTimeEnd).diff(moment(dateTimeStart), 'days');
  return dayDiff>0;
}
export const RSVPGetEvents = (orgId) => async (dispatch, getState) => {
  try {
    let loading = getState().RSVPevents.RSVPloadingEvents;

    if (loading) {
      return;
    }
    dispatch(setRSVPEventsLoading());

    var data = await APIV1.get(`events?organizationId=${orgId}`, { withCredentials: false });
    console.log(data.data);
    let events = data.data.events ? data.data.events : [];
    let orgName = data.data.orgName ? data.data.orgName : "";
    // let filteredEvents = events.filter((i) =>
    //   moment(i.dateTimeStart).isSameOrAfter(moment(), "day")
    // );

    const sortedEvents = events.sort((ev1, ev2) => {
      let date1 = ev1.dateTimeStart;
      let date2 = ev2.dateTimeStart;

      if (ev1.allDay) date1 = moment(ev1.dateTimeStart).startOf('day').valueOf();
      if (ev2.allDay) date2 = moment(ev2.dateTimeStart).startOf('day').valueOf();

      return date1 - date2;
    })

    dispatch(setRSVPEvents({ events: sortedEvents, orgName }));

    // var data = await APIV1.get(`events?organizationId=miX1lTR5S02YVycIDhuT`);
  } catch (error) {
    dispatch(setRSVPEventsLoadingFailed());
  }
};

export const RSVPGetEvent = (eventId) => async (dispatch, getState) => {
  try {
    let loading = getState().RSVPevents.RSVPloadingEvents;

    if (loading) {
      return;
    }
    dispatch(setRSVPEventLoading());

    var data = await APIV1.get(`events/${eventId}`, { withCredentials: false });
    console.log(data);
    let payload = data.data;
    if(!!payload.name)
      dispatch(setRSVPEventDetails(payload));
    else     
      dispatch(setRSVPEventLoadingFailed());
    // var data = await APIV1.get(`events?organizationId=miX1lTR5S02YVycIDhuT`);
  } catch (error) {
    console.log(error);
    dispatch(setRSVPEventLoadingFailed());
  }
};
export const RSVPGetEventOrgDetails = (orgId) => async (dispatch, getState) => {
  try {

    var response = await APIV1.get(`organization/${orgId}`, { withCredentials: false });
    console.log(response);
    const {data} = response;
    const orgData = {id:data.id, ...data.payload}
    dispatch(setRSVPEventOrgDetails(orgData));

    // var data = await APIV1.get(`events?organizationId=miX1lTR5S02YVycIDhuT`);
  } catch (error) {
    console.log(error);
    dispatch(setRSVPEventLoadingFailed());
  }
};

export const RSVPGPostSignUp = (
  name,
  attendees,
  email,
  phoneNumber,
  eventId,
  orgId,
  cb
) => async (dispatch, getState) => {
  try {
    let loading = getState().RSVPevents.RSVPJoining;
    if (loading) {
      return;
    }
    dispatch(setRSVPJoining());

    let form = new FormData();
    form.append("name", name);
    form.append("attendees", attendees);
    form.append("email", email);
    form.append("phoneNumber", phoneNumber);
    form.append("eventId", eventId);
    form.append("orgId", orgId);

    var data = await APIV1.post("events/attendees", form, { withCredentials: false });
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    dispatch(setRSVPJoined());
    cb && cb();
  } catch (error) {
    dispatch(setRSVPJoinedError());
  }
};
