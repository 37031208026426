// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
    isValidToken: false,
    isValidTokenLoading: false,
    isValidTokenLoaded: false,
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN:
            return {
                ...state, isValidToken: action.payload
            };
        case actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN_LOADING:
            return {
                ...state, isValidTokenLoading: true, isValidTokenLoaded: false
            };
        case actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN_LOADED:
            return {
                ...state, isValidTokenLoading: false, isValidTokenLoaded: true
            };
        default:
            return state;
    }
}

export const getGiveIDS = ({
    isValidToken,
    isValidTokenLoading,
    isValidTokenLoaded,
}) => ({
    isValidToken,
    isValidTokenLoading,
    isValidTokenLoaded,
});
