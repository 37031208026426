import React from 'react'

const SuggestionAlert = ({ children, error, icon = "fa-exclamation-circle" }) => {
    return (
        <div className={"suggestion-alert"}>
            <div>
                <i className={["suggestion-alert-icon", "fa", icon].join(" ")} />
            </div>
            <div className="suggestion-alert-message">{children}</div>
        </div>
    )
}

export default SuggestionAlert
