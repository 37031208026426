import { Formik } from 'formik';
import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import * as Yup from 'yup';
import { ResizeObserver } from '@juggle/resize-observer';

const HexeaAchGG = ({ createPaymentMethodAch }) => {
    let formRef = useRef(null);
    const [userDetails, setUserDetails] = useState(null)
    const routingNumber = useRef(null);
    const accNumberRef = useRef(null);
    const [ACDone, setACDone] = useState(false);
    const [RNDone, setRNDone] = useState(false);
    const containerRef = useRef(null);
    
    const observer = useRef(new ResizeObserver(entries => {
        const {height} = entries[0].contentRect;
        sendResizeAlert(height)
    }));

    
    const validationSchema = Yup.object().shape({
        accountNumber: Yup.string().required().min(3).max(17),
        routingNumber: Yup.string().required().min(9).max(9),
    });

    const sendResizeAlert = async (newHeight) => {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'ach_resize',
            payload: newHeight
        }));
    }

    useEffect(()=>{
        if(containerRef.current){
            observer.current.observe(containerRef.current)
        }
    },[]);
    useEffect(() => {

        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ hexeaReady: true }))
        window.addEventListener("submit", (e) => {
            window.ReactNativeWebView.postMessage(JSON.stringify({type:'pre_request', payload: e.detail}))
            setUserDetails({ ...e.detail })
            routingNumber.current.blur();
            formRef.current.submitForm()
        })
    }, [])
    
    return <Fragment>
        <div class="container-fluid hexea-container" ref={containerRef}>
            <Formik
                innerRef={formRef}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{ routingNumber: "", accountNumber: '' }}
                onSubmit={(values, actions) => {
                    if(!userDetails){
                        routingNumber.current.blur()
                        accNumberRef.current.blur()
                        return
                    }
                    createPaymentMethodAch({ ...userDetails, ...values }, (result) => {
                        if(result.error) return;
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ paymentMethod: { ...result.data } }))
                    })
                }}
                validateOnChange
            >
                {({ handleBlur, values, handleChange, handleSubmit, errors, touched, isValid }) => {

                    if (isValid && !!values.accountNumber && !!values.routingNumber) {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ validInputsAch: true }))
                    }
                    if (!isValid || !values.accountNumber && !values.routingNumber) {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ validInputsAch: false }))
                    }
                    
                    return <form id="payment-form" className={'form-inline'} onSubmit={handleSubmit}>
                        <div className="form-group col-xs-12" style={{ marginTop: 16, paddingRight:0, paddingLeft:0 }}>

                            <input
                                ref={accNumberRef}
                                name="accountNumber"
                                id="accountNumber"
                                tabIndex={2}
                                className={"form-control "+`${errors.accountNumber ? 'form-control-error' : ''}`}
                                autocomplete="billing account-number"
                                placeholder="Account number"
                                type="tel"
                                onBlur={handleBlur}
                                value={values.accountNumber}
                                onChange={handleChange}
                                onFocus={() => {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({ focussed: true }))
                                    setACDone(false)}
                                }
                            />


                            {errors.accountNumber &&
                            touched.accountNumber &&
                            <div id="card-errors" role="alert" className="text-danger col-xs-12" role="alert">Account number should be between 3 and 17 numbers</div>}
                        </div>
                        <div className="form-group col-xs-12" style={{ marginTop: 8, paddingRight:0, paddingLeft:0 }}>
                                <input
                                    ref={routingNumber}
                                    tabIndex={1}
                                    name="routingNumber"
                                    id="routingNumber"
                                    className={"form-control "+`${errors.routingNumber ? 'form-control-error' : ''}`}
                                    autocomplete="billing routing-number"
                                    placeholder="Routing number"
                                    type="tel"
                                    onBlur={handleBlur}
                                    value={values.routingNumber}
                                    onChange={handleChange}
                                    onFocus={() => {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ focussed: true }))
                                        setRNDone(false)
                                    }}
                                />

                            {errors.routingNumber &&
                                touched.routingNumber &&
                                <div id="card-errors" role="alert" className="text-danger col-xs-12" role="alert">Routing number should be 9 numbers</div>}
                                <input type='submit' tabIndex={3} style={{opacity:0, height:0, width:0}}/>
                        </div>
                        
                    </form>
                }}
            </Formik>
        </div>
    </Fragment >

}

export default HexeaAchGG
