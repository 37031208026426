import { connect } from "react-redux";

import RSVPEvents from "./RSVPEvents";
import * as actions from "../../../../actions/RSVPEventsActions";
import { getRSVPevents } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
  ...getRSVPevents(state),
});

export default connect(mapStateToProps, actions)(RSVPEvents);
