import { combineReducers } from "redux";
import metric, * as fromMetric from "./metricReducer";
import RSVPevents, * as fromRSVPevents from "./RSVPEventsReducer";
import giveIDS, * as fromGiveIDS from './giveIDSReducer';

export default combineReducers({
  metric,
  RSVPevents,
  giveIDS
});

export const getMetric = (state) => fromMetric.getMetric(state.metric);

export const getGiveIDSReducer = state => fromGiveIDS.getGiveIDS(state.giveIDS);

export const getRSVPevents = (state) =>
  fromRSVPevents.getEvent(state.RSVPevents);
