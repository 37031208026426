import React, { Fragment, useState } from "react";
import moment from "moment";

const CustomInput = ({
  value,
  onChange,
  placeholder,
  error,
  dropdown,
  additionalGuests,
  availableTickets,
  hasAttendeeLimit,
  type,
  pattern,
  attendeeLimit,
  errorMessage,
  focusColor,
}) => { 
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  }
  const handleBlur = () => {
    setIsFocused(false);
  }
  return (
  // <Fragment style={{position:'relative'}}>
  <Fragment >
    {!!value && 
      <p 
        style={(isFocused && !error) ? {color:focusColor}:  {}}
        className={["RSVP-sign-in-custom-input-top-label", error ? "has-error":""].join(" ")}>
        {placeholder}
      </p>}
    <div>
      {dropdown ? (
        <select
          disabled={!availableTickets}
          placeholder={placeholder}
          onBlur={handleBlur}
          onFocus={handleFocus}
          style={isFocused ? {borderColor:focusColor}:  {}}
          className={[
            "form-control",
            "RSVP-input",
            "RSVP-input-dropdown",
            error ? "has-error" : "",
          ].join(" ")}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <Fragment>
            {additionalGuests &&
              Array.apply(
                null,
                Array(
                  Math.min(
                    Math.max(availableTickets, 1),
                    +attendeeLimit && hasAttendeeLimit ? attendeeLimit : 20
                  )
                )
              ).map((_, i) => (
                <option value={i + 1} className="form-control">
                  {i + 1}
                </option>
              ))}
            {!additionalGuests && (
              <option value="1" className="form-control">
                1
              </option>
            )}
            {/* <div style={{backgroundColor:'blue', width:16, height:16}}></div> */}
          </Fragment>
        </select>
      ) : (
        <input
          pattern={pattern}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={isFocused ? {borderColor:focusColor}:  {}}
          className={[
            "form-control",
            "RSVP-input",
            error ? "has-error" : "",
          ].join(" ")}
          value={value}
          type={type}
          onInput={(e) => {
            if (pattern) {
              if (pattern.test(e.target.value)) {
                onChange(e.target.value);
              } else {
                return;
              }
            } else {
              onChange(e.target.value);
            }
          }}
        />
      )}
      {errorMessage && error && <p className="error">{errorMessage}</p>}
    </div>
  </Fragment>
  );
}

export default CustomInput;
