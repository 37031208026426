class MessagingHelper {
  constructor() {}
  sendEvent(eventName, payload) {
    if (!window.ReactNativeWebView?.postMessage) return;
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: eventName,
        payload: payload,
      })
    );
  }
  sendHexeaReady() {
    this.sendEvent("hexea_ready", true);
  }
  postError(error) {
    this.sendEvent("payment_error", error);
  }
  postPaymentToken(token) {
    this.sendEvent("payment_token", token);
  }
  postAchResize(newSize) {
    this.sendEvent("ach_resize", newSize);
  }
  postCCResize(newSize) {
    this.sendEvent("cc_resize", newSize);
  }
  postFormValidation(isValid) {
    this.sendEvent("form_validation", isValid);
  }
}

//adding constructor to the window object
window.MessagingHelper = MessagingHelper;
export default MessagingHelper;
