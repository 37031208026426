import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet';

import { HexeaScript } from './src';

const Hexea = () => {

    return <Fragment>
        <div class="container-fluid hexea-container" style={{paddingLeft: 24, paddingRight: 24}}>
            <form id="payment-form" action="#">
                <div class="form-group col-xd-12">
                    <div id="card-number" className="form-control" data-placeholder="Card number">
                    </div>
                    <div id="card-errors" role="alert" class="text-danger" role="alert"></div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-6">
                        <div id="card-exp" class="form-control" data-placeholder="Expiration date">
                        </div>
                        <div id="card-errors-date" role="alert" class="text-danger" role="alert"></div>
                    </div>
                    <div class="form-group col-xs-6">
                        <input
                            name="bill-postalCode"
                            id="postalCode"
                            class="form-control"
                            autocomplete="billing postal-code"
                            placeholder="ZIP code"
                            type="tel"
                        />
                        <div id="card-errors-zip" role="alert" class="text-danger" role="alert"></div>
                    </div>
                </div>
            </form>
        </div>
        <Helmet>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/validator/13.1.17/validator.min.js" integrity="sha512-p1b+doSyVwyKqVTZeU1+XJtjpBSkhllBca2L6QTxPUjZZ0ELeZIHkAeQdtfNulbXxLdCwN4uKYGPpp78xeqwfQ==" crossorigin="anonymous"></script>
        </Helmet>
        <HexeaScript />
    </Fragment>

}

export default Hexea