import React, { Fragment } from "react";
import ActivitySpinner from "../../adminstration/components/ActivitySpinner";

const Loading = () => {
  return (
    <div className="IDS-login-container" style={{textAlign: 'center'}}>
      <p>Logging in... Please wait</p>
      <ActivitySpinner />
    </div>
  );
};

export default Loading;
