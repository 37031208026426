// @flow weak
import * as actionConstants from "../actionConstants";

// Reducer
const DEFAULT_STATE = {
  RSVPloadingEvents: false,
  RSVPloadedEvents: false,
  RSVPloadingEventsFailed: false,
  RSVPloadingEvent: false,
  RSVPloadedEvent: false,
  RSVPloadingEventFailed: false,
  RSVPJoining: false,
  RSVPJoined: false,
  RSVPJoinedError: false,
  RSVPevents: [],
  RSVPeventsOrgName: null,
  RSVPeventsOrgDetails: {},
  RSVPEventDetails: {},
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.RSVP_LOADING_EVENTS:
      return {
        ...state,
        RSVPloadingEvents: true,
        RSVPloadedEvents: false,
        RSVPloadingEventsFailed: false,
      };
    case actionConstants.RSVP_JOINING:
      return {
        ...state,
        RSVPJoining: true,
        RSVPJoined: false,
        RSVPJoinedError: false,
      };
    case actionConstants.RSVP_JOINED:
      return {
        ...state,
        RSVPJoining: false,
        RSVPJoined: true,
        RSVPJoinedError: false,
      };
    case actionConstants.RSVP_JOINED_ERROR:
      return {
        ...state,
        RSVPJoining: false,
        RSVPJoined: false,
        RSVPJoinedError: true,
      };
    case actionConstants.RSVP_LOADED_EVENTS:
      return {
        ...state,
        RSVPevents: action.events,
        RSVPeventsOrgName: action.orgName,
        RSVPloadingEvents: false,
        RSVPloadedEvents: true,
        RSVPloadingEventsFailed: false,
      };
    case actionConstants.RSVP_LOADING_EVENTS_FAILED:
      return {
        ...state,
        RSVPloadingEvents: false,
        RSVPloadedEvents: false,
        RSVPloadingEventsFailed: true,
      };
    case actionConstants.RSVP_LOADED_EVENT:
      return {
        ...state,
        RSVPEventDetails: action.payload,
        RSVPloadingEvent: false,
        RSVPloadedEvent: true,
        RSVPloadingEventFailed: false,
      };
    case actionConstants.RSVP_LOADING_EVENT:
      return {
        ...state,
        RSVPEventDetails: {},
        RSVPloadingEvent: true,
        RSVPloadedEvent: false,
        RSVPloadingEventFailed: false,
      };
    case actionConstants.RSVP_LOADING_EVENT_FAILED:
      return {
        ...state,
        RSVPEventDetails: {},
        RSVPloadingEvent: false,
        RSVPloadedEvent: false,
        RSVPloadingEventFailed: true,
      };
    case actionConstants.RSVP_LOADED_EVENT_ORG_DETAILS:
      return {
        ...state,
        RSVPeventsOrgDetails: action.payload,
      };
    default:
      return state;
  }
}

// Selectors (mapStateToProps)
export const getEvent = ({
  RSVPloadingEvents,
  RSVPloadedEvents,
  RSVPloadingEventsFailed,
  RSVPloadingEvent,
  RSVPloadedEvent,
  RSVPloadingEventFailed,
  RSVPevents,
  RSVPeventsOrgName,
  RSVPeventsOrgDetails,
  RSVPEventDetails,
  RSVPJoining,
  RSVPJoined,
  RSVPJoinedError,
}) => ({
  RSVPloadingEvents,
  RSVPloadedEvents,
  RSVPloadingEventsFailed,
  RSVPloadingEvent,
  RSVPloadedEvent,
  RSVPloadingEventFailed,
  RSVPevents,
  RSVPeventsOrgName,
  RSVPeventsOrgDetails,
  RSVPEventDetails,
  RSVPJoining,
  RSVPJoined,
  RSVPJoinedError,
});
