import React, { Fragment, useState } from "react";

import appStoreBadge from "../../../../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import * as Tools from "../../../adminstration/components/Tools";

const SignUpSummary = ({ summaryInfo, eventName }) => {
  return (
    <div className="RSVP-event-body-column ">
      <div className="RSVP-sign-up-summary">
        <div className="col">
          <div>
            <h1 className="title">You’re going to {eventName}!</h1>
          </div>
          <div>
            <h3 className="subtitle">Reservation Details</h3>
          </div>
          <p>{summaryInfo.name}</p>
          {summaryInfo.attendees === 1 ? (
            <p>{summaryInfo.attendees} attendee</p>
          ) : (
            <p>{summaryInfo.attendees} attendees</p>
          )}
          <p>{summaryInfo.email}</p>
          {summaryInfo.phoneNumber && <p>{summaryInfo.phoneNumber}</p>}
          <div className="col footer-tip">
            <h3>
              Plans changed? Modify or cancel your reservation using Vanco
              Mobile.
            </h3>
          </div>
          <div className="col">
            <div className="badges-container">
              <a
                href="https://apps.apple.com/us/app/id1504961674"
                className="badge-link"
              >
                <img
                  src={appStoreBadge}
                  alt="Get it on App Store"
                  className="store-badge"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.vancopayments.vancomobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                className="badge-link"
              >
                <img
                  alt="Get it on Google Play"
                  className="store-badge google-play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
          <div className="col footer-download-suggestion">
            <h3>
              Download the Vanco Mobile app to keep track of your event sign
              ups, edit your response, and stay connected to your faith
              community.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSummary;
