import CryptoJS from "crypto-js"
import { toast } from "react-toastify";

import { PAYER_API_V1 } from "../index";
import * as actionConstants from '../actionConstants';
import { PROCESSOR_API } from '../index'

toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
})

export const setGiveIDSIsValidToken = (payload) => (dispatch) => {
    dispatch({ type: actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN, payload });
};

export const clearGiveIDSIsValidToken = () => (dispatch) => {
    dispatch({ type: actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN, payload: null });
};

export const setGiveIDSIsValidTokenLoading = () => (dispatch) => {
    dispatch({ type: actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN_LOADING });
};

export const setGiveIDSIsValidTokenLoaded = () => (dispatch) => {
    dispatch({ type: actionConstants.SET_GIVE_IDS_IS_VALID_TOKEN_LOADED });
};

export const checkIfIsValidPaymentToken = (token, paymentInfo, gg, cb) => async (dispatch, getState) => {
    let decryptedInfo = {}

    try {

        let { isValidTokenLoading } = getState().giveIDS
        if (isValidTokenLoading) return
        dispatch(setGiveIDSIsValidTokenLoading())
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        let decodedPaymentInfo = decodeURIComponent(paymentInfo)
        token = decodeURIComponent(token)


        var bytes = CryptoJS.AES.decrypt(decodedPaymentInfo, token);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);

        decryptedInfo = JSON.parse(originalText)
        let { location, brand, last4, uuid, campaingId, authorization, paymentMethodType, accountType, paymentItems } = decryptedInfo
        const campaignIds = paymentItems ? [...new Set(paymentItems.map(i => i.campaingId))] : [campaingId];
        console.log(decryptedInfo)
        document.cookie = ""

        let headers = {
            headers: {
                "Cookieless-Request": true,
            }
        }

        if (!gg) {
            headers.headers['authorization'] = "Bearer " + authorization
        }

        //? creates a VO payment token 
        let resCreatePaymentMethod = await PAYER_API_V1.post(`api/payments`, {
            "data": {
                "location": {
                    "id": location
                }
            }
        }, headers)
        let vpcampaignspaymentheader = resCreatePaymentMethod.headers["vp-campaigns-payment-header"]

        let tokenCreatePaymentMethod = resCreatePaymentMethod.data.data.payment.id

        headers = {
            headers: {
                "Cookieless-Request": true,
                "vp-campaigns-payment-header": vpcampaignspaymentheader
            }
        }

        if (!gg) {
            headers.headers['authorization'] = "Bearer " + authorization
        }

        let resSavePaymentMethod = await PAYER_API_V1.put(`api/payments/${tokenCreatePaymentMethod}/paymentMethods`, {
            "data": {
                brand,
                last4,
                paymentMethodToken: token,
                paymentMethodType,
                uuid,
                accountType
            }
        }, headers)
        vpcampaignspaymentheader = resSavePaymentMethod.headers["vp-campaigns-payment-header"]

        let resIsValid = await PAYER_API_V1.get(`api/payments/${tokenCreatePaymentMethod}/IsValid`, headers)
        let locationCampaigns = await PAYER_API_V1.get(`api/campaigns/?location=${location}`, headers)
        const includedCampaigns = locationCampaigns.data.data.filter((campaign) => campaignIds.includes(campaign.id));
        if(includedCampaigns.length < campaignIds.length) throw new Error('an invalid campaign was passed')
        //if paymentItems were not sent, it means it's a legacy single item transaction, and we have to adapt it for the new flow
        if(!paymentItems) {
            paymentItems = [{
                amount: decryptedInfo.amount,
                showAmount: decryptedInfo.showAmount,
                campaingId: decryptedInfo.campaingId,
                frequency: decryptedInfo.frequency,
                startDate: decryptedInfo.startDate,
                endDate: decryptedInfo.endDate,
                textFieldTitle: decryptedInfo.textFieldTitle,
                memoLine: decryptedInfo.memoLine,
                campaignType: decryptedInfo.campaignType,
                campaignQty: decryptedInfo.campaignQty,
            }]
        }
        paymentItems && paymentItems.forEach(i => {
            i.campaign = includedCampaigns.find( j=> i.campaingId === j.id);
        })
        cb && cb({ ...decryptedInfo, paymentItems, campaigns:includedCampaigns, paymentToken: tokenCreatePaymentMethod, vpcampaignspaymentheader })
        dispatch(setGiveIDSIsValidTokenLoaded())

    } catch (error) {

        if (error.response) {
            switch (error.response.data.message) {
                case "Cookie length: 4160 exceeds the max length allowed: 4096":
                    cb && cb(decryptedInfo, "An error occurred gathering the data. (Error code 500)")
                    break;
                default:
                    cb && cb(decryptedInfo, "An unknown error occurred while processing the payment. Please try again later. (Error code 500)")
                    break;
            }
        }
        else {
            toast.error("❌ " + error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        dispatch(setGiveIDSIsValidTokenLoaded())
    }
}
export const makePayment = (paymentInfo, cb) => async (dispatch, getState) => {
    try {
        let { paymentToken, notificationEmail, reCaptchaToken, vpcampaignspaymentheader, startDate, endDate, showAmount, amount, frequency, includeProcessingFee, campaingId, authorization, memoLine, gg, campaignQty, campaignType } = paymentInfo
        let resMakePayment = null
        let formattedFrequency = frequency

        switch (frequency) {
            case "Once, today":
                formattedFrequency = "OneTimeNow"
                break;
            case "Once, on a future date":
                formattedFrequency = "OneTimeFuture"
                break;
            case "Every week":
                formattedFrequency = "Weekly"
                break;
            case "Every two weeks":
                formattedFrequency = "BiWeekly"
                break;
            case "Twice a month (1st and 15th)":
                formattedFrequency = "BiMonthly"
                break;
            case "Monthly":
                formattedFrequency = "Monthly"
                break;       
            case "Quarterly":
                formattedFrequency = "Quarterly"
                break;    
            case "Annually":
                formattedFrequency = "Annually"
                break;    
            default:
                formattedFrequency = "OneTimeNow"
        }
        console.log('====================================');
        console.log(paymentInfo);
        console.log('====================================');

        let headers = {
            headers: {
                "Cookieless-Request": true,
                "vp-campaigns-payment-header": vpcampaignspaymentheader
            }
        }

        if (!gg) {
            headers.headers['authorization'] = "Bearer " + authorization
        }

        let postData = {
            "data": {
                execute: true,
                notificationEmail,
                reCaptchaToken,
                paymentItems: [{
                    amount: amount ? amount : showAmount,
                    frequency: formattedFrequency,
                    includeProcessingFee,
                    campaign: {
                        id: campaingId
                    },
                    memoLine
                }]
            }
        }

        if (frequency && formattedFrequency != "OneTimeNow" && startDate) {
            postData.data.paymentItems[0].startDate = startDate
            postData.data.paymentItems[0].paymentDate = startDate
            postData.data.paymentItems[0].endDate = endDate
            resMakePayment = await PAYER_API_V1.put(`api/payments/${paymentToken}`, postData, headers)
        }
        else if (paymentInfo.paymentOptions) {
            postData.data.paymentItems[0].itemAmount = paymentInfo.paymentOptions.price
            postData.data.paymentItems[0].quantity = campaignQty
            resMakePayment = await PAYER_API_V1.put(`api/payments/${paymentToken}`, postData, headers)
        }
        else {
            resMakePayment = await PAYER_API_V1.put(`api/payments/${paymentToken}`, postData, headers)
        }


        cb && cb()
    } catch (error) {
        console.log(error.response);
        if (error.response) {
            switch (error.response.data.message) {
                case "Cookie length: 4160 exceeds the max length allowed: 4096":
                    cb && cb(null, "An error occurred gathering the data. (Error code 500)")
                    break;
                case "Duplicate Transaction":
                    cb && cb(null, "This payment has the same amount, frequency, and account number as a previous payment for the same item. To process this payment, please change the amount, frequency, or use a different payment method.")
                    break
                default:
                    cb && cb(null, "An unknown error occurred while processing the payment. Please try again later. (Error code 500)")
                    break;
            }
        }
        else {
            toast.error("❌ " + error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
}

export const makeBasketPayment = (paymentInfo, cb) => async (dispatch, getState) => {
    try {
        let { uuid, paymentToken, notificationEmail,includeProcessingFee, reCaptchaToken, vpcampaignspaymentheader,  gg, campaignQty, campaignType, authorization, name, paymentId, location} = paymentInfo;
        let resMakePayment = null

        let headers = {
            headers: {
                "Cookieless-Request": true,
                "vp-campaigns-payment-header": vpcampaignspaymentheader
            }
        }

        if (!gg) {
            headers.headers['authorization'] = "Bearer " + authorization
        }

        let postData = {
            "data": {
                execute: true,
                notificationEmail,
                reCaptchaToken,
                paymentItems: paymentInfo.paymentItems.map(item => {
                    const {
                      startDate,
                      endDate,
                      showAmount,
                      amount,
                      frequency,
                      memoLine,
                      campaignQty
                    } = item;
                    let dateOptions = {}
                    let paymentOptions = {}
                    if (frequency && frequency != "OneTimeNow" && startDate) {
                        dateOptions.startDate = startDate
                        dateOptions.paymentDate = startDate
                        dateOptions.endDate = endDate
                    }
                    else if (item.campaign.paymentOptions) {
                        paymentOptions.itemAmount = item.campaign.paymentOptions.price
                        paymentOptions.quantity = campaignQty
                    }
                    return ({
                        amount: amount ? amount : showAmount,
                        frequency: frequency,
                        includeProcessingFee,
                        campaign: {
                            id: item.campaign.id
                        },
                        memoLine,
                        ...dateOptions,
                        ...paymentOptions
                    })
                }) 
            }
        }
        if(uuid && uuid.includes('guest')){
            postData.data.user = {
                id: uuid,
                username: notificationEmail,
                email: notificationEmail,
                givenName: name,
            }
        }
        if (paymentId) {
            resMakePayment = await PAYER_API_V1.put(`/api/users/locations/${location}/scheduledpayments/${paymentId}?paymentId=${paymentInfo.paymentToken}`, postData, headers);
        } else {
            resMakePayment = await PAYER_API_V1.put(`api/payments/${paymentToken}`, postData, headers);
        }
        cb && cb()
    } catch (error) {
        console.log(error.response);
        if (error.response) {
            switch (error.response.data.message) {
                case "Cookie length: 4160 exceeds the max length allowed: 4096":
                    cb && cb(null, "An error occurred gathering the data. (Error code 500)")
                    break;
                case "Duplicate Transaction":
                    cb && cb(null, "This payment has the same amount, frequency, and account number as a previous payment for the same item. To process this payment, please change the amount, frequency, or use a different payment method.")
                    break
                default:
                    cb && cb(null, "An unknown error occurred while processing the payment. Please try again later. (Error code 500)")
                    break;
            }
        }
        else {
            toast.error("❌ " + error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
}

export const createPaymentMethodAch = ({ accountNumber, email, routingNumber, authorization, pcct, accountType, postalCode = '12345', state=null, city='', firstName, lastName, isSaved, address=' ', addressInternal, name, gg = false, }, cb) => async (dispatch, getState) => {
    try {
        const headers = {
            "pcct": pcct,
            "authorizationtype": "IDS"
        }
        const body = {
            accountNumber,
            accountType,
            address: {
                postalCode,
                addressLine1: address,
                addressLine2: addressInternal,
                state,
                city,
            },
            firstName,
            lastName,
            routingNumber,
            isSaved,
            email,
        };

        if(gg){
            body.partnerPayerRef = authorization;
        }
        else {
            headers["Authorization"] = "Bearer " + authorization;
        }

        let resPayment = await PROCESSOR_API.post(`tokenize`, body, {
            headers,
            withCredentials: false
        })

        cb && cb(resPayment)
    } catch (error) {
        cb({error})
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ tokenError: true, error: error }))
    }
}

export const createPaymentMethodAchV2 = ({
    accountNumber,
    email,
    routingNumber,
    authorization,
    pcct,
    accountType,
    isSaved,
    name,
    firstName,
    lastName,
    streetName,
    city,
    state,
    phoneNumber,
    zip = "12345" }, cb) => async (dispatch, getState) => {
        try {
            let resPayment = null
            if (isSaved) {
                resPayment = await PROCESSOR_API.post(`tokenize`, {
                    accountNumber,
                    accountType,
                    address: {
                        postalCode: zip,
                        addressLine1: streetName,
                        state,
                        city,
                    },
                    firstName: firstName,
                    lastName: lastName,
                    routingNumber,
                    isSaved: true,
                    email,
                }, {
                    headers: {
                        "pcct": pcct,
                        "Authorization": "Bearer " + authorization,
                        "authorizationtype": "IDS"
                    },
                    withCredentials: false
                })
            }
            else {
                resPayment = await PROCESSOR_API.post(`tokenize`, {
                    accountNumber,
                    accountType,
                    address: {
                        postalCode: zip,
                        addressLine1: "1",
                        state: "IN",
                        city: "1"
                    },
                    firstName: name,
                    lastName: name,
                    routingNumber,
                    isSaved,
                    email,
                }, {
                    headers: {
                        "pcct": pcct,
                        "Authorization": "Bearer " + authorization,
                        "authorizationtype": "IDS"
                    },
                    withCredentials: false
                })
            }
            cb && cb(resPayment)
        } catch (error) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ tokenError: true, error: error }))
        }
    }

export const updatePaymentMethodBank = ({
    authorization,
    paymentMethodToken,
    org,
    addressLine1,
    city,
    state,
    postalCode = "12345",
    pcct }, cb) => async (dispatch, getState) => {
        try {
            let resPayment = null
            let resPaymentUpdate = await PROCESSOR_API.post(`partners/${org}/paymentMethods/${paymentMethodToken}`, {
                postalCode,
                addressLine1,
                state,
                city
            }, {
                headers: {
                    "pcct": pcct,
                    "Authorization": "Bearer " + authorization,
                    "authorizationtype": "IDS"
                },
                withCredentials: false
            })
            
            cb && cb(resPaymentUpdate)
        } catch (error) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ tokenError: true, error: error }))
        }
    }