import React, { Fragment } from "react";

import logo from "../../../../img/vanco-white.png";

const NavBar = ({ title, imageUri }) => {
  return (
    <div className={"RSVP-navbar"}>
      <div className="RSVP-topbar-container">
        <div className="RSVP-topbar-left">
          <img alt="Logo" src={imageUri || logo} className="topbar-logo" />
          <h1 className="topbar-title">{title}</h1>
        </div>
        {/* <div className="topbar-right col-md-6">
          <a onClick={onClickSignIn}>SIGN IN</a>
        </div> */}
      </div>
    </div>
  );
};

export default NavBar;
