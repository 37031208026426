import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import RSVPEvent from "./RSVPEvent";
import * as actions from "../../../../actions/RSVPEventsActions";
import { getRSVPevents } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
  ...getRSVPevents(state),
});

const RSVPEventRouter = withRouter(RSVPEvent);
export default connect(mapStateToProps, actions)(RSVPEventRouter);
