import React, { PureComponent } from "react";
import "../css/congregate-auth.scss";
import logo from "../img/vanco-white.png";
import Footer from "../apps/adminstration/components/Footer";

/**
 * The template for all the auth routes
 */
class RegistrationTemplate extends PureComponent {
  render() {
    return (
      <div>
        <div className="container-fluid main-page">
          <div className="col-xs-12 logo-holder">
            <img alt="Logo" src={logo} style={{ width: 60, height: 60 }} />
          </div>
          <div className="col-xs-12 col-md-6 col-md-push-3 content-holder">
            {this.props.children}
          </div>
          <div className="clearfix" />

          <Footer />
        </div>
      </div>
    );
  }
}

export default RegistrationTemplate;
