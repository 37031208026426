import { Pause } from '@material-ui/icons';
import firebase from 'firebase/compat/app';
import { withAttributes } from 'js-cookie';
import * as actionConstants from '../actionConstants';
import { falseUsers } from '../falseUsersSource';
import { PAYER_API_V1 } from '../index';

const moment = require('moment');

export const startingMetricCounts = () => dispatch => {
  dispatch({ type: actionConstants.STARTING_METRIC_COUNTS });
};

/**
 * Load all donations by organization
 *
 * @returns {function(*, *)}
 */
export const getUsersOrgCounts = () => (dispatch, getState) => {
  firebase.firestore().collection('users').get()
    .then((querySnapshot) => {
      let usersNoOrg = 0;
      let usersWithOrg = 0;
      querySnapshot.forEach((doc) => {
        if (doc.data().organizations && Object.keys(doc.data().organizations).length === 0) {
          usersNoOrg += 1;
        }
        if (doc.data().organizations && Object.keys(doc.data().organizations).length > 0) {
          usersWithOrg += 1;
        }
      });
      dispatch({ type: actionConstants.GOT_METRIC_USERS_ORG_COUNTS, usersNoOrg, usersWithOrg });
    });
};

export const updateOrgCounts = () => dispatch => {
  let messageCount = 0;
  let promises = [];
  firebase.firestore().collection('organizations').doc('BuVB5tbVdedQf6POjE1V').get()
    .then(doc => {
      if (doc.exists) {
        firebase.firestore().collection('groups').where('organizationId', '==', doc.id).get()
          .then(querySnapshot => {
            const groupCount = querySnapshot.size;
            querySnapshot.forEach(async (groupDoc) => {
              promises.push(firebase.firestore().collection('groups').doc(groupDoc.id).collection('messages').get())
            });
            Promise.all(promises)
              .then(querySnapshots => {
                querySnapshots.forEach(querySnapshot => {
                  messageCount += querySnapshot.size;
                });

                firebase.firestore().collection('organizations').doc(doc.id).update({
                  groupCount,
                  messageCount
                });
              });
          });
      }
    });
};

export const getInviteCounts = () => dispatch => {
  firebase.firestore().collection('groupInvites').get()
    .then(querySnapshot => {
      const inviteCount = querySnapshot.size;
      dispatch({ type: actionConstants.GOT_METRIC_INVITE_COUNTS, inviteCount });
    })
}

export const getUserCounts = () => dispatch => {
  let both = 0;
  let email = 0;
  let phone = 0;
  firebase.firestore().collection('users').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        if (!doc.data().customerSupport) {
          if (doc.data().phoneNumber && doc.data().email) {
            both += 1;
          } else if (doc.data().phoneNumber) {
            phone += 1;
          } else if (doc.data().email) {
            email += 1;
          }
        }
      });
      dispatch({ type: actionConstants.GOT_METRIC_USERS_COUNTS, payload: { both, phone, email} });
    });
};

export const getPhoneNumberOnlyUserDetails = () => dispatch => {
  let hasOrg = 0;
  let hasExtraGroup = 0;
  let users = [];
  let usersWithOrg = [];
  let usersWithExtraGroup = [];
  let orgs = [];
  firebase.firestore().collection('users').get()
    .then(querySnapshot => {
      querySnapshot.forEach(async doc => {
        if (!doc.data().customerSupport) {
          if (!doc.data().email && doc.data().phoneNumber) {
            users.push({id: doc.id, ...doc.data()})
            if (doc.data().organizations && Object.keys(doc.data().organizations).length > 0) {
              hasOrg += await firebase
                .firestore()
                .collection('organizations')
                .doc(Object.keys(doc.data().organizations)[0])
                .get()
                .then(orgdoc => {
                  if (orgdoc.exists && orgdoc.data().status === 'Active' && orgdoc.data().clientId.toLowerCase() !== 'vanco_demo') {
                    usersWithOrg.push({id: doc.id, ...doc.data()})
                    orgs.push({id: orgdoc.id, clientId: orgdoc.data().clientId, name: orgdoc.data().name})
                  }
                })
            }
            if (doc.data().groups && Object.keys(doc.data().groups).length > 1) {
              usersWithExtraGroup.push({id: doc.id, ...doc.data()})
            }
          }
        }
      });
      console.log(hasOrg);
      console.log(hasExtraGroup);
      console.log(users);
      console.log(usersWithOrg);
      console.log(usersWithExtraGroup);
      console.log(orgs);
      //dispatch({ type: actionConstants.GOT_METRIC_USERS_COUNTS, payload: { both, phone, email} });
    });
};

// Stats
// this.props.userCreatedCountByMonth();
// this.props.userGroupCountByMonth();
// this.props.eventCountByMonth();
// this.props.userChatCountByMonth();

export const getAllGroupsByNameCount = () => dispatch => {
  dispatch({ type: actionConstants.GETTING_METRIC_GROUPS_WITH_COUNT });
  firebase
    .firestore()
    .collection('groups')
    .get()
    .then(querySnapshot => {
      let groups = [];
      querySnapshot.docs.map(doc => {
        if (doc.exists) {
          if (doc.data().users && Object.keys(doc.data().users).length > 0 && doc.data().name) {
            groups.push(doc.data().name);
          }
        }
      });
      let uniqueGroups = [];
      groups.forEach(group => {
        if (uniqueGroups.indexOf(group) === -1) {
          uniqueGroups.push(group);
        }
      });
      let groupsCount = uniqueGroups.map(group => {
        return {
          name: group,
          count: groups.filter(value => value === group).length
        };
      });
      groupsCount = groupsCount.filter(group => group.count > 1).sort((a, b) => b.count - a.count);
      dispatch({ type: actionConstants.GOT_METRIC_GROUPS_WITH_COUNT, payload: groupsCount });
    });
};

export const getAllEventsByNameCount = () => dispatch => {
  dispatch({ type: actionConstants.GETTING_METRIC_EVENTS_WITH_COUNT });
  firebase
    .firestore()
    .collection('events')
    .get()
    .then(querySnapshot => {
      let events = [];
      querySnapshot.docs.map(doc => {
        if (doc.exists) {
          if (doc.data().name && ((doc.data().recurring && doc.data().initialEvent === doc.id) || (!doc.data().recurring))) {
            events.push(doc.data().name);
          }
        }
      });
      let uniqueEvents = [];
      events.forEach(event => {
        if (uniqueEvents.indexOf(event) === -1) {
          uniqueEvents.push(event);
        }
      });
      let eventsCount = uniqueEvents.map(event => {
        return {
          name: event,
          count: events.filter(value => value === event).length
        };
      });
      eventsCount = eventsCount.filter(event => event.count > 1).sort((a, b) => b.count - a.count);
      dispatch({ type: actionConstants.GOT_METRIC_EVENTS_WITH_COUNT, payload: eventsCount });
    });
};

export const eventCountByMonth = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('events')
      .get()
      .then(querySnapshot => {
        let counts = {};
        querySnapshot.forEach(doc => {
          if (doc.data().createdAt) {
            console.log(doc.id)
            if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
              counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
            } else {
              counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
            }
          }
        })
        console.log(counts)
      })
  }
}

export const groupCountByMonth = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('groups')
      .get()
      .then(querySnapshot => {
        let counts = {};
        querySnapshot.forEach(doc => {
          if (doc.data().createdAt) {
            if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
              counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
            } else {
              counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
            }
          }
        })
        console.log(counts)
      })
  }
}

export const userCreatedCountByMonth = () => {
  let limit = 1000;
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .where('email', '!=', null)
      .limit(limit)
      .get()
      .then(async (querySnapshot) => {
        let counts = {};
        querySnapshot.forEach(doc => {
          if (doc.data().createdAt) {
            if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
              counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
            } else {
              counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
            }
          }
        })
        if (querySnapshot.size === limit) {
          counts = await getUsers(counts, querySnapshot.docs[querySnapshot.docs.length -1])
        }
        console.log(counts)
      })
  }
}

const getUsers = (counts, lastDoc) => {
  let limit = 1000;
  return firebase
  .firestore()
  .collection('users')
  .where('email', '!=', null)
  .limit(limit)
  .startAt(lastDoc)
  .get()
  .then(async (querySnapshot) => {
    querySnapshot.forEach(doc => {
      if (doc.data().createdAt) {
        if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
          counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
        } else {
          counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
        }
      }
    })
    if (querySnapshot.size === limit) {
      counts = await getUsers(counts, querySnapshot.docs[querySnapshot.docs.length -1])
    }
    return counts
  })
}

export const userGroupCountByMonth = () => {
  let limit = 1000
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .where('email', '!=', null)
      .limit(limit)
      .get()
      .then(async (querySnapshot) => {
        let counts = {};
        querySnapshot.forEach(doc => {
          if (doc.data().createdAt && doc.data().groups && Object.keys(doc.data().groups).length > 0) {
            if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
              counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
            } else {
              counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
            }
          }
        })
        if (querySnapshot.size === limit) {
          counts = await getUsersWithGroup(counts, querySnapshot.docs[querySnapshot.docs.length -1])
        }
        console.log(counts)
      })
  }
}

const getUsersWithGroup = (counts, lastDoc) => {
  let limit = 1000;
  return firebase
  .firestore()
  .collection('users')
  .where('email', '!=', null)
  .limit(limit)
  .startAt(lastDoc)
  .get()
  .then(async (querySnapshot) => {
    querySnapshot.forEach(doc => {
      if (doc.data().createdAt && doc.data().groups && Object.keys(doc.data().groups).length > 0) {
        if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
          counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
        } else {
          counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
        }
      }
    })
    if (querySnapshot.size === limit) {
      counts = await getUsersWithGroup(counts, querySnapshot.docs[querySnapshot.docs.length -1])
    }
    return counts
  })
}

export const userChatCountByMonth = () => {
  let limit = 1000;
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .where('email', '!=', null)
      .limit(limit)
      .get()
      .then(async (querySnapshot) => {
        let counts = {};
        querySnapshot.forEach(doc => {
          if (doc.data().createdAt && doc.data().chats && Object.keys(doc.data().chats).length > 0) {
            if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
              counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
            } else {
              counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
            }
          }
        })
        if (querySnapshot.size === limit) {
          counts = await getUsersWithChat(counts, querySnapshot.docs[querySnapshot.docs.length -1])
        }
        console.log(counts)
      })
  }
}

const getUsersWithChat = (counts, lastDoc) => {
  let limit = 1000;
  return firebase
  .firestore()
  .collection('users')
  .where('email', '!=', null)
  .limit(limit)
  .startAt(lastDoc)
  .get()
  .then(async (querySnapshot) => {
    querySnapshot.forEach(doc => {
      if (doc.data().createdAt && doc.data().chats && Object.keys(doc.data().chats).length > 0) {
        if (!counts[moment(doc.data().createdAt).format('Y-MM')]) {
          counts[moment(doc.data().createdAt).format('Y-MM')] = 1;
        } else {
          counts[moment(doc.data().createdAt).format('Y-MM')] = counts[moment(doc.data().createdAt).format('Y-MM')] + 1;
        }
      }
    })
    if (querySnapshot.size === limit) {
      counts = await getUsersWithChat(counts, querySnapshot.docs[querySnapshot.docs.length -1])
    }
    return counts
  })
}

export const repairLastOrgValues = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .orderBy('lastOrg')
      .startAt("O")
      .endAt("P")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().lastOrg && doc.data().lastOrg.length < 7) {
            console.log(doc.id)
            console.log(doc.data().lastOrg)
            console.log(Object.keys(doc.data().admins)[0])
            console.log(Object.keys(doc.data().organizations)[0])
            if (Object.keys(doc.data().admins)[0]) {
              doc.ref.update({
                lastOrg: Object.keys(doc.data().admins)[0]
              })
            } else if (Object.keys(doc.data().organizations)[0]) {
              doc.ref.update({
                lastOrg: Object.keys(doc.data().organizations)[0]
              })
            }
            console.log('-----')
          }
        })
        //console.log(counts)
      })
  }
}

export const removeFalseUsersFromOrg = () => {
  let promises = []
  falseUsers.map(user => {
    promises.push(
      firebase
      .firestore()
      .collection('organizations')
      .where('clientId', '==', user['Client ID'])
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size === 1) {
          let doc = querySnapshot.docs[0]
          doc.ref.collection('users').where('email', '==', user['Contact Email']).get()
          .then(querySnapshot => {
            if (querySnapshot.size > 0) {
              let userDoc = querySnapshot.docs[0]
              console.log(doc.data().clientId, userDoc.data().email)
              userDoc.ref.delete()
            }
          })
        }
      })
    )
  })
  Promise.all(promises).then(data => console.log('end'))
}

export const removeOrgsFromFalseUsers = () => {
  let promises = []
  falseUsers.map(user => {
    promises.push(
      firebase
      .firestore()
      .collection('organizations')
      .where('clientId', '==', user['Client ID'])
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size === 1) {
          let doc = querySnapshot.docs[0]
          let orgId = doc.id
          firebase.firestore().collection('users').where('email', '==', user['Contact Email']).get()
          .then(querySnapshot => {
            if (querySnapshot.size > 0) {
              let userDoc = querySnapshot.docs[0]
              console.log(userDoc.data().organizations[orgId], userDoc.data().email)
              userDoc.ref.update({
                [`organizations.${orgId}`]: firebase.firestore.FieldValue.delete(),
                [`admins.${orgId}`]: firebase.firestore.FieldValue.delete(),
              })
            }
          })
        }
      })
    )
  })
  Promise.all(promises).then(data => console.log('end'))
}

export const updateOrgLocationIds = () => (dispatch) => {
  let count = 0;
  let timeout = 0;
  firebase
    .firestore()
    .collection("organizations")
    .where('myVancoOrgId', '>', '0')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        if (doc && doc.data() && doc.data().myVancoOrgId && !doc.data().locationId) {
          timeout = timeout + 200
          setTimeout(async () => {
            await PAYER_API_V1.get(`/api/organizations/${doc.data().myVancoOrgId}?include=mobilelocations`)
              .then(async orgResponse => {
                if (orgResponse && orgResponse.data && orgResponse.data.included.length > 0 && orgResponse.data.included[0].locations.length > 0) {
                  console.log(doc.data().myVancoOrgId + ' - ' + orgResponse.data.included[0].locations[0].id)
                  let locationId = orgResponse.data.included[0].locations[0].id
                  await doc.ref.update({
                    locationId: locationId
                  })
                  count = count + 1
                }
              })
              .catch(error => console.log(error))
          }, timeout)
        }
      });
      return true;
    })
    .catch((error) => console.log(error))
}