import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import HexeaAch from "./HexeaAch";
import * as actions from "../../../../actions/giveIDSActions";
import { getGiveIDSReducer } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
    ...getGiveIDSReducer(state),
});

const HexeaAchRouter = withRouter(HexeaAch);
export default connect(mapStateToProps, actions)(HexeaAchRouter);
