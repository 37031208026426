import React, { useEffect } from "react";

const AppLink = (props) => {
  const searchQuery = props.location.search;
  const buildLink = () => {
    return `${global.BASE_DEEP_LINK}/?link=${global.VANCOMOBILE_BASEURL}/error-phone-link/${searchQuery}&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
  };
  useEffect(() => {
    console.log(searchQuery);
    window.location.href = buildLink();
  }, []);

  return (
    <div className="IDS-login-container" style={{ textAlign: "center" }}>
      <p>
        If you're not redirected to the app, please use{" "}
        <a href={buildLink()}>this link</a>
      </p>
    </div>
  );
};
export default AppLink;
