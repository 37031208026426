/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { checkStatusPage, checkStatusPageResponse } from '../../components/Tools';

const moment = require('moment');

class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incidents: [],
    };
  }

  /**
   * Clear down the entire redux state
   */
  async UNSAFE_componentWillMount() {
    const statusPageResponse = await checkStatusPage();
    checkStatusPageResponse(statusPageResponse, (data) => this.setState({ incidents: data.data }), () => window.location.href = '/admin');
  }

  renderMessages() {
    return this.state.incidents.map(message => this.renderMessage(message));
  }

  renderMessage(issue) {
    return issue.incident_updates.map(incidentUpdate => {
      return (
        <div key={incidentUpdate.id} style={{ marginBottom: 20 }}>
          <p style={{ textAlign: 'center', marginBottom: 8, fontWeight: 'bold' }}>{ moment(incidentUpdate.created_at).format('M-D-Y h:mm a') }</p>
          <p style={{ textAlign: 'center' }}>
            {incidentUpdate.body}
          </p>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <h1>Vanco Mobile Status</h1>
        { this.renderMessages() }
      </div>
    );
  }
}

export default Status;
