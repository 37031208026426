import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomInput from "../CustomInput/CustomInput";
import ActivitySpinner from "../../../adminstration/components/ActivitySpinner";
import * as Tools from "../../../adminstration/components/Tools";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const SignUpForm = ({
  orgDetails,
  termsAndConditions,
  toggleTermsAndConditions,
  onClickSignUp,
  additionalGuests,
  availableTickets,
  hasAttendeeLimit,
  eventId,
  loading,
  orgId,
  error,
  attendeeLimit,
  country = "US",
}) => {
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(),
    attendes: Yup.number().required().min(1),
    email: Yup.string().email().required(),
  });

  return (
    <div className="RSVP-event-body-column ">
      <div className="RSVP-sign-in">
        {error && <h3>Something went wrong. Try again.</h3>}
        <div>
          <h3>Event sign up</h3>
          {/* <h3 style={{fontWeight:'bold', marginBottom: 10}}>Event sign up</h3> */}
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors }) => {
              let { name, attendes, email, phoneNumber } = values;
              if (phoneNumber) {
                if (Tools.isValidNumber(phoneNumber, country)) {
                  setErrors("phoneNumber", false);
                } else {
                  setErrors({ phoneNumber: "true" });
                  return;
                }
              }
              onClickSignUp(name, attendes, email, phoneNumber, eventId, orgId);
            }}
            initialValues={{
              name: "",
              attendes: 1,
              email: "",
              phoneNumber: "",
            }}
          >
            {({
              handleBlur,
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              setFieldTouched,
              isValid,
              dirty,
            }) => (
              <fieldset disabled={loading}>
                <div>
                  {/* <h3 className="title">
                    Your full name <p className="required">*</p>
                  </h3> */}
                  <CustomInput
                    value={values.name}
                    focusColor={orgDetails.primaryColor}
                    placeholder="Full name (required)"
                    pattern={/^$|^[a-z ,.'-]+$/gim}
                    error={errors.name && touched.name}
                    onChange={(e) => {
                      setFieldTouched("name");
                      handleChange("name")(e);
                    }}
                  />
                </div>
                {/* <div style={{position:'relative'}}> */}
                <div >
                  {/* <h3 className="title">
                    Total attendees <p className="required">*</p>
                  </h3> */}
                  {/* <p style={{marginLeft:11, fontSize:12, top:-6, backgroundColor:'white', width:'auto',position:'absolute', zIndex:10, paddingLeft:4, paddingRight:4}}>Total attendees</p> */}
                  <CustomInput
                    value={values.attendes}
                    focusColor={orgDetails.primaryColor}
                    placeholder="Attendes"
                    error={errors.attendes}
                    dropdown
                    additionalGuests={additionalGuests}
                    availableTickets={availableTickets}
                    onChange={handleChange("attendes")}
                    hasAttendeeLimit={hasAttendeeLimit}
                    attendeeLimit={attendeeLimit}
                  />
                </div>
                <div>
                  {/* <h3 className="title">
                    Your email address <p className="required">*</p>
                  </h3> */}
                  <CustomInput
                    value={values.email}
                    focusColor={orgDetails.primaryColor}
                    placeholder="Email address (required)"
                    type="email"
                    error={errors.email && touched.email}
                    onChange={(e) => {
                      setFieldTouched("email");
                      handleChange("email")(e);
                    }}
                  />
                </div>
                <div>
                  {/* <h3 className="title">
                    Your phone number (mobile suggested)
                  </h3> */}
                  <CustomInput
                    value={values.phoneNumber}
                    focusColor={orgDetails.primaryColor}
                    placeholder="Phone number (mobile suggested)"
                    // pattern={/^(\s*|\d+)$/gim}
                    error={
                      (errors.phoneNumber && touched.phoneNumber) ||
                      (!phoneNumberValid && touched.phoneNumber)
                    }
                    onChange={(e) => {
                      e = Tools.formatPhoneAsYouType(e);
                      if (e) {
                        if (Tools.isValidNumber(e, country)) {
                          setPhoneNumberValid(true);
                        } else {
                          setPhoneNumberValid(false);
                        }
                      } else {
                        setPhoneNumberValid(true);
                      }
                      setFieldTouched("phoneNumber");
                      handleChange("phoneNumber")(e);
                    }}
                  />
                </div>
                <div
                  className="col mt-1 checkbox-custom"
                  onClick={toggleTermsAndConditions}
                >
                <div className={"checkbox-custom-check"} style={{borderColor:termsAndConditions ? orgDetails.primaryColor : ''}}>
                  {termsAndConditions && <CheckBoxIcon className="checkbox-custom-check-selected-icon" style={{color:orgDetails.primaryColor, fontSize:20}} />}
                </div>
                  <p onClick={toggleTermsAndConditions}>
                    I agree to the{" "}
                    <a
                      href={`${window.VANCOPAYMENTS_BASEURL}/terms-and-conditions`}
                      target="_blank"
                      style={{color:orgDetails.primaryColor}}
                      rel="noopener noreferrer"
                    >
                      Terms &amp; Conditions
                    </a>
                  </p>
                </div>
                <div className="col mt-2 center-content">
                  {loading ? (
                    <ActivitySpinner />
                  ) : (
                    <input
                      className="btn btn-info RSVP-event-item-submit-button sign-up" 
                      style={{borderColor:orgDetails.primaryColor, backgroundColor:orgDetails.primaryColor}}
                      type="submit"
                      disabled={
                        !(isValid && dirty && termsAndConditions) ||
                        loading ||
                        !availableTickets
                      }
                      value="Sign Up"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </fieldset>
            )}
          </Formik>
          {/* <div className="col mt-2 center-content">
            <p>
              Already have a Vanco Mobile account?{" "}
              <a onClick={onClickSignIn}>Sign in</a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
