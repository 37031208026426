import React from "react";
import moment from "moment";

const MiniCalendar = ({ dateTimeStart, big = false }) => (
  <div className="RSVP-event-item-noavatar col-md-4">
    <div
      className={[
        "RSVP-event-item-noavatar-calendar",
        big ? "col-md-5" : "col-md-9",
        "col-sm-8",
        "col-xs-8",
      ].join(" ")}
    >
      <div className="RSVP-event-item-noavatar-calendar-header col-md-12 col-sm-12">
        <p className="RSVP-event-item-noavatar-calendar-day">
          {moment(dateTimeStart).format("dddd")}
        </p>
      </div>
      <div className="RSVP-event-item-noavatar-calendar-body">
        <p className="RSVP-event-item-noavatar-calendar-dayofmonth">
          {moment(dateTimeStart).format("D")}
        </p>
        <p className="RSVP-event-item-noavatar-calendar-month">
          {moment(dateTimeStart).format("MMM")}
        </p>
      </div>
    </div>
  </div>
);

export default MiniCalendar;
