import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PaymentError from "./PaymentError";
import * as actions from "../../../../actions/giveIDSActions";
import { getGiveIDSReducer } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
    ...getGiveIDSReducer(state),
});

const PaymentErrorRouter = withRouter(PaymentError);
export default connect(mapStateToProps, actions)(PaymentErrorRouter);
