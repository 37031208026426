import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import HexeaAchGG from "./HexeaAchGG";
import * as actions from "../../../../actions/giveIDSActions";
import { getGiveIDSReducer } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
    ...getGiveIDSReducer(state),
});

const HexeaAchRouter = withRouter(HexeaAchGG);
export default connect(mapStateToProps, actions)(HexeaAchRouter);
