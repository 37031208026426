import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import reduxReset from "redux-reset";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import "firebase/firestore";
import rootReducer from "./reducers/rootReducer";
import "./include/bootstrap";
import "./css/libs.common.scss";
import "./css/just.scss";
import "./css/errors.scss";
import "./css/RSVP-events.scss";
import "./css/IDS.scss";
import "react-calendar/dist/Calendar.css";
import 'react-toastify/dist/ReactToastify.css';
/**
The routes.js file includes all the routes in the app
 */
import Routes from "./routes";

/**
Setup the Redux DevTools, Logger, thunk and the Redux store with redux persist
 */
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["RSVPevents", "giveIDS"], // messages will not be persisted
};

const middleware = [thunk];
// Logger will only run in development mode __DEV__ is a NPM global constant
if (process.env.NODE_ENV === "development") {
  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware), reduxReset())
);
const persistor = persistStore(store);

class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
