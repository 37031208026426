import firebase from 'firebase/compat/app';
import * as actionConstants from '../actionConstants';

const moment = require('moment');

/**
 * Load all events by organization
 *
 * @returns {function(*, *)}
 */
export const getZoomCreds = (code, userId) => async (dispatch, getState) => {
  const zoomCredsResponse = firebase.functions().httpsCallable('getZoomCredsOAuth');
  const zoomCreds = await zoomCredsResponse({code, userId}).then(result => result.data);
  console.log(zoomCreds)
  if (zoomCreds && zoomCreds.access_token) {
    dispatch({ type: actionConstants.ZOOM, payload: zoomCreds })
    firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .update({
        zoomAccessToken: zoomCreds.access_token,
        zoomRefreshToken: zoomCreds.refresh_token,
      })
      .then((resp) => console.log(resp))
      .catch(error => console.log(error))
  }
};
