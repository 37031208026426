/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

class Scripts extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * Clear down the entire redux state
   */
  UNSAFE_componentWillMount() {
    //this.props.updateOrgLocationIds()
    this.props.eventCountByMonth()
  }

  render() {
    return null;
  }
}

export default Scripts;
