import React, { Fragment, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom";
import moment from "moment-timezone";
import NavBar from "../../components/NavBar";
import Footer from "../../../adminstration/components/Footer";
import example from "../../../../img/churchExample.jpg";
import SignUpForm from "../../components/SignInForm/SignUpForm";
import SignUpSummary from "../../components/SignUpSummary";
import useWindowDimensions from "../../../../utils/windowUtils";
import ActivitySpinner from "../../../adminstration/components/ActivitySpinner";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import VideocamIcon from "@material-ui/icons/Videocam";
import ZoomIcon from '../../../../img/ZoomIcon.svg'
import LanguageIcon from "@material-ui/icons/Language";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import LinkIcon from "@material-ui/icons/Link";
import CloseIcon from "@material-ui/icons/Close";
import CallMadeIcon from "@material-ui/icons/CallMade";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import MiniCalendar from "../../components/MiniCalendar/MiniCalendar";
const DEFAULT_BANNER =
  "https://donstagestg1pubstor01.blob.core.windows.net/bannerimages/DefaultHomePageBanner.png";
const RSVPEvent = ({
  RSVPGetEvent,
  RSVPGetEventOrgDetails,
  RSVPGetEvents,
  RSVPEventDetails,
  RSVPloadingEvent,
  RSVPloadingEventFailed,
  limit = 250,
  RSVPJoined,
  RSVPGPostSignUp,
  RSVPJoining,
  history,
  RSVPJoinedError,
  setRSVPEventDetails,
  RSVPeventsOrgDetails,
}) => {
  const refRSVPLink = useRef(null);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showModalSignIn, setShowModalSignIn] = useState(false);
  const [showModalLocationDetails, setShowModalLocationDetails] =
    useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [summaryInfo, setSummaryInfo] = useState({});
  const regExpLink =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim;

  const { height, width } = useWindowDimensions();
  
  let { orgId, eventId } = useParams();
  let {
    name,
    description,
    avatar,
    dateTimeStart,
    hasAttendeeLimit,
    attendeeLimit,
    attending,
    venue,
    locationName,
    additionalGuests,
    address,
    allDay,
    dateTimeEnd,
    zoomMeetingDetails,
    webLocationLink,
    onlineLocationType,
    personSpecialInstructions,
    webLocationInstructions,
    createdByName,
    locationType,
  } = RSVPEventDetails;

  const [showAllDescription, setshowAllDescription] = useState(false);
  const [showAllDescriptionLink, setshowAllDescriptionLink] = useState(false);
  const [noDescription, setNoDescription] = useState(false);
  const [fixedDescription, setfixedDescription] = useState(description);
  const [isSameDay] = useState(moment(dateTimeStart)
    .isSame(moment(dateTimeEnd), "day"))
  
  let locType = typeof(locationType) === 'string' ? locationType : locationType && locationType.value
  let onlineLocType = typeof(onlineLocationType) === 'string' ? onlineLocationType : onlineLocationType && onlineLocationType.value

  const [eventLink, setEventLink] = useState(
    `https://${window.location.hostname}/events/${orgId}/${eventId}`
  );
  const [eventLinkCopied, setEventLinkCopied] = useState(false);
  const stop = null;
  let localtz = moment.tz.guess()
  const checkDescriptionLength = () => {
    if (description) {
      let newDescription = description.replace(
        regExpLink,
        "</br></br><a href='$1' target='_blank'>$1</a>"
      );
      //console.log(newDescription, description);
      let stringLength = String(newDescription).trim().length;
      if (stringLength > limit && !showAllDescription && width < 600) {
        setshowAllDescriptionLink(true);
        setfixedDescription(String(newDescription).substring(0, limit) + "...");
      } else {
        setfixedDescription(String(newDescription));
      }
    }
    setNoDescription(true);
  };

  const formatURL = (url="") => {
    if(!url.match(/^(http(s):\/\/.*)/g))
      return `//${url}`;
    return url;
  } 
  const renderSameDayDate = () => {
    let localtz = moment.tz.guess()
    //console.log("same day");
    return <Fragment>
      <p>
        {moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, YYYY")}
      </p>
      <p>
        {moment(dateTimeStart).tz(localtz).format("h:mm A - ")}{moment(dateTimeEnd).tz(localtz).format("h:mm A - z")}
      </p>

    </Fragment>
  }

  const renderDifferentDay = () => {
    let localtz = moment.tz.guess()

    return <Fragment>
      <p>
        {moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, h:mm A -")}
      </p>
      <p>
        {moment(dateTimeEnd).tz(localtz).format("dddd, MMMM D, h:mm A z")}
      </p>
    </Fragment>
  }

  const renderAllDay = () => {
    let localtz = moment.tz.guess()

    return <Fragment>
      <p>
        {moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, YYYY")}
      </p>
      <p>
        All day
      </p>
    </Fragment>
  }
  const renderViewLocationDetails = () => {
    if (locType === "person" && !address) return null;
    if (onlineLocType === "online" && !webLocationLink) return null;
    return (
      <p
        className="RSVP-location-details"
        onClick={toggleSetShowModalLocationDetails}
      >
        View location details
      </p>
    );
  };
  const toggleShowAllDescription = () => {
    setshowAllDescription(!showAllDescription);
  };

  const toggleSetShowModalSignIn = () => {
    setShowModalSignIn(!showModalSignIn);
  };
  const toggleSetShowModalLocationDetails = () => {
    setShowModalLocationDetails(!showModalSignIn);
  };

  const calculateAttendeeNumbers = () => {
    let total = 0;
    const attendees = attending;
    if (attendees) {
      Object.keys(attendees).map((key) => {
        return (total += attendees[key] || 1);
      });
    }
    return total;
  };
  const joinOrg = (name, attendees, email, phoneNumber, eventId, orgId) => {
    RSVPGPostSignUp(name, attendees, email, phoneNumber, eventId, orgId, () => {
      setSummaryInfo({ name, attendees, email, phoneNumber });
      RSVPGetEvent(eventId);
      setSignedUp(true);
    });
  };
  const calculateRemainingAttendeeSlots = () => {
    if (!hasAttendeeLimit) {
      return 100;
    }
    return attendeeLimit - calculateAttendeeNumbers() > 0
      ? attendeeLimit - calculateAttendeeNumbers()
      : 0;
  };

  const copyLinkTimeOut = () => {
    if (!eventLinkCopied) {
      let timer = setTimeout(() => {
        setEventLinkCopied(false);
        refRSVPLink && refRSVPLink.current && refRSVPLink.current.blur();
      }, 6000);
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  };

  const copyRSVPLink = () => {
    refRSVPLink.current.select();
    document.execCommand("copy");
    copyLinkTimeOut();
    setEventLinkCopied(true);
    refRSVPLink.current.blur();
  };

  const mapsURL = !!address && ("https://www.google.com.mx/maps/place/" + address.replace(/\s+/g, '+'));
  const renderLocationDetails = () => {
    if (!!address) {
      return (
        <div>
          <p>
            <b>Location name:</b> {locationName}
          </p>
          <p>
            <b>Address: </b>
            <a href={mapsURL} target="_blank" style={{ color: RSVPeventsOrgDetails.primaryColor }}>
              {address}
            </a>
          </p>
          {personSpecialInstructions && <p>
            <b>Additional instructions or details: </b>
            {personSpecialInstructions}
          </p>}
        </div>
      );
    } else if (onlineLocationType === "link" && !!webLocationLink) {
      return (
        <div>
          <p>
            <b>Web link: </b>
            <a href={formatURL(webLocationLink)} target="_blank" style={{ color: RSVPeventsOrgDetails.primaryColor }}>
              {webLocationLink}
            </a>
          </p>
          {!!webLocationInstructions && (
            <p>
              <b>Additional instructions or details: </b>
              {webLocationInstructions}
            </p>
          )}
        </div>
      );
    } else if (onlineLocationType === "zoom" && !!zoomMeetingDetails) {
      const { topic, timezone, start_time, settings, id, join_url } =
        zoomMeetingDetails;
      const { global_dial_in_numbers } = settings;
      const idStr = `${id}`;
      const meetingId = `${idStr.substring(0, 3)} ${idStr.substring(
        3,
        7
      )} ${idStr.substring(7, 11)}`;
      return (
        <div>
          <p>{createdByName} is inviting you to a scheduled Zoom meeting.</p>
          <p>
            Topic: {`${topic}`}
            <br />
            Time:{start_time} {timezone}
          </p>
          <p>
            Join Zoom Meeting{" "}
            <a a={join_url} style={{ color: RSVPeventsOrgDetails.primaryColor }}>
              {join_url}
            </a>
          </p>
          <p>Meeting ID: {meetingId}</p>
          <p>
            Dial by your location
            <br />
            {global_dial_in_numbers.map((item) => (
              <div style={{ paddingLeft: 24 }}>
                {item.number} {item.country} ({item.city})
              </div>
            ))}
          </p>
        </div>
      );
    }
  };
  
  const renderEventDetailsInfo = () => {
    return (
      <div>
        <div className="col">
                      <p className="e-event-title">{name}</p>
                    </div>
                    <div className="col RSVP-event-body-schedule">
                      <div style={{ marginBottom: 8 }} className="flex-row">
                        <AccessTimeOutlinedIcon
                          style={{
                            fontSize: 24,
                            color: "#222222",
                            marginRight: 8,
                          }}
                        />
                        <div className="flex-column">
                          {isMultiDay && !allDay ? (
                            <p style={{textAlign:'left'}}>
                              {moment(dateTimeStart).tz(localtz).format(
                                "dddd, MMMM D, h:mm A"
                              )}{" "}
                              -{" "}
                              {moment(dateTimeEnd).tz(localtz).format(
                                "dddd, MMMM D, h:mm A zz"
                              )}
                            </p>
                          ) : (
                            <p style={{textAlign:'left'}}>
                              {`${moment(dateTimeStart).tz(localtz).format("dddd, MMMM D, YYYY")} ${allDay ? "(all day)" : ""}`}
                            </p>
                          )}
                          {(!allDay && !isMultiDay) && (
                            <p style={{textAlign:'left'}}>
                              {moment(dateTimeStart).tz(localtz).format("h:mm A") +
                                " - " +
                                moment(dateTimeEnd).tz(localtz).format("h:mm A zz")}
                            </p>
                          )} 
                        </div>
                      </div>
                      {locType==="person" && (
                        <div style={{cursor:'pointer'}} className="flex-row">
                          <RoomOutlinedIcon
                            style={{
                              fontSize: 24,
                              marginRight: 8,
                              color: "#222222",
                            }}
                          />
                          {!!address ? <div className="flex-column" onClick={() => {setShowModalLocationDetails(true)}}>
                            <p style={{textDecoration:'underline',textAlign:'left'}}>{locationName}</p>
                            <p
                              style={{
                                color: RSVPeventsOrgDetails.primaryColor,
                                textDecoration:'underline',
                                textAlign:'left'
                              }}
                            >
                              {address}
                            </p>
                          </div>
                          : "In Person"}
                        </div>
                      )}

                      {onlineLocationType === "link" && (
                        <div style={{cursor:'pointer'}} className="flex-row" onClick={() => {setShowModalLocationDetails(true)}}>
                          <LanguageIcon
                            style={{
                              fontSize: 24,
                              marginRight: 8,
                              color: "#222222",
                            }}
                          />
                          <div className="flex-column">
                            {!!webLocationLink ?
                            <p
                              style={{
                                color: RSVPeventsOrgDetails.primaryColor,
                                textDecoration: 'underline'
                              }}
                            >
                              {webLocationLink}
                            </p>
                            : "Online"}
                          </div>
                        </div>
                      )}

                      {onlineLocationType === "zoom" && !!zoomMeetingDetails && (
                        <div style={{cursor:'pointer'}} className="flex-row" onClick={() => {setShowModalLocationDetails(true)}}>
                          <img
                          src={ZoomIcon}
                            style={{
                              width: 24,
                              height: 24,
                              marginRight: 8,
                            }}
                          />
                          <div className="flex-column">
                            <a
                              style={{
                                color: RSVPeventsOrgDetails.primaryColor,
                                textDecoration: 'underline'
                              }}
                            >
                              Zoom
                            </a>
                          </div>
                        </div>
                      )}
                      <div style={{ display: "flex", paddingLeft: 18 }}>
                        {renderViewLocationDetails()}
                      </div>
                      {hasAttendeeLimit && (
                        <p className="RSVP-remaining-spots">
                          {calculateRemainingAttendeeSlots()} spots remaining
                        </p>
                      )}
                    </div>
      </div>
    );
  }
  const renderEventDescription = () => {
    return (
      <div>
{!!description && (
                    <div className="col RSVP-description">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 16,
                          marginTop: 16,
                          marginBottom: 8,
                          color: "#363636",
                        }}
                      >
                        Description
                      </p>
                      <div
                        style={{ color: "#363636" }}
                        className="RSVP-description-text"
                        dangerouslySetInnerHTML={{ __html: fixedDescription }}
                      ></div>
                      {showAllDescriptionLink && (
                        <a onClick={toggleShowAllDescription}>
                          {showAllDescription ? "See less" : "See more"}
                        </a>
                      )}
                    </div>
            )}

      </div>
      );
    }
  const renderEventLinkDetails = () => {
    return (
      <div style={{ marginTop: 10, marginRight: 0, padding:10 }}
            // className={["RSVP-event-body-column-footer", eventHasEnded ? "col-md" : "col-md-6", "mb-2"].join(" ")}
            // style={{ marginTop: 0, marginRight: 0, padding:0 }}
          >
            <div className="">
              <div
                // className="flex-row"
                className="RSVP-event-link-container"
                onClick={copyRSVPLink}
              >
                <div className="RSVP-event-link">
                  <div style={{ color: "#363636", marginRight: 2, marginTop: 1}}>Link:</div>
                  <input
                    disabled={false}
                    style={{ color: RSVPeventsOrgDetails.primaryColor, textDecoration:'underline' }}
                    ref={refRSVPLink}
                    value={eventLink}
                    onChange={(e) => { }}
                  />
                </div>
                <div className="flex-row">
                  <LinkIcon
                    style={{
                      fontSize: 20,
                      marginRight: 8,
                      color: RSVPeventsOrgDetails.primaryColor,
                    }}
                  />
                  {eventLinkCopied ? (
                    <p
                      className="RSVP-event-link-copied"
                      style={{ color: RSVPeventsOrgDetails.primaryColor }}
                    >
                      Copied!
                    </p>
                  ) : (
                    <p style={{ color: RSVPeventsOrgDetails.primaryColor }}>
                      Copy
                    </p>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 32}}>
                <Link to={`/events/${orgId}`}
                  className="RSVP-event-link link flex-row"
                  style={{ color: RSVPeventsOrgDetails.primaryColor,  alignItems: 'center',  }}
                  
                >
                  <ArrowBackIcon className="RSVP-back-icon" /> <p className="RSVP-back-text"> Return to events</p>
                </Link>
              </div>
            </div>
      </div>
      );
    }
  const renderSignUp = () => {
    return (
      <div>
        {RSVPJoined && signedUp ? (
                  <SignUpSummary summaryInfo={summaryInfo} eventName={name} />
                ) : (
                  !eventHasEnded && (
                    <SignUpForm
                      additionalGuests={additionalGuests}
                      toggleTermsAndConditions={() =>
                        setTermsAndConditions(!termsAndConditions)
                      }
                      termsAndConditions={termsAndConditions}
                      onClickSignUp={joinOrg}
                      availableTickets={calculateRemainingAttendeeSlots()}
                      error={RSVPJoinedError}
                      loading={RSVPJoining}
                      eventId={eventId}
                      orgId={orgId}
                      attendeeLimit={attendeeLimit}
                      hasAttendeeLimit={hasAttendeeLimit}
                      orgDetails={RSVPeventsOrgDetails}
                    />
                  )
                )}
      </div>
      );
    }
  
  const openDetailsLink = () => {
    if(!!address) {
      window.open(mapsURL, "_blank")
    }
    else if(onlineLocationType === "link") {
      
      window.open(formatURL(webLocationLink), "_blank");
    }
    else if(onlineLocationType === "zoom"){
      const {join_url} = zoomMeetingDetails;
      window.open(join_url, "_blank");
    }
    
  }
  useEffect(() => {
    //console.log(!RSVPEventDetails.description, !noDescription);
    !RSVPeventsOrgDetails.id && RSVPGetEventOrgDetails(orgId);
    !RSVPEventDetails.description && !noDescription && RSVPGetEvent(eventId);
    window.scrollTo(0, 0);
    checkDescriptionLength();
    window.scrollTo(0, 0);
    history.listen(() => {
      setRSVPEventDetails({});
      setSignedUp(false);
      stop && stop();
    });
  }, [RSVPEventDetails, showAllDescription, eventId]);
  //console.log(dateTimeEnd)
  dateTimeEnd = dateTimeEnd || moment(dateTimeStart).endOf('day');
  //console.log(dateTimeEnd)
  const eventHasEnded = moment(dateTimeEnd).isBefore(new Date()) || (allDay && moment(moment(dateTimeStart).endOf('day')).isBefore(new Date()));
  const isMultiDay = !moment(dateTimeStart).isSame(moment(dateTimeEnd), "day");
  
  return (
    <Fragment>
      <Modal
        overlayClassName="signInModal-Overlay "
        isOpen={showModalLocationDetails}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        onRequestClose={() => setShowModalLocationDetails(false)}
        className="RSVP-location-details-modal"
      >
        <div className="RSVP-location-details-modal-title">
          <p style={{textAlign:'left'}}>{name}</p>
          <CloseIcon style={{ fontSize: 20, color: "#666666", padding: -3, cursor:'pointer' }}
            onClick={() => setShowModalLocationDetails(false)}
          />
        </div>
        <div className="RSVP-location-details-modal-content">
          {renderLocationDetails()}
        </div>
        <div className="RSVP-location-details-modal-actions">
          <input
            className="btn btn-info RSVP-event-item-submit-button sign-up"
            style={{
              width: "auto",
              height: 40,
              marginRight: 8,
              borderColor: "#CCCCCC",
              backgroundColor: "white",
              color: "#222222",
              borderRadius: 6,
            }}
            type="button"
            value="Close"
            onClick={() => setShowModalLocationDetails(false)}
          />
          <input
            className="btn btn-info RSVP-event-item-submit-button sign-up"
            style={{
              width: "auto",
              height: 40,
              borderColor: RSVPeventsOrgDetails.primaryColor,
              backgroundColor: RSVPeventsOrgDetails.primaryColor,
              borderRadius: 6,
            }}
            type="button"
            value={onlineLocationType==="zoom" ? "Join meeting" : "Open link"}
            onClick={openDetailsLink}
          />
        </div>
      </Modal>
      <Modal
        overlayClassName="signInModal-Overlay "
        isOpen={showModalSignIn}
        shouldCloseOnOverlayClick
        onRequestClose={() => setShowModalSignIn(false)}
        className="signInModal col-md-4 col-12-sm "
      >
        <div className="col">
          <div className="row">
            <h1>Sign in </h1>
          </div>
          <div className="col">
            <h3>Sign in with your Vanco Mobile email and password.</h3>
          </div>
          <div className="col">
            <input
              placeholder="Email"
              className="form-control underlined pv-2 mt-1"
            />
          </div>
          <div className="col mt-1 mb-1">
            <input
              placeholder="Password"
              type="password"
              className="form-control underlined pv-2 mt-1"
            />
          </div>
          <div className="col right-content mt-1">
            <a>Forgot Password?</a>
          </div>
          <div className="col center-content">
            <input className="btn btn-info" type="submit" value="Sign Up" />
          </div>
          <div className="col mt-2 mb-2 center-content">
            <p>
              Not a Vanco Mobile user? <a>Download the app to sign up.</a>
            </p>
          </div>
        </div>
      </Modal>
      <NavBar
        title={`${RSVPeventsOrgDetails.name || ""} Events`}
        imageUri={RSVPeventsOrgDetails && RSVPeventsOrgDetails.avatar}
      />

      
      
      <div className="e-container">


        {eventHasEnded && (
          <div>
            <h3 className="e-event-ended">This event has ended.</h3>
          </div>
        )}
      
        <div className="e-event">
          <div className="e-event-detail">
        
            {avatar && ( // Only display the event if the image has one:
              <img 
              className="e-event-image"
              src={avatar || DEFAULT_BANNER}
              style={!avatar ? {
                              backgroundColor:
                                RSVPeventsOrgDetails.primaryColor || "#9b59b6",
                            }
                          : {}
                } 
            /> 
            )}
            
            <div className="e-event-detail-normal">

              <div className="e-event-detail-info">
                {renderEventDetailsInfo()}
              </div>

            </div>
            <div className="e-event-description-web">

          {renderEventDescription()}
            
          {renderEventLinkDetails()}
                          
          </div>
        </div>

        <div className="e-event-description-mobile">
          {renderEventDescription()}
        </div>
        
        <div className="e-event-sign-up">
          {renderSignUp()}
        </div>

        <div className="e-event-description-mobile">
          {renderEventLinkDetails()}
        </div>

      </div>

      <Footer />
              
              
      </div>
      



      
    </Fragment>
  );
};

export default RSVPEvent;
