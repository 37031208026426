import React, { Component, Fragment } from "react";
import firebase from 'firebase/compat/app';
import { Link } from "react-router-dom";

import logo from "../../../../img/vanco-white.png";
import appStoreBadge from "../../../../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

class ErrorOpenInPhone extends Component {

  render() {
    return (
      <Fragment>
        <div className={"error-topbar"}>
          <div className="error-topbar-container">
            <div className="error-topbar-left">
              <img
                alt="Logo"
                src={logo}
                style={{ width: "60px", height: "auto" }}
              />
              <h1 className="error-topbar-title">Vanco Mobile</h1>
            </div>
            <div className="error-topbar-right">
              <Link to="/admin/login">LOGIN</Link>
            </div>
          </div>
        </div>
        <div className={"error-body"}>
          <h1 className={"error-title"}>Oops!</h1>
          <h1 className={"error-description"}>
            Looks like you tried to access this link from your computer instead
            of a mobile phone.
          </h1>
          <img
            src={`${window.VANCOMOBILE_BASEURL}img/vanco_mobile_email_divider.png`}
            className="error-img-divider"
          />
          <div className="error-steps-container">
            <h1>Get started</h1>
            <ol>
              <li className="error-steps-element">
                <p>
                  Open your smartphone, and download Vanco Mobile from your app
                  store.
                </p>
                <div className="badges-container">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.vancopayments.vancomobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    className="badge-link"
                  >
                    <img
                      alt="Get it on Google Play"
                      className="store-badge google-play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/id1504961674"
                    className="badge-link"
                  >
                    <img
                      src={appStoreBadge}
                      alt="Get it on App Store"
                      className="store-badge"
                    />
                  </a>
                </div>
              </li>
              <li className="error-steps-element">
                <p>Install Vanco Mobile and create your account.</p>
              </li>
              <li className="error-steps-element">
                <p>
                  When you’re prompted to join an organzation, enter the 6-digit
                  code from your email in the search bar.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-xs-12 footer">
          <p>
            <a
              rel="noopener noreferrer"
              href={`${window.VANCOPAYMENTS_BASEURL}/terms-and-conditions`}
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
            &nbsp;|&nbsp;
            <a
              rel="noopener noreferrer"
              href={`${window.VANCOPAYMENTS_BASEURL}/privacy`}
              target="_blank"
            >
              Privacy Policy
            </a>
            &nbsp;|&nbsp;
            <a href="mailto:cs@vancopayments.com">Email Support</a>
          </p>
          <p>
            &copy;
            {new Date().getFullYear()} Vanco Payments LLC. All rights reserved
          </p>
        </div>
      </Fragment>
    );
  }
}

export default ErrorOpenInPhone;
