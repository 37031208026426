import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PaymentConfirmation from "./PaymentConfirmation";
import * as actions from "../../../../actions/giveIDSActions";
import { getGiveIDSReducer } from "../../../../reducers/rootReducer";

const mapStateToProps = (state) => ({
    ...getGiveIDSReducer(state),
});

const PaymentConfirmationRouter = withRouter(PaymentConfirmation);
export default connect(mapStateToProps, actions)(PaymentConfirmationRouter);
