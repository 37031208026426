import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useLocation } from 'react-router-dom';
import moment from 'moment'

const PaymentSuccess = () => {
    const location = useLocation()
    const refactorFrequency = (name) => {
        let newName = name
        switch (name) {
            case "OneTimeNow":
                newName = "Once, today"
                break;
            case "OneTimeFuture":
                newName = "Once, on a future date"
                break;
            case "Weekly":
                newName = "Every week"
                break;
            case "BiWeekly":
                newName = "Every two weeks"
                break;
            case "BiMonthly":
                newName = "Twice a month (1st and 15th)"
                break;
            case "Monthly":
                newName = "Monthly"
                break;
            case "Quarterly":
                newName = "Quarterly"
                break;
            case "Annually":
                newName = "Annually"
                break;
            default:
                break;
        }
        return newName
    }
    const cache = createIntlCache();
    let formater = createIntl(
        {
            locale: 'en',
            defaultLocale: 'en',
        },
        cache,
    );

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return "unknown";
    }

    const closeWindow = () => {
        if (getMobileOperatingSystem() == "ios") {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=true&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
        else if (getMobileOperatingSystem() == "android") {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/?paymentSuccess=true&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
        else {
            let url = `${window.BASE_DEEP_LINK}/?link=${window.VANCOMOBILE_BASEURL}/error-phone-link/&paymentSuccess=true&apn=com.vancopayments.vancomobile&isi=1504961674&ibi=com.vancopayments.vancomobile`;
            var win = window.open(url, '_blank');
            win.focus();
        }
    }
    console.log(location.state)
    return (
        <div className="container-fluid paymentConfimation">
            <div className="IDS navbar" style={{ backgroundColor: '#FFFFFF' }}>
                <h1 style={{ color: '#000000' }}>Success</h1>
            </div>
            <div className="IDS body row">
                <div className="col-xs-12 payment-success">
                    <div className="check-container">
                        <i className="fa fa-check" style={{ color: location.state.backgroundColor }} />
                    </div>
                    <div className="details col-xs-12">
                        <h1>Thank you</h1>
                    </div>
                    <div className="col-xs-12 submit-controls">
                        <div className="row">
                            <input
                                style={{
                                    borderColor: location.state.backgroundColor,
                                    backgroundColor: location.state.backgroundColor
                                }}
                                onClick={closeWindow}
                                className="btn btn-submit col-xs-8"
                                type="button"
                                value="Return to app"
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="footer col-md-12" >
                    This site is protected by reCAPTCHA and the Google <a style={{ color: location.state.backgroundColor }} href="https://policies.google.com/privacy?hl=en-US" target="_blank" >Privacy Policy</a> and <a style={{ color: location.state.backgroundColor }} href="https://policies.google.com/terms?hl=en-US">Terms of Service</a> apply
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
