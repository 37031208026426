import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import App from "./App";
import * as serviceWorker from "./serviceWorker";
firebase.initializeApp(window.FIREBASE_CONFIG);
firebase.firestore().settings({ experimentalForceLongPolling: true });

export let APIV1 = axios.create({
  // baseURL: "http://localhost:5001/vanco-mobile/us-central1/api/v1/",
  baseURL: window.APIV1_BASEURL,
  withCredentials: true
});

export let PROCESSOR_API = axios.create({
  // baseURL: "http://localhost:5001/vanco-mobile/us-central1/api/v1/",
  baseURL: window.CONNEXPOINT_PROCESSOR_BASEURL,
  withCredentials: true
});


export let PAYER_API_V1 = axios.create({
  // baseURL: "http://localhost:5001/vanco-mobile/us-central1/api/v1/",
  baseURL: window.PAYER_API_V1_BASEURL,
  // baseURL: "https://540fffa45fcb.ngrok.io/",
  headers: {
    "Cookieless-Request": "true"
  },
  withCredentials: true
});


ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
