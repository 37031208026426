import React, { Component } from 'react';
import queryString from 'query-string';

class ZoomAuth extends Component {
  constructor(props) {
    super(props);
    this.userId = this.props.match.params.userId;
    this.params = queryString.parse(this.props.location.search);
    props.getZoomCreds(this.params.code, this.userId);
  }

  render() {
    return (
      <div className="IDS-login-container" style={{textAlign: 'center'}}>
        {this.params.code ? (
          <p>Thank you! You have now authorized Vanco Mobile to use Zoom. You can now return to the Vanco Mobile app.</p>
        ) : (
          <p>Sorry! Something has gone wrong. Please try again and if the problem persists contact your adminstrator.</p>
        )}
        <p>&nbsp;</p>
        <a href="vancomobile://" style={{backgroundColor: '#30119B', color: '#FFFFFF', height: 40, width: 100, textAlign: 'center', paddingTop: 6, textDecoration: 'none', borderRadius: 7, paddingLeft: 20, paddingRight: 20, paddingBottom: 8}}>Back to Vanco Mobile</a>
      </div>
    );
  }
}

export default ZoomAuth;
